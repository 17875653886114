import React from "react";
import styles from "./Desc.pc.module.scss";

export default function Skills({ data }) {
  if (data?.length) {
    return (
      <div className={styles.skills}>
        {data.map((item, idx) => {
          return <span key={idx}>{item.value}</span>;
        })}
      </div>
    );
  }
  return null;
}
