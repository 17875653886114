import Chat from "./../Chat/Chat.pc";
import Save from "./Save.pc";
import styles from "./JobClient.pc.module.scss";

const JobClient = ({ data, jobId, sessionid }) => {
  // console.log("JobClient@data", { data, jobId, sessionid });

  return (
    <div className={styles.jobClientWrap}>
      <div className={styles.jobClient}>
        <div className={styles.left}>
          <Chat
            data={{
              ...data.job_header,
              ...data.job_description
            }}
            className={styles.chatButton}
            jobId={jobId}
            sessionid={sessionid}
          />
          <Save data={data.job_header?.save_button} jobId={jobId} />
        </div>
      </div>
    </div>
  );
};

export default JobClient;
