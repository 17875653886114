import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./index.module.scss";

import "swiper/css";
import { Drawer } from "antd";
import { useState } from "react";
import { useCompanyDetail } from "../../../DataProvider";

const CloseIcon = ({ onClick }) => {
  return (
    <span className={styles.closeIcon} onClick={onClick}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
      >
        <path
          d='M6.5 6.5L17.5 17.5'
          stroke='#121212'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M6.5 17.5L17.5 6.5'
          stroke='#121212'
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </span>
  );
};

export const BrandAndProduct = () => {
  const { companyPageData } = useCompanyDetail();
  const data = companyPageData?.company_about?.company_brand_product || {};
  const [item, setItem] = useState<any>({});

  return (
    <>
      <Swiper style={{ width: "100%" }} spaceBetween={12} slidesPerView={"auto"}>
        {(data?.brand_and_product_info || []).map((item, i) => {
          return (
            <SwiperSlide
              className={styles.swiperSlideItem}
              onClick={() => {
                setItem(item);
              }}
            >
              <img src={item.logo_url} />
              <div className={styles.content}>
                <div className={styles.name}>{item.title}</div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Drawer
        placement='bottom'
        open={Boolean(Object.keys(item).length)}
        title={null}
        onClose={() => {
          setItem({});
        }}
        closeIcon={null}
        rootClassName={styles.antDrawer}
        height={"70vh"}
        maskClosable
      >
        <div className={styles.modalContent}>
          <CloseIcon
            onClick={() => {
              setItem({});
            }}
          />
          <img className={styles.logoImg} src={item.logo_url} />
          <div className={styles.contentWrapper}>
            <div className={styles.title}>{item.title}</div>
            <div className={styles.desc}>{item.description}</div>
            {Boolean(item?.pictures?.length) && (
              <div className={styles.imgItemWrapper}>
                {item.pictures.map((v) => {
                  return (
                    <div className={styles.imgItem} key={v}>
                      <img src={v} />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </>
  );
};
