import React, { useEffect, useMemo, useState } from "react";

import styles from "./SortFilter.module.scss";

import { BenefitsSelectPc } from "../../_pc/components/BenefitsSelectCopy/pc";
import { IndustrySelectPc } from "../../_pc/components/IndustrySelect/pc";
import { flattenDeep } from "lodash-es";
import { ButtonWeb3 } from "../Button/index.pc";
import MultiSelect from "app/(new-pages)/components/allSelect/baseSelect/pc/MultiSelect";
interface IProps {
  data: any;
  searchParams: any;
  onChange: (e: any) => void;
}

const ResetIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M17.0443 10.7443C16.8128 12.9192 15.5805 14.9573 13.5413 16.1346C10.1534 18.0906 5.82132 16.9298 3.86531 13.5419L3.65698 13.1811M2.95527 9.2559C3.18677 7.08099 4.4191 5.04288 6.45825 3.86557C9.84616 1.90957 14.1783 3.07035 16.1343 6.45825L16.3426 6.8191M2.91113 15.0551L3.52118 12.7784L5.79788 13.3884M14.2022 6.61173L16.4789 7.22178L17.0889 4.94507'
        stroke='#121212'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const getEmptyForm = () => {
  let emptyFilter = {
    company_size_ids: "",
    financing_stage_ids: "",
    benefits: "",
    industry_ids: ""
  };

  return emptyFilter;
};
const convertUrlBenefits = (data: any[], ids: string[]) => {
  const mapping = {};
  for (const item of data) {
    const parentSeoValue = item.seo_value;
    for (const benefit of item.benefits) {
      mapping[benefit.id] = parentSeoValue;
    }
  }

  const result = {};
  for (const id of ids) {
    const seoValue = mapping[id];
    if (seoValue) {
      if (!result[seoValue]) {
        result[seoValue] = [];
      }
      result[seoValue].push(id);
    }
  }
  let form = { category_value: [], benefits: [] };
  Object.keys(result).forEach((key) => {
    form.category_value.push(key);
    result[key].forEach((value) => {
      form.benefits.push(parseInt(value));
    });
  });
  return form;
};

const convertUrlIndustry = (data, ids) => {
  const mapping = {};
  for (const item of data) {
    const parentSeoValue = item.value;
    for (const benefit of item.sub_filter) {
      mapping[benefit.id] = parentSeoValue;
    }
  }

  const result = [];
  for (const id of ids) {
    const seoValue = mapping[id];
    if (seoValue) {
      result.push([seoValue, parseInt(id, 10)]);
    }
  }
  return result;
};

const MySelect = ({ data, value, onChange, label, visibleSearch = false }) => {
  const options = useMemo(() => {
    const mapData = (data: any[]) => {
      return data?.map((v) => {
        let children = v.children ? mapData(v.children) : undefined;
        return {
          label: v.value,
          value: v.id,
          children
        };
      });
    };
    return mapData(data);
  }, []);

  const [oneLabel, setOneLabel] = useState("");

  return (
    <MultiSelect
      options={options}
      value={value}
      onChange={(value) => {
        if (value?.length === 1) {
          let option = options.find((v) => v.value === value[0][0]);
          setOneLabel(option?.label || "");
        }
        onChange(value);
      }}
      visibleSearch={visibleSearch}
      label={label}
      placeholder={label}
      oneLabel={oneLabel}
    />
  );
};

export const SortFilter = (props: IProps) => {
  const { data, searchParams, onChange } = props;
  const config = data.search_info;

  const benefits_filter = config?.benefits_filter || [];
  const companySizeList = config.company_size_filter || [];
  const industryList = config.industry_filter || [];
  const financingStageList = config.financing_stage_filter || [];

  const [companySizes, setCompanySizes] = useState([]);
  const [benefitValue, setBenefitValue] = useState({ category_value: [], benefits: [] });
  const [industryValue, setIndustryValue] = useState([]);
  const [financingStages, setFinancingStages] = useState([]);

  // console.log("companySizes", financingStages, companySizes);

  const handleReset = () => {
    setCompanySizes([]);
    setFinancingStages([]);
    setBenefitValue({ category_value: [], benefits: [] });
    setIndustryValue([]);

    onChange(getEmptyForm());
  };

  //
  // console.log("searchParamssearchParams", config, searchParams);

  //
  const handleQueries = (newData = {}) => {
    let newParams = {
      companySizes,
      benefitValue,
      industryValue,
      financingStages,
      ...newData
    };

    let idMap = getEmptyForm();

    if (newParams.companySizes.length) {
      idMap.company_size_ids = flattenDeep(newParams.companySizes).join(",");
    }

    if (newParams.financingStages.length) {
      idMap.financing_stage_ids = flattenDeep(newParams.financingStages).join(",");
    }

    if (newParams.benefitValue.benefits.length) {
      idMap.benefits = flattenDeep(newParams.benefitValue.benefits).join(",");
    }

    if (newParams.industryValue.length) {
      idMap.industry_ids = newParams.industryValue.map((v) => v[1]).join(",");
    }

    onChange(idMap);
  };

  useEffect(() => {
    const { benefits, company_size_ids, financing_stage_ids, industry_ids } = searchParams;

    if (benefits) {
      setBenefitValue(convertUrlBenefits(benefits_filter, benefits.split(",")));
    }

    if (company_size_ids) {
      setCompanySizes(company_size_ids.split(",").map((v) => [parseInt(v, 10)]));
    }

    if (financing_stage_ids) {
      setFinancingStages(financing_stage_ids.split(",").map((v) => [parseInt(v, 10)]));
    }

    if (industry_ids) {
      setIndustryValue(
        convertUrlIndustry(
          industryList,
          industry_ids.split(",").map((v) => parseInt(v, 10))
        )
      );
    }
  }, []);

  return (
    <div
      style={{
        position: "relative",
        zIndex: 1,
        display: "flex",
        alignItems: "center",
        gap: "16px"
      }}
    >
      <BenefitsSelectPc
        label={data?.search_info?.benefits_text}
        isRemote={false}
        benefits_filter={benefits_filter}
        value={benefitValue}
        onChange={(value) => {
          setBenefitValue(value);
          handleQueries({ benefitValue: value });
        }}
      />

      <MySelect
        data={companySizeList}
        value={companySizes}
        onChange={(value) => {
          setCompanySizes(value);
          handleQueries({ companySizes: value });
        }}
        label={data?.search_info?.company_size_text}
      />

      <MySelect
        data={financingStageList}
        value={financingStages}
        onChange={(value) => {
          setFinancingStages(value);
          handleQueries({ financingStages: value });
        }}
        label={data?.search_info?.financing_stage_text}
      />

      <IndustrySelectPc
        label={data?.search_info?.industry_text}
        data={industryList}
        value={industryValue}
        onChange={(value) => {
          setIndustryValue(value);
          handleQueries({ industryValue: value });
        }}
      />
      <ButtonWeb3
        // className={styles.resetBtn}
        btnSize='size44'
        variant='whiteBoxShadowBtn'
        style={{ marginLeft: "auto", padding: 0, width: "44px" }}
        onClick={handleReset}
      >
        <ResetIcon />
      </ButtonWeb3>
    </div>
  );
};
