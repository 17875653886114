import { useCompanyDetail } from "../../../DataProvider";
import {
  BuildingIcon,
  FBIcon,
  FinancingIcon,
  InstagramIcon,
  LinkedIcon,
  MediaIcon,
  PeopleIcon,
  SiteIcon,
  XIcon,
  YuTuBeIcon
} from "./icon";
import styles from "./index.module.scss";

export const CompanyOverview = () => {
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData?.company_about || {};
  const overviewCompany = companyPageData?.company_about?.company_overview || {};

  const { company_overview = {} } = about;

  const mediaMap: Record<string, any> = {};
  (overviewCompany.media || []).forEach((item) => {
    mediaMap[item.title] = item.url;
  });

  const arr = [
    {
      icon: LinkedIcon,
      link: mediaMap.Linkedin,
      name: "Linkedin"
    },
    {
      icon: InstagramIcon,
      link: mediaMap.Instagram,
      name: "Instagram"
    },
    {
      icon: XIcon,
      link: mediaMap.Twitter,
      name: "Twitter"
    },
    {
      icon: FBIcon,
      link: mediaMap.Facebook,
      name: "Facebook"
    },
    {
      icon: YuTuBeIcon,
      link: mediaMap.YouTube,
      name: "YouTube"
    }
  ];
  const res = arr.filter((item) => item.link);

  let baseInfo = [
    {
      icon: <SiteIcon />,
      value: company_overview?.website
    },
    {
      icon: <PeopleIcon />,
      value: company_overview?.company_size
    },
    {
      icon: <BuildingIcon />,
      value: company_overview?.industry
    },
    {
      icon: <FinancingIcon />,
      value: company_overview?.financing_stage
    },
    {
      icon: <MediaIcon />,
      value: !res.length ? null : (
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          {res.map((v) => {
            let Icon = v.icon;
            return (
              <a href={v.link} target='_blank'>
                <Icon />
              </a>
            );
          })}
        </div>
      )
    }
  ].filter((v) => Boolean(v.value));

  return (
    <div className={styles.overviewRoot}>
      {baseInfo.map((v) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 10,
              lineHeight: "24px"
              // alignItems: 'center'
            }}
          >
            <span style={{ flexShrink: 0, paddingTop: 5 }}>{v.icon}</span>
            {v.value}
          </div>
        );
      })}
    </div>
  );
};
