"use client";
import CompanyCard from "./CompanyCard/CompanyCard";
// import Empty from './Empty'
import Image from "next/image";
// Styles
import styles from "../Companies.module.scss";
import { getCookie } from "helpers/cookies";
import { useLanguage } from "app/components/providers/languageProvider";
import { postNotification } from "bossjob-remote/dist/clientStorage";
// import Empty from "./empty";
import NoDataImg from "../noData.png";

const NoDataSvg = () => {
  return <img src={NoDataImg.src} width={160} height={160} />;
};

interface ICompanyCardList {
  companiesList: any;
  isLoading?: boolean;
  empty?: any;
  langKey: string;
  page: number;
}

const CompanyCardList = (props: ICompanyCardList) => {
  const { companiesList, isLoading, empty, langKey } = props;
  const isLogin = getCookie("sessionid") ? true : false;

  // const { companies } = useLanguage();
  // const Tips = (
  //   <div className={styles.tips_wrapper}>
  //     <div className={styles.tips_content}>
  //       <Image
  //         alt={"img"}
  //         width={106}
  //         height={102}
  //         src={`${process.env.S3_BUCKET_URL}/companies/registerLock.svg`}
  //       ></Image>
  //       <div className={styles.tips_content_text}>{companies.loginNowDescription}</div>
  //       <div
  //         className={styles.tips_content_go}
  //         onClick={() => {
  //           postNotification("SHOW_LOGIN_MODAL");
  //         }}
  //       >
  //         {companies.loginNow}
  //       </div>
  //     </div>
  //     <div className={styles.tips_full}>
  //       <Image
  //         alt={"fill"}
  //         fill
  //         style={{ objectFit: "contain" }}
  //         src={`${process.env.S3_BUCKET_URL}/companies/companies-search-bg.svg`}
  //       ></Image>
  //     </div>
  //   </div>
  // );

  const _list = Boolean(companiesList?.length) ? companiesList : [];

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
      {!isLoading &&
        companiesList?.length > 0 &&
        _list.map((item, index) => {
          return <CompanyCard company={item} langKey={langKey} key={item.id} />;
        })}

      {!isLoading && !companiesList?.length && (
        // <Empty lang={empty} />
        <div
          className={styles.emptyNode}
          style={{
            padding: "100px 0"
          }}
        >
          <NoDataSvg />
          No data
        </div>
      )}
    </div>
  );
};

export default CompanyCardList;
