// select 公共的一些方法

// 转 jobFunction就使用它
// @description 格式main function title. seo_value有一样的所以需要单独写一个方法加上id
const toSeo = (value) => value?.replaceAll('/', '-')?.replaceAll(' ', '-')?.toLowerCase();
export const formatJobFunctions = (jobFunctions) => {
  return jobFunctions.map((obj, index) => {
    const firstParent = {
      ...obj,
      key: toSeo(obj.seo_value || obj['seo-value']),
      value: toSeo(obj.seo_value || obj['seo-value']),
      label: obj.value,
      children: undefined
    }
    firstParent.children = obj.sub_function_list.map((second) => {
      const secondParent = {
        key: toSeo(second.seo_value || second['seo-value']),
        value: toSeo(second.seo_value || second['seo-value']),
        label: second.value,
        children: undefined,
        parent: toSeo(obj.seo_value || second['seo-value']),
      }
      secondParent.children = second.job_titles.map((third) => {
        return {
          ...third,
          label: third.value,
          value: toSeo((third['seo_value'] || third['seo-value'])) + '-' + third.id,
          key: toSeo(third['seo_value'] || third['seo-value']) + '-' + third.id,
          parent: toSeo(second.seo_value || second['seo-value']),
        }
      })

      return secondParent
    })
    return firstParent
  })
}

// 下拉框需要转换数据
// 将指定的key转换成children, 以及seo_value转value,value转label
// formatOptions(location_lists, ['locations'])
export const formatOptions = (arr: any[], keys: string[], parent?): any[] => {

  return (arr ?? []).map(item => {
    const result: any = {};

    Object.entries(item).forEach(([key, value]) => {
      if (keys.includes(key) && Array.isArray(value)) {
        result.children = formatOptions(value, keys, item);
      } else {
        result[key] = value;
      }
    });

    result.value = item['seo_value'] ? item['seo_value'] : item['id']
    result.label = item.value
    if (parent) {
      result.parent = parent['seo_value']
    }
    return result;
  });
};


export const formatSalary = (salaryTypeLists) => {
  return salaryTypeLists.filter(v => Boolean(v.salary_range_list)).map((obj, index) => {
    const firstParent = {
      label: obj.salary_type,
      id: index,
      value: obj.seo_value,
      salary_type_id: obj.salary_type_id,
      children: undefined
    }
    firstParent.children = (obj?.salary_range_list ?? []).map((second) => {
      const secondParent = {
        id: second.id,
        label: second.value,
        value: second.seo_value
      }
      return secondParent
    })
    return firstParent
  })
}