const DropdownIconPc = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='13' viewBox='0 0 12 13' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.89096 7.70425L2.6511 4.46439C2.45584 4.26913 2.13926 4.26913 1.94399 4.46439L1.83793 4.57046C1.64267 4.76572 1.64267 5.0823 1.83793 5.27757L5.89096 9.3306L9.94399 5.27757C10.1393 5.0823 10.1393 4.76572 9.94399 4.57046L9.83793 4.46439C9.64267 4.26913 9.32608 4.26913 9.13082 4.46439L5.89096 7.70425Z'
      fill='black'
    />
  </svg>
);

const DropdownIconBluePc = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.86983 3.90795C10.0434 4.08151 10.0434 4.36292 9.86983 4.53649L6.31427 8.09204C6.1407 8.26561 5.8593 8.26561 5.68573 8.09204L2.13017 4.53648C1.95661 4.36292 1.95661 4.08151 2.13017 3.90795C2.30374 3.73438 2.58515 3.73438 2.75871 3.90795L6 7.14923L9.24129 3.90795C9.41485 3.73438 9.69626 3.73438 9.86983 3.90795Z'
      fill='#2378E5'
    />
  </svg>
);

const DropdownIconDark = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 1.41421L1.41421 0L5.70711 4.29289L10 0L11.4142 1.41421L5.70711 7.12132L0 1.41421Z'
      fill='black'
    />
  </svg>
);

const PullUPIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.4141 5.70688L9.99985 7.12109L5.70696 2.8282L1.41406 7.12109L-0.000151511 5.70688L5.70696 -0.000226997L11.4141 5.70688Z'
      fill='black'
    />
  </svg>
);

const SearchIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <path
      d='M18.7595 20L12.8222 14.0627C11.4716 15.143 9.75852 15.789 7.89452 15.789C3.5345 15.789 0 12.2545 0 7.89452C0 3.5345 3.5345 0 7.89452 0C12.2545 0 15.789 3.5345 15.789 7.89452C15.789 9.75852 15.143 11.4716 14.0627 12.8222L20 18.7595L18.7595 20ZM7.89452 13.728C11.2856 13.728 13.7006 11.2856 13.7006 7.89452C13.7006 4.50339 11.2856 2.07808 7.89452 2.07808C4.50339 2.07808 2.07814 4.50339 2.07814 7.89452C2.07814 11.2856 4.50339 13.728 7.89452 13.728Z'
      fill='#111212'
    />
  </svg>
);

const PlaceIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 1.57461C3.99779 1.57461 1.6 3.9724 1.6 6.97461C1.6 9.4371 2.99634 11.8153 4.48492 13.6347C5.21986 14.5329 5.95641 15.2697 6.50938 15.7817C6.69703 15.9554 6.86303 16.1028 7 16.2214C7.13697 16.1028 7.30297 15.9554 7.49062 15.7817C8.04359 15.2697 8.78014 14.5329 9.51508 13.6347C11.0037 11.8153 12.4 9.4371 12.4 6.97461C12.4 3.9724 10.0022 1.57461 7 1.57461ZM7 17.0996C6.58559 17.6324 6.58542 17.6323 6.58523 17.6321L6.58332 17.6307L6.57886 17.6272L6.56345 17.615C6.55035 17.6046 6.53166 17.5897 6.50774 17.5704C6.4599 17.5318 6.39113 17.4755 6.30443 17.4026C6.13107 17.2567 5.88565 17.044 5.59218 16.7722C5.00609 16.2296 4.22389 15.4475 3.44008 14.4895C1.89116 12.5964 0.25 9.91212 0.25 6.97461C0.25 3.22682 3.25221 0.224609 7 0.224609C10.7478 0.224609 13.75 3.22682 13.75 6.97461C13.75 9.91212 12.1088 12.5964 10.5599 14.4895C9.77611 15.4475 8.99391 16.2296 8.40782 16.7722C8.11435 17.044 7.86893 17.2567 7.69557 17.4026C7.60887 17.4755 7.5401 17.5318 7.49226 17.5704C7.46834 17.5897 7.44964 17.6046 7.43655 17.615L7.42114 17.6272L7.41668 17.6307L7.41527 17.6318C7.41507 17.6319 7.41441 17.6324 7 17.0996ZM7 17.0996L7.41441 17.6324C7.17066 17.822 6.82898 17.8217 6.58523 17.6321L7 17.0996Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.00078 5.62441C6.2552 5.62441 5.65078 6.22883 5.65078 6.97441C5.65078 7.72 6.2552 8.32441 7.00078 8.32441C7.74637 8.32441 8.35078 7.72 8.35078 6.97441C8.35078 6.22883 7.74637 5.62441 7.00078 5.62441ZM4.30078 6.97441C4.30078 5.48325 5.50961 4.27441 7.00078 4.27441C8.49195 4.27441 9.70078 5.48325 9.70078 6.97441C9.70078 8.46558 8.49195 9.67441 7.00078 9.67441C5.50961 9.67441 4.30078 8.46558 4.30078 6.97441Z'
      fill='black'
    />
  </svg>
);

export { DropdownIconPc, DropdownIconBluePc, PullUPIcon, DropdownIconDark, SearchIcon, PlaceIcon };
