import { SelectProps } from "antd";
import MultiSelect from "app/(new-pages)/components/allSelect/baseSelect/pc/MultiSelect";
// import MultiSelect from "../../baseSelect/pc/MultiSelect";

type PropsType = {
  label: string;
  data: any[];
  value: any[];
  searchBoardPlaceholder?: string;
  boardClearText?: string;
  boardApplyFiltersText?: string;
  wrapperClassName?: string;
  arrowIconStyle?: "dark" | "default";
  showWhereLabel?: boolean;
  realTimeRefresh?: boolean;
} & SelectProps;

function convertArrayToLabelValue(arr) {
  return arr.map((item) => {
    const newItem = {
      label: item.value,
      value: item.id || item.value,
      seo_value: item.seo_value,
      children: []
    };

    if (item?.sub_filter?.length > 0) {
      newItem.children = convertArrayToLabelValue(item.sub_filter);
    }

    return newItem;
  });
}

export const IndustrySelectPc = ({
  placeholder,
  value,
  onChange,
  data,
  wrapperClassName,
  ...rest
}: PropsType) => {
  const options = convertArrayToLabelValue(data);

  return (
    <MultiSelect
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      oneLabel={""}
      wrapperClassName={wrapperClassName}
      {...rest}
    />
  );
};
