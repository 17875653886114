import React from 'react'
import { Dropdown } from 'antd'
import { defaultCountryKey, languages } from 'helpers/country.base'
import styles from '../../common.module.scss'
import { getCurLanguage } from 'helpers/country'
import { LanguageIcon } from './icons'
import { configKey, setCookie } from 'helpers/cookies'
import classNames from 'classnames'

const ChangeLanguage = (props) => {
  const { currentLangSlug } = getCurLanguage()

  console.log('currentLangSlug', currentLangSlug)

  const handleSwitchNation = (ev) => {
    console.log('ev', ev)

    const lang = ev.value || 'en-us'

    const { origin, pathname } = window.location

    const query = `/${lang}`
    const newOrigin = origin

    let restPath = pathname.split('/').slice(2).join('/')
    restPath = restPath ? `/${restPath}` : ''

    // store this in cookies. then the others link request server can take it to server
    setCookie(configKey, `${defaultCountryKey()}_${lang}`)
    window.location.href = newOrigin + query + restPath + location.search
  }

  return (
    <div>
      <Dropdown
        trigger={['click']}
        getPopupContainer={(node) => node.parentElement}
        dropdownRender={() => (
          <div className={styles.desiredHeaderLangList}>
            {languages.map((item) => {
              return (
                <div
                  className={classNames([
                    styles.desiredHeaderLangItem,
                    currentLangSlug === item.slug && styles.desiredHeaderLangActive
                  ])}
                  key={item.value}
                  onClick={() => handleSwitchNation(item)}
                >
                  {item.label}
                </div>
              )
            })}
          </div>
        )}
      >
        <div className={styles.desiredHeaderLang}>
          <LanguageIcon />
          {currentLangSlug}
        </div>
      </Dropdown>
    </div>
  )
}

export default ChangeLanguage
