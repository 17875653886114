import classNames from "classnames";
import Chat from "../Chat/Chat.pc";
import styles from "./Recruiter.pc.module.scss";

const Recruiter = ({ data, sessionid, jobId, className = "" }) => {
  return (
    <div className={classNames([styles.recruiter, className])}>
      <div className={styles.avatar}>
        {data.recruiter?.is_online ? <span className={styles.circle}></span> : null}

        <img src={data.recruiter?.avatar} alt='' />
      </div>
      <div className={styles.info}>
        <p>
          {data.recruiter?.full_name ? (
            <span className={styles.name}>{data.recruiter?.full_name}</span>
          ) : null}
        </p>
        <p className={styles.secondRow}>
          {data.recruiter?.company_name ? (
            <span className={styles.companyName}>
              {data.recruiter?.job_title ? (
                <>
                  <span className={styles.jobTitle}>{data.recruiter?.job_title}</span>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='3'
                    height='4'
                    viewBox='0 0 3 4'
                    fill='none'
                  >
                    <circle cx='1.25' cy='2' r='1.25' fill='#7D7D7D' />
                  </svg>
                </>
              ) : null}
              {data.recruiter?.company_name}
            </span>
          ) : null}
        </p>
        {data.recruiter?.reply_label ? (
          <p>
            <span className={styles.activeDate}>{data.recruiter?.reply_label}</span>
          </p>
        ) : null}
      </div>
      <div style={{ alignSelf: "center" }}>
        <Chat
          data={{
            ...data.job_header,
            ...data.job_description
          }}
          chatText={data?.recruiter?.chat_button?.text}
          jobId={jobId}
          sessionid={sessionid}
          variant='whiteBtn'
          className={styles.recruiterChatButton}
        />
      </div>
    </div>
  );

  // return null;
};

export default Recruiter;
