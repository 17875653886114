"use client";
import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from "react";
import { hoverable } from "components/highLevel/hoverable";
import { usePageGrouped } from "./hooks";
import { LocationContext } from "app/components/providers/locationProvier";
import styles from "../../styles/index.pc.module.scss";
import { buildQuery } from "helpers/buildQuery";
import Link from "components/Link";
import classNames from "classnames";
import { addEventTag } from "helpers/utilities";

const getDisplayWidth = (ref, parentRef) => {
  function getVisibleWidthPercentage(element, parentElement) {
    const parentRect = parentElement.getBoundingClientRect();
    const elementRect = element.getBoundingClientRect();

    const visibleLeft = parentRect.left;
    const visibleRight = parentRect.right;

    if (elementRect.right < visibleRight) {
      // fully visible
      return { visible: true, visibleWidth: elementRect.width };
    }

    const visibleWidth = visibleRight - elementRect.left;

    return {
      visible: false,
      visibleWidth
    };
  }

  return getVisibleWidthPercentage(ref, parentRef);
};
const CalculateLink = ({ href, subTitle, onMouseOver, onClick, parentRef, bindRef, style }) => {
  return (
    <Link href={href} style={style} target='_blank'>
      <div
        ref={bindRef}
        title={subTitle}
        className={styles.mainTitleSub}
        onMouseOver={onMouseOver}
        onClick={onClick}
      >
        {subTitle}
      </div>
    </Link>
  );
};

const MainItem = hoverable((props: any) => {
  const { isHover, setHoverData, data, onMouseEnter, onMouseLeave, setHoverTitle, hoverTitle } =
    props;

  const { location } = useContext(LocationContext);
  const ref = useRef(null);
  const childRef = useRef({});
  const [childStyle, setChildStyle] = useState([]);
  const title = data.simple_title || data.value;

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    let childRefObj = childRef.current;
    let newStyle = Object.keys(childRefObj).map((key) => {
      const { visibleWidth, visible } = getDisplayWidth(childRefObj[key], ref.current);
      if (visible) {
        return {};
      }
      if (visibleWidth < 50) {
        return { display: "none" };
      } else {
        return {
          display: "flex",
          textOverflow: "ellipsis",
          overflow: "hidden"
        };
      }
    });
    setChildStyle(newStyle);
  }, []);

  useLayoutEffect(() => {
    if (isHover) {
      setHoverData(data.sub_function_list);
      setHoverTitle(title);
    }
  }, [isHover]);

  return (
    <div
      className={classNames({
        [styles.mainItem]: true,
        [styles.isHover]: isHover || hoverTitle === title
      })}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={styles.mainTitle}>
        <div className={styles.mainTitleFirst}>{title}</div>
        <div className={styles.subContainer} style={{ overflow: "hidden" }} ref={ref}>
          {(data?.sub_title_list || []).map((subTitle, index) => {
            return (
              <CalculateLink
                parentRef={ref}
                style={childStyle[index]}
                bindRef={(ref) => (childRef.current[index] = ref)}
                key={subTitle.id + "_" + subTitle["seo_value"]}
                href={"/" + props.langKey + buildQuery(location?.seo_value, subTitle.value)}
                onMouseOver={() => addEventTag("hp_job_function_hover")}
                onClick={() => addEventTag("hp_job_function_click")}
                subTitle={subTitle.value}
              />
            );
          })}
        </div>
        <span
          style={{
            display: "inline-flex",
            marginLeft: 12,
            height: 32,
            width: 32,
            borderRadius: 8,

            background: "#fff",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <MoreIcon />
        </span>
      </div>
    </div>
  );
});

const MoreIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.41421 12L-6.18172e-08 10.5858L4.29289 6.29289L-4.37114e-07 2L1.41421 0.585786L7.12132 6.29289L1.41421 12Z'
      fill='black'
      fillOpacity='0.8'
    />
  </svg>
);

const PrevIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='8' height='12' viewBox='0 0 8 12' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.58579 12L8 10.5858L3.70711 6.29289L8 2L6.58579 0.585786L0.87868 6.29289L6.58579 12Z'
      fill='black'
      fillOpacity='0.8'
    />
  </svg>
);

const SectionItem = (props: any) => {
  const { data }: any = props;
  return (
    <div className={styles.sectionItems} key={data?.id + data["seo_value"]}>
      <label className={styles.sectionName}>{data.value}</label>
      <div className={styles.subItems}>
        {(data?.job_titles || []).map((item, index) => (
          <SubItem data={item} langKey={props.langKey} key={item.value + index} />
        ))}
      </div>
    </div>
  );
};

const SubItem = hoverable((props: any) => {
  const { location } = useContext(LocationContext);
  const { data } = props;
  return (
    <Link
      className={styles.subItem}
      href={"/" + props.langKey + buildQuery(location?.seo_value, data?.value)}
      target='_blank'
    >
      <div className={styles.linkText} title={data.value}>
        {data.value}
      </div>
    </Link>
  );
});
const FunctionFilter: React.FC<{
  list: any[];
  langKey: string;
}> = hoverable((props: any) => {
  const { list, isHover, langKey, ...rest } = props;
  const [hoverTitle, setHoverTitle] = useState("");
  const sectionRef = useRef<HTMLDivElement | null>(null);

  const { currentPage, totalPages, pageDatas, nextEnable, preEnable, onNext, onPre } =
    usePageGrouped(list);

  // console.log("list", { list, currentPage, totalPages, pageDatas });

  const [hoverData, setHoverData] = useState<any[] | null>(null);
  useEffect(() => {
    if (!isHover && hoverData) {
      setHoverData(null);
      setHoverTitle("");
    }
    if (sectionRef.current) {
      sectionRef.current.scrollTop = 0;
    }
  }, [isHover, hoverData]);

  // console.log('hoverData', { hoverData, hoverTitle })

  return (
    <section className={styles.functionFilter} {...rest}>
      <div className={styles.mainItems}>
        {pageDatas.map((main, index) => {
          return (
            <MainItem
              key={main.id + main["seo_value"]}
              hoverTitle={hoverTitle}
              setHoverData={setHoverData}
              data={main}
              setHoverTitle={setHoverTitle}
              langKey={langKey}
            />
          );
        })}

        <div className={styles.pagination}>
          <label>
            <span>{currentPage}</span>
            <svg
              className={styles.svg}
              xmlns='http://www.w3.org/2000/svg'
              width='5'
              height='12'
              viewBox='0 0 5 12'
              fill='none'
            >
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0 11.0703L4 0.570312L4.93449 0.926308L0.934488 11.4263L0 11.0703Z'
                fill='black'
              />
            </svg>
            {totalPages}
          </label>
          <div>
            {/* <button type='button' disabled={!preEnable} onClick={onPre}>
              <PrevIcon />
            </button>
            <button type='button' disabled={!nextEnable} onClick={onNext}>
              <MoreIcon />
            </button> */}
            <button
              type='button'
              className={styles.sectionBtn}
              // disabled={!preEnable}
              onClick={onPre}
            >
              <PrevIcon />
            </button>
            <button
              type='button'
              className={styles.sectionBtn}
              // disabled={!nextEnable}
              onClick={onNext}
            >
              <MoreIcon />
            </button>
          </div>
        </div>
      </div>
      {hoverData && (
        <div className={styles.sectionContainer}>
          <div className={styles.sectionMain}>
            <div className={styles.section} ref={sectionRef}>
              <label className={styles.sectionTitle}>{hoverTitle}</label>
              {(hoverData || []).map((sectionData, index) => {
                return (
                  <SectionItem
                    langKey={langKey}
                    data={sectionData}
                    key={sectionData.id + "_" + index}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </section>
  );
});

export default FunctionFilter;
