"use client";
import React, { useState, useEffect, useTransition } from "react";
import CompanyCardList from "./components/CompanyCardList";
import styles from "./Companies.module.scss";
import CompanyCardLoader from "components/Loader/CompanyCard";
import { useRouter } from "next/navigation";
import { SortFilter } from "./components/SortFilter/SortFilter";
import { Content } from "./_pc/components/layout";
import { Pagination } from "app/(new-pages)/components/Pagination";
import { SearchInput } from "./_pc/components/Header";
import { formatTemplateString } from "helpers/formatter";
import { LoadingPC } from "./_pc/components/CircleLoading/CircleLoading";

interface IProps {
  data: any;
  params: any;
  searchParams: any;
  companies: any[];
}

const SIZE = 15;
const CompaniesLoader = () => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: 16 }}>
      {[...Array(12)].map((_, i) => (
        <div
          style={{
            width: "calc((100% - 32px) / 3)"
          }}
          key={i}
        >
          <CompanyCardLoader />
        </div>
      ))}
    </div>
  );
};

const Companies = (props: IProps) => {
  const { data = {}, params, searchParams, companies } = props;
  const langKey = params.lang;
  const [totalPage, setTotalPage] = useState(data?.total_pages);
  const [query, setQuery] = useState(searchParams?.query || "");

  // console.log("data", companies, data, props);
  const { new_title } = data;

  useEffect(() => {
    setTotalPage(data.total_pages);
  }, [data]);

  const searchQuery = searchParams;

  const router = useRouter();
  let [isFeaturedCompaniesFetching, translate] = useTransition();
  // isFeaturedCompaniesFetching = true;

  const [filter, setFilter] = useState(searchParams);

  // console.log("filter", searchQuery, filter);

  const push = (params, options?) => {
    let newParams = { ...searchQuery, query, ...params, t: Date.now() };

    Object.keys(newParams).forEach((key) => {
      let value = newParams[key];
      if (value === "" || value === null || value === undefined) {
        delete newParams[key];
      }
    });

    const urlParams = new URLSearchParams(newParams);
    // console.log("newParams", urlParams, newParams);

    translate(() => {
      router.push(location.pathname + "?" + urlParams.toString(), options);
    });
  };

  return (
    <>
      <div className={styles.container} style={{ backgroundColor: "#fff", paddingBottom: 40 }}>
        {/* <div style={{ height: 64 }} /> */}
        {/* search */}
        <div className={styles.searchContainer}>
          <div
            className={styles.topHeaderText}
            //
            dangerouslySetInnerHTML={{
              __html: formatTemplateString(
                new_title.new_title,
                `<span class='coloredText'>${new_title.new_title_first}</span>`,
                `<span class='coloredText'>${new_title.new_title_second}</span>`
              )
            }}
          />
          <Content style={{ marginBottom: 16 }}>
            <SearchInput
              value={query}
              onSearch={(value) => {
                // console.log("ee", value);
                push({ query: value, page: 1 });
              }}
              onChange={setQuery}
              placeholder={data?.search_info?.input_text}
            />
          </Content>
        </div>
        {/*  */}
        {/*  */}
        <Content
          style={{
            padding: "32px 16px",
            borderRadius: "16px",
            backgroundColor: "#F8F9FA",
            marginTop: "16px"
          }}
        >
          {/* companies list */}
          <div className={styles.companiesWrapper}>
            <div id='listHeader'>
              <div className={styles.mainHeaderText}>
                {data.popular_company_title || "Popular Companies "}
              </div>
              {/*  */}
              <div
                style={{
                  height: "1px",
                  backgroundColor: "#EBEEF0",
                  margin: "16px 0 12px 0"
                }}
              ></div>
              <div
                style={{
                  position: "relative",
                  marginBottom: 32
                  //  marginTop: 32
                }}
              >
                <SortFilter
                  data={data}
                  searchParams={searchParams}
                  onChange={(params) => {
                    // console.log("...params", params);
                    push({ ...params, page: 1 });
                    setFilter(params);
                  }}
                />
              </div>
              {/*  */}
            </div>
            <div className={styles.companies}>
              <div>
                {/*  */}
                {isFeaturedCompaniesFetching && (
                  <div style={{ padding: "160px 0", display: "flex", justifyContent: "center" }}>
                    <LoadingPC />
                  </div>
                )}
                {/* company card list */}
                <CompanyCardList
                  companiesList={data.companies}
                  isLoading={isFeaturedCompaniesFetching}
                  langKey={langKey}
                  empty={data.company_search_empty}
                  page={searchQuery.page}
                />
              </div>
              {/* Pagination  */}
              {Boolean(totalPage > 1 && !isFeaturedCompaniesFetching) && (
                <div className={styles.companiesPagination}>
                  <Pagination
                    current={+(searchQuery.page || 1)}
                    pageSize={SIZE}
                    total={totalPage * SIZE}
                    onChange={(value) => {
                      push({ query: searchParams.query, page: value });

                      document
                        .getElementById("listHeader")
                        ?.scrollIntoView?.({ behavior: "smooth" });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </Content>
      </div>
    </>
  );
};

export default Companies;
