import { useCompanyDetail } from "../../../DataProvider";
import { Fb, Instagram, LinkedIn, X, YuTuBe } from "./icon";
import styles from "./index.module.scss";

export const OverviewTable = () => {
  const { companyPageData } = useCompanyDetail();
  const overviewCompany = companyPageData?.company_about?.company_overview || {};

  const mediaMap: Record<string, any> = {};
  (overviewCompany.media || []).forEach((item) => {
    mediaMap[item.title] = item.url;
  });

  const arr = [
    {
      icon: LinkedIn,
      link: mediaMap?.Linkedin,
      name: "Linkedin"
    },
    {
      icon: Instagram,
      link: mediaMap?.Instagram,
      name: "Instagram"
    },
    {
      icon: X,
      link: mediaMap?.Twitter,
      name: "Twitter"
    },
    {
      icon: Fb,
      link: mediaMap?.Facebook,
      name: "Facebook"
    },
    {
      icon: YuTuBe,
      link: mediaMap?.YouTube,
      name: "YouTube"
    }
  ].filter((item) => item.link);

  const data = [
    {
      label: overviewCompany?.website_title,
      value: overviewCompany?.website
    },
    {
      label: overviewCompany?.company_size_title,
      value: overviewCompany?.company_size
    },
    {
      label: overviewCompany?.industry_title,
      value: overviewCompany?.industry
    },
    {
      label: overviewCompany?.financing_stage_title,
      value: overviewCompany?.financing_stage
    },
    {
      label: overviewCompany?.media_title,
      value: !arr.length ? null : (
        <>
          {arr.map((v) => {
            const { icon: Icon } = v;
            return (
              <a
                href={v.link}
                key={v.link}
                target='_blank'
                title={v.link}
                style={{ display: "inline-block", width: 22, height: 22 }}
              >
                <Icon size={22} />
              </a>
            );
          })}
        </>
      )
    }
  ].filter((v) => v.label && v.value);

  return (
    <div className={styles.overviewTable}>
      {data.map((v, i) => {
        //
        //
        return (
          <div style={{ display: "flex" }}>
            <span className={styles.label}>{v.label}</span>
            <span className={styles.value}>{v.value}</span>
          </div>
        );
      })}
    </div>
  );
};
