'use client'

import React from 'react'
import Link from 'components/Link'
import styles from '../../Footer.module.scss'
import { getCurLanguage } from 'helpers/country'

const About = (props: any) => {
  const { blogAddressUrl, countryKey, langKey, data } = props

  const { about, aboutBossjob, dataPrivacyGuide, FAQ, latestNews } = data?.foot || {}
  const { currentLangAlias } = getCurLanguage()
  const languageCode = currentLangAlias === 'tr' ? 'en' : currentLangAlias
  const FAQUrl = `https://bossjob.crisp.help/${languageCode}`

  return (
    <ul className={styles.footerMobileLink}>
      <li className={styles.footerLinkTitle}>
        <span>{about}</span>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={`/${langKey}/company/bossjob-1668`}
          title={aboutBossjob}
          aTag={false}
          external={false}
        >
          <span>{aboutBossjob}</span>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={blogAddressUrl?.DataPrivacyGuide}
          title={dataPrivacyGuide}
          external
        >
          <span>{dataPrivacyGuide}</span>
        </Link>
      </li>
      <li>
        <Link className={styles.footerLink} to={FAQUrl} title={FAQ} external>
          <span>{FAQ}</span>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={`https://blog.bossjob.${countryKey === 'jp' ? 'jp' : 'ph'}`}
          title={latestNews}
          external
        >
          <span>{latestNews}</span>
        </Link>
      </li>
    </ul>
  )
}

export default About
