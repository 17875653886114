"use client";
import React, { useState } from "react";
import classNames from "classnames";
import ShowMore from "./ShowMore.pc";
import BenefitsItem from "./BenefitsItem.pc";
import styles from "./Benefits.pc.module.scss";

export type propsType = {
  className?: string;
  data?: {
    [key: string]: any;
  };
};

const Benefits = ({ data, className = "" }: propsType) => {
  const [showMore, setShowMore] = useState(false);

  if (data?.benefits_info?.length) {
    return (
      <div className={classNames(styles.benefits, className)}>
        <BenefitsItem data={data} showMore={showMore} />
        <ShowMore
          data={data}
          showMore={showMore}
          handleClick={() => {
            setShowMore((state) => !state);
          }}
        />
      </div>
    );
  }
  return null;
};

export default Benefits;
