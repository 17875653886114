import { cellHoneIcon } from "images/svg";
import classNames from "classnames";

import styles from "../../styles/index.pc.module.scss";
// import PopoverDownload from "./PopoverDownload";

const StickySearch = ({
  langKey,
  search_info,
  isShow,
  isDownloadAppOpacity,
  onMouseEnter = () => {},
  onMouseLeave = () => {}
}) => {
  const { download, chat_boss } = search_info;

  if (!isShow) {
    return null;
  }

  if (isShow) {
    return (
      <div
        className={classNames({
          [styles.download]: true,
          [styles.downloadNoOpacity]: isDownloadAppOpacity
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        style={{ minWidth: langKey === "ja-JP" ? "216px" : "183px" }}
      >
        <div className={styles.icon}>{cellHoneIcon}</div>
        <div className={styles.downloadDescribe}>
          <h5>{download}</h5>
          <span>{chat_boss}</span>
        </div>

        {/* <PopoverDownload search_info={search_info} /> */}
      </div>
    );
  }
};

export default StickySearch;
