"use client";
import React from "react";
import Link from "next/link";
import { addEventTag } from "helpers/utilities";
import { setCookie } from "helpers/cookies";
import {
  getDeviceNameTracker,
  setCompanyViewCookiesTracker,
  viewCompanySourceTracker
} from "components/ReportTracker/util";
import { getCountryId } from "helpers/country";

interface IProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  isMobile: boolean;
  company: any;
  device_udid: string;
  [key: string]: any;
}

const LinkToCompany = (props: IProps) => {
  const { children, isMobile, company, device_udid, ...rest } = props;
  const country_id = getCountryId();

  const onClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const params = {
      id: company?.id,
      payload: {
        source: viewCompanySourceTracker.home,
        device: getDeviceNameTracker(isMobile),
        country_id: country_id || "",
        device_udid
      }
    };

    setCompanyViewCookiesTracker(setCookie, params);
    addEventTag("hp_top_companies_card_click");
  };
  return (
    <Link {...rest} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkToCompany;
