"use client";
import React from "react";
import styles from "../../styles/index.pc.module.scss";
import { addEventTag } from "helpers/utilities";
import Link from "next/link";

const LinkCardMoreClient = ({ data, langKey }) => {
  return (
    <Link
      onClick={() => addEventTag("hp_top_companies_card_more_click")}
      className={styles.companyCardBottomViewMore}
      href={`/${langKey}${data?.company_url}`}
      target='_blank'
    >
      {data?.more_text}{" "}
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='17'
        height='16'
        viewBox='0 0 17 16'
        fill='none'
      >
        <path
          d='M5.33337 11.3332L12 4.6665M12 4.6665H5.33337M12 4.6665V11.3332'
          stroke='black'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </Link>
  );
};

export default LinkCardMoreClient;
