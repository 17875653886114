import { handleUserCookiesConfig, setCookie } from "helpers/cookies";
import { getLang } from "helpers/country";
import jobSource from "helpers/jobSource";
import { assistTrigger } from "store/services/users/addUserPreferences";
import { fetchUserOwnDetailService } from "store/services/users/fetchUserOwnDetail";

export const getImageBlob = async (url: any) => {
  try {
    const imgBlob = await fetch(url).then(res => res.blob());
    return imgBlob
  } catch (error) {
    console.log('error', error)
    return null
  }
}

export function getDefaultAvatarNumber(url) {
  if (!url) return undefined
  const parts = url.split('/');
  const lastPart = parts[parts.length - 1];
  let numberPart = undefined;

  const match = lastPart.match(/icon-avatar-(\d+)\.png$/);
  if (match) {
    numberPart = match[1];
  }
  return numberPart
}

export const getSourceFrom = () => {
  if (typeof window === 'undefined') return undefined
  const params = new URLSearchParams(window?.location?.search)
  const _fromSearchParams = params.get('from') || ''
  return _fromSearchParams
}

export const getChatJobId = () => {
  if (typeof window === 'undefined') return undefined
  const params = new URLSearchParams(window?.location?.search)
  const _fromSearchParams = params.get('chatJobId') || ''
  const _chatJobId = sessionStorage.getItem('chatJobId') || ''
  return _fromSearchParams || _chatJobId
}

export const addQueryParams = (url: string, params: Record<string, any>) => {
  const baseUrl = url.split('?')[0] || '';
  const searchWords = url.split('?')[1] || '';
  const searchParams = new URLSearchParams(searchWords);
  Object.keys(params).forEach(key => {
    searchParams.has(key) && searchParams.delete(key);
    params[key] && searchParams.append(key, params[key]);
  });

  const search = searchParams.toString();
  return baseUrl + (search ? '?' + search : '');
}

export const getUrlSearchString = (step = 1) => {
  if (typeof window === 'undefined') return undefined
  const search = window.location.search || ''
  const searchWords = addQueryParams(search, {
    step: step,
    from: getSourceFrom(),
    chatJobId: getChatJobId()
  })
  return searchWords
}

export const isChatProcess = () => {
  const from = getSourceFrom()
  return from == 'chat'
}

export const jumpPage = () => {
  const chatProcess = isChatProcess()
  const langKey = getLang()
  fetchUserOwnDetailService().then((res) => {
    return assistTrigger().then(() => {
      const userDetail = res?.data?.data
      const userCookie = handleUserCookiesConfig(userDetail)
      setCookie('user', userCookie)
      const isChatRedirect = sessionStorage.getItem('isChatRedirect')
      const redirectPage = sessionStorage.getItem('redirectPage')
      const chatJobId = getChatJobId()
      sessionStorage.removeItem('chatJobId')

      if (chatJobId && chatProcess) {
        location.href = `/chat-redirect/${chatJobId}?source=${jobSource()}`
      } else if (isChatRedirect) {
        sessionStorage.removeItem('isChatRedirect')
        location.href = isChatRedirect
      } else if (redirectPage) {
        sessionStorage.removeItem('redirectPage')
        location.href = redirectPage
      } else {
        location.href = `/${langKey}/my-jobs?`
      }
    })
  })
}