import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";
// import { Card } from "../card";
import styles from "./index.module.scss";
import { useCompanyDetail } from "../../../DataProvider";
import { languageContext } from "app/components/providers/languageProvider";
import { MyInput, MySelect } from "./Select";
import { getCompanyJobs } from "../../../service";
import { Loading } from "./component/CircleLoading/CircleLoading";
// import Empty from "app/components/empty/empty";
import { flatten, debounce } from "lodash-es";
import { JobItem } from "./component/JobItem";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";
import { Pagination } from "../Pagination/Pagination";
import MultiSelectPc from "app/(new-pages)/components/allSelect/baseSelect/pc/MultiSelect";

import defaultImg from "./default.png";

const NoDataSvg = () => {
  return <img width={160} height={160} src={defaultImg.src} />;
};

export const Salary = ({ salaryType, salaryRange, typeSize = 14 }) => {
  return (
    <>
      {salaryRange}
      {salaryType && (
        <span
          style={{
            color: "#2378e5",
            fontSize: typeSize,
            letterSpacing: "0.12px",
            fontWeight: "400"
          }}
        >
          {`[${salaryType}]`}
        </span>
      )}
    </>
  );
};

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

const FunctionFilter = ({ data, title, value, onChange }) => {
  if (!data.length) {
    return null;
  }

  return (
    <div className={styles.jobFunctionRoot}>
      <div className={styles.jobFunctionWrapper}>
        {data.map((item) => {
          return (
            <div
              key={item.id}
              className={classNames(styles.jobFunctionItem, item.id === value && styles.checked)}
              onClick={() => {
                onChange(item.id);
              }}
            >
              {item.value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ClearIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path
        d='M17.0443 10.7442C16.8128 12.9191 15.5805 14.9572 13.5413 16.1345C10.1534 18.0905 5.82132 16.9298 3.86531 13.5419L3.65698 13.181M2.95527 9.25584C3.18677 7.08093 4.4191 5.04282 6.45825 3.86551C9.84616 1.90951 14.1783 3.07029 16.1343 6.45819L16.3426 6.81904M2.91113 15.0551L3.52118 12.7783L5.79788 13.3884M14.2022 6.61167L16.4789 7.22172L17.0889 4.94501'
        stroke='#121212'
        stroke-width='1.66667'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  );
};

const initFilterValue = () => {
  return {
    query: "",
    jobFunction: 0,
    xpl: [],
    edu: [],
    jobType: []
  };
};

export const JobTab = () => {
  const { lang, companyPageData, companyId } = useCompanyDetail();
  console.log("remoteJobs___", companyPageData, lang);

  const { company_jobs: jobPageData = {} } = companyPageData || {};
  const { function_filter } = jobPageData;
  const { jobs: initJobs = [], total_page = 0 } = companyPageData?.company_jobs || {};

  let [jobs, setJobs] = useState(initJobs);
  const contextLang = useContext(languageContext);

  const [pagination, setPagination] = useState({ size: 10, page: 1, total_page });
  const [loading, setLoading] = useState(false);
  const searchInfo = jobPageData?.search_info;
  const { job_type_text, job_type_filter, degree_text, degree_filter, xp_lvl_text, xp_lvl_filter } =
    jobPageData;

  const [filter, setFilter] = useState(initFilterValue());

  const getJobs = useCallback(
    (params) => {
      setLoading(true);
      if (params.page) {
        setPagination((pre) => {
          return { ...pre, page: params.page };
        });
      }
      const { jobFunction = "", xpl = [], edu = [], jobType = [], query } = filter;
      const { page, size } = pagination;
      getCompanyJobs({
        companyId,
        params: {
          page,
          size,
          xp_lvl_ids: flatten(xpl).join(","),
          degree_ids: flatten(edu).join(","),
          job_type_ids: flatten(jobType).join(","),
          job_function_id: jobFunction,
          query: query,
          ...params
        }
      })
        .then((res) => {
          let { jobs = [], total_page = 1 } = res.data.data?.company_jobs || {};
          setJobs(jobs);
          setPagination((pre) => {
            return { ...pre, total_page: total_page };
          });
        })
        .catch((err) => {
          setJobs([]);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [companyId, pagination, filter]
  );
  const fetchRef = useRef(getJobs);
  fetchRef.current = getJobs;

  const handleQueryChange = useCallback(
    debounce((newValue) => {
      fetchRef.current(newValue);
    }, 500),
    []
  );

  console.log("filterfilter", filter);

  return (
    <div>
      <div>
        <FunctionFilter
          data={function_filter}
          value={filter.jobFunction}
          onChange={(value) => {
            setFilter((pre) => {
              return { ...pre, jobFunction: value };
            });
            getJobs({ job_function_id: value, page: 1 });
          }}
          title={jobPageData.function_title}
        />

        <div className={styles.filterRoot}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 8,
              position: "relative",
              zIndex: 1
            }}
          >
            <MultiSelectPc
              options={xp_lvl_filter?.map((v) => {
                return { value: v.id, label: v.value };
              })}
              visibleSearch={false}
              value={filter.xpl}
              onChange={(value) => {
                setFilter((pre) => {
                  return { ...pre, xpl: value };
                });
                // console.log("value", value);
                getJobs({ xp_lvl_ids: flatten(value).join(","), page: 1 });
              }}
              label={xp_lvl_text}
            />

            <MultiSelectPc
              options={degree_filter?.map((v) => {
                return { value: v.id, label: v.value };
              })}
              value={filter.edu}
              visibleSearch={false}
              onChange={(value) => {
                setFilter((pre) => {
                  return { ...pre, edu: value };
                });
                getJobs({ degree_ids: flatten(value).join(","), page: 1 });
              }}
              label={degree_text}
            />

            <MultiSelectPc
              options={job_type_filter?.map((v) => {
                return { value: v.id, label: v.value };
              })}
              value={filter.jobType}
              visibleSearch={false}
              onChange={(value) => {
                setFilter((pre) => {
                  return { ...pre, jobType: value };
                });
                getJobs({ job_type_ids: flatten(value).join(","), page: 1 });
              }}
              label={job_type_text}
            />

            <Button
              btnSize='size44'
              variant='whiteBoxShadowBtn'
              style={{ padding: 0, width: 44 }}
              onClick={() => {
                let newParams = { ...initFilterValue(), query: filter.query };
                setFilter(newParams);
                let newP = {
                  xp_lvl_ids: "",
                  degree_ids: "",
                  job_type_ids: "",
                  job_function_id: 0
                };
                getJobs({ ...newP, page: 1 });
              }}
            >
              <ClearIcon />
            </Button>
          </div>

          <div style={{ marginLeft: "auto", display: "flex", justifyContent: "flex-end" }}>
            <div style={{ marginLeft: 20 }}>
              <MyInput
                placeholder={searchInfo?.input_text}
                value={filter.query}
                onChange={(value) => {
                  setFilter((pre) => {
                    return { ...pre, query: value };
                  });
                  handleQueryChange({ query: value, page: 1 });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.jobsRoot}>
        {loading && (
          <div
            style={{ padding: "200px 0", width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Loading />
          </div>
        )}

        {!loading && Boolean(jobs.length) && (
          <>
            {jobs.map((item, index) => {
              return <JobItem item={item} lang={lang} key={index} />;
            })}

            <div
              style={{ height: 100, textAlign: "center", width: "100%", padding: "32px 0 20px 0" }}
            >
              <Pagination
                current={pagination.page}
                pageSize={pagination.size}
                total={pagination.total_page * pagination.size}
                onChange={(value) => {
                  getJobs({ page: value });
                  // window.scrollTo({ top: 100 });
                  setPagination((pre) => {
                    return { ...pre, page: value };
                  });

                  let holder = document.getElementById("viewHolder");
                  if (holder && !isElementInViewport(holder)) {
                    holder.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              />
            </div>
          </>
        )}

        {!loading && !Boolean(jobs.length) && (
          <div className={styles.emptyNode} style={{ paddingTop: 100 }}>
            <NoDataSvg />
            <div>No Data</div>
          </div>
        )}
      </div>
    </div>
  );
};
