import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

export const TextOverflow = ({ text, moreText }) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const target = ref.current as HTMLDivElement;
    setVisible(target.scrollHeight > target.clientHeight);
  }, []);

  return (
    <div className={classNames(styles.textOverflow, open && styles.open)} ref={ref}>
      {text}
      {visible && !open && (
        <div
          className={classNames(styles.expandRoot, open && styles.open)}
          onClick={() => {
            setOpen((pre) => !pre);
          }}
        >
          ... <span className={styles.btnText}>{moreText}</span>
        </div>
      )}
    </div>
  );
};
