'use client'
import { useEffect, useState } from 'react'
import { getCookie, setCookie } from 'helpers/cookies'
import SiteGuideModal from 'components/SiteGuideModal'
import { receiveNotification } from 'bossjob-remote/dist/clientStorage'
import { isMobileDevice } from 'helpers/utilities'
export default function SiteGuideModalWrapped({ lang }) {
  const cookieValue = getCookie('_siteguideModal')
    ? JSON.parse(getCookie('_siteguideModal'))
    : undefined
  const [keepCookie, setKeepCookie] = useState(!Boolean(cookieValue))
  const [meetOrigin, setMeetOrigin] = useState(false)
  const [meetPathname, setMeetPathname] = useState(false)
  const [show, setShow] = useState(false)
  const [display, setDisplay] = useState(false)
  const [clickTriggerModal, setClickTriggerModal] = useState(false)
  const isMobile = isMobileDevice()

  useEffect(() => {
    return receiveNotification('SHOW_SIDE_GUIDE_MODAL', () => {
      setClickTriggerModal(true)
      if (!display) {
        setShow(true)
        setTimeout(() => {
          setShow(false)
          // Promise.resolve().then(() => setShow(true))
          setTimeout(() => {
            setShow(true)
            setDisplay(true)
          }, 0)
        }, 1)
      } else {
        setShow(true)
      }
    })
  }, [])
  useEffect(() => {
    if (
      /\.com$/.test(location.origin)
      // || location.origin.indexOf('localhost') >= 0
    ) {
      setMeetOrigin(true)
    }
    if (location.pathname.indexOf('get-started') < 0) {
      setMeetPathname(true)
    }
  }, [globalThis?.location?.origin, globalThis?.location?.pathname])
  useEffect(() => {
    if (meetPathname && meetOrigin && keepCookie) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
        // Promise.resolve().then(() => setShow(true))
        setTimeout(() => {
          setShow(true)
          setDisplay(true)
        }, 0)
      }, 1)
    }
  }, [meetPathname, meetOrigin, keepCookie])

  if (isMobile) {
    return null
  }

  if (show) {
    // 点击触发的弹框需要默认显示中间动画
    if (clickTriggerModal) {
      return (
        <SiteGuideModal
          key={Date.now() + 100}
          lang={lang}
          onClose={() => {
            setCookie('_siteguideModal', JSON.stringify(true))
            setKeepCookie(false)
            setShow(false)
          }}
        />
      )
    }
    return (
      <div
        style={{
          zIndex: display ? 2000 : -100,
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0
        }}
        key={Date.now() + 50}
      >
        <SiteGuideModal
          key={Date.now() + 100}
          lang={lang}
          displayControl={true}
          onClose={() => {
            setCookie('_siteguideModal', JSON.stringify(true))
            setKeepCookie(false)
            setShow(false)
          }}
        />
      </div>
    )
  }
  return null
}
