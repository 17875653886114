'use client'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { isMobileDevice } from 'helpers/utilities'
import { compressImage } from 'helpers/imageCompression'
import { ReactComponent as CloseIcon } from '../../images/svg/close.svg'
import ChangeAvatar from './ChangeAvatar'
import EditAvatar from './EditAvatar'
import MobileModal from '../Common/MobileModal'

const AvatarImageModal = (props: {
  open: boolean
  onOk: (file: any, index?: number) => void
  onCancel: () => void
  lang: any
  selectAvatarIndex?: any
}) => {
  const { lang, open, onOk, onCancel } = props

  const [step, setStep] = useState(1)
  const [file, setFile] = useState(null)
  const isMobile = isMobileDevice()

  const handleOk = () => {
    onOk && onOk(false)
  }

  const handleCancel = () => {
    onCancel && onCancel()
  }

  const handleSelectAvatar = (file, index) => {
    // console.log('select avatar', file, index)
    onOk && onOk(file, index)
  }

  const handleCropAvatar = (file) => {
    // console.log('crop avatar', file)
    onOk && onOk(file, undefined)
  }

  const onChangeUpload = (file, index) => {
    // console.log('file', file, index)
    let img
    let aspectRatio
    img = new Image()
    const objectUrl = URL.createObjectURL(file)
    img.onload = async function () {
      aspectRatio = this.width / this.height
      const compressedFile = await compressImage(file, 100, aspectRatio, 400)
      const createNewFromData = new File([compressedFile], file?.name || 'avatar.jpg', {
        type: 'image/*'
      })
      setFile(createNewFromData)
      setStep(2)
    }
    img.src = objectUrl

    // setFile(file)
    // setStep(2)
  }

  const renderContent = () => {
    if (step == 1) {
      return (
        <ChangeAvatar
          lang={lang}
          onOk={handleSelectAvatar}
          onChange={onChangeUpload}
          selectAvatarIndex={props.selectAvatarIndex}
        />
      )
    }
    return (
      <EditAvatar
        lang={lang}
        onOk={handleCropAvatar}
        onCancel={() => setStep(1)}
        previewImage={file}
      />
    )
  }

  if (isMobile) {
    return (
      <MobileModal onClose={handleCancel} open={open} onCancel={onCancel}>
        {renderContent()}
      </MobileModal>
    )
  }

  return (
    <Modal
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      wrapClassName='initWrapModal'
      closeIcon={<CloseIcon />}
      maskClosable={true}
      title={null}
      footer={null}
      width={420}
      zIndex={2002}
      // styles={{
      //   body: {
      //     padding: '12px 8px 12px 8px',
      //   }
      // }}
    >
      {renderContent()}
    </Modal>
  )
}

export default AvatarImageModal
