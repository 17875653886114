import { useCompanyDetail } from "../../../DataProvider";
import { MobileAlbum } from "../Album";
import { BrandAndProduct } from "../BrandAndProduct";
import { MobileCard } from "../Card";
import { HiBoss } from "../HiBoss";
import { Location } from "../Location";
import { CompanyOverview } from "../Overview";
import { SectionHeader, Section, SecondarySectionHeader } from "../SectionHeader";
import { TextOverflow } from "../TextOverFlow";

export const AboutPage = () => {
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData?.company_about || {};

  const {
    about_the_company = {},
    company_overview = {},
    company_brand_product = {},
    company_album = {}
  } = about;

  // console.log("companyAlbumscompanyAlbums", companyPageData);

  return (
    <div
      style={{
        backgroundColor: "#FDFDFD",
        padding: "0px 12px 12px 12px",
        borderRadius: "12px",
        boxShadow: "0px 4px 6px 0px rgba(162, 183, 203, 0.10)"
      }}
    >
      <MobileCard style={{ padding: "20px 16px" }}>
        <Section>
          <SectionHeader>{about_the_company.title}</SectionHeader>

          {Boolean(about_the_company?.description) ? (
            <TextOverflow
              text={about_the_company.description}
              moreText={about_the_company.more_text}
            />
          ) : (
            ""
          )}
        </Section>
        <Section>
          <SecondarySectionHeader>{company_overview.title}</SecondarySectionHeader>
          <CompanyOverview />
        </Section>

        <Section>
          <SecondarySectionHeader>{company_album.title}</SecondarySectionHeader>
          <MobileAlbum pictures={company_album?.albums || []} />
        </Section>

        <Location />

        <Section>
          <SectionHeader>{company_brand_product.title}</SectionHeader>
          <BrandAndProduct />
        </Section>
        <HiBoss />
      </MobileCard>
    </div>
  );
};
