import { Button } from "app/(new-pages)/components/Button/pc/index.pc";
import { postNotification } from "bossjob-remote/dist/clientStorage";
import { useSharedData } from "bossjob-remote/dist/hooks";
import styles from "./index.module.scss";
import { useEffect, useState } from "react";
import { useCompanyDetail } from "../../../DataProvider";

export const SignInChatWithBoss = ({ tabValue }) => {
  const me = useSharedData("ME");
  const [hasLogin, setHasLogin] = useState(false);
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData.company_about;

  useEffect(() => {
    setHasLogin(Boolean(me));
  }, [me]);

  if (hasLogin) {
    return null;
  }

  console.log("tabValue", tabValue);

  return (
    <div className={styles.outerRoot} style={{ padding: 0 }}>
      <div className={styles.signInRoot}>
        <div className={styles.header}>{about.sign_in_title_text}</div>
        <Button
          variant='whiteBtn'
          btnSize='size44'
          style={{ width: "100%" }}
          onClick={() => {
            postNotification("SHOW_LOGIN_MODAL");
          }}
        >
          {about.sign_in_text}
        </Button>
      </div>
    </div>
  );
};
