'use client'
import React, { useMemo } from 'react'
import { isMobileDevice } from 'helpers/utilities'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import classNames from 'classnames'

import { CardIcon, MobileCardIcon } from './CardIcon'
import ClientButton, { PTag } from './ClientButton'
// import ClientClose from './ClientClose'

import styles from './index.module.scss'

export const CompleteProfileCard = () => {
  const isMobile = isMobileDevice()

  const userDetail = useSharedData('ME')
  const dic = useSharedData('DICTIONARY') || {}
  // console.log('langDic::', dic)
  const { profile = {} } = dic

  const show = useMemo(() => {
    if (!userDetail) {
      return false
    }
    return !userDetail?.is_profile_completed
  }, [userDetail])

  if (isMobile && show) {
    return (
      <div className={classNames([styles.mobile, 'complete-profile-card'])}>
        <div className={styles.mobile_content}>
          <div className={styles.title}>{profile.completedCard?.title}</div>
          <p className={styles.description + ' ' + styles.mobile_description}>
            {profile.completedCard?.description}
          </p>
          <div className={styles.mobile_button}>
            <ClientButton dictionary={dic} userDetail={userDetail} />
          </div>
        </div>
        <div className={styles.mobile_icon}>
          <MobileCardIcon />
        </div>
      </div>
    )
  }

  // return show ? (
  //   <div className={classNames([styles.main, 'complete-profile-card'])}>
  //     <div className={styles.left}>
  //       <div className={styles.icon}>
  //         <CardIcon />
  //       </div>
  //       <div className={styles.content}>
  //         <div className={styles.title}>{profile.completedCard?.title}</div>
  //         <p className={styles.description}>{profile.completedCard?.description}</p>
  //       </div>
  //     </div>
  //     <div className={styles.right}>
  //       <ClientButton dictionary={dic} userDetail={userDetail} />
  //     </div>
  //     {/* <div className={styles.close}>
  //         <ClientClose />
  //       </div> */}
  //   </div>
  // ) : null

  return show ? (
    <div className={styles.newMain}>
      <div className={styles.newMain_left}>
        <ParagraphSvg /> {profile.completedCard?.title}
      </div>

      <div className={styles.newMain_right}>
        <PTag dictionary={dic} userDetail={userDetail} />
      </div>
    </div>
  ) : null
}

const ParagraphSvg = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
      <path d='M3.5 17V3H16.3052L17 11.7653L12 17H3.5Z' fill='#1C70EE' fill-opacity='0.1' />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.5 4.16675C2.5 2.78604 3.61929 1.66675 5 1.66675H15C16.3807 1.66675 17.5 2.78604 17.5 4.16675V11.3317C17.5 11.9516 17.2697 12.5493 16.8538 13.009L12.7809 17.5107C12.3069 18.0345 11.6334 18.3334 10.927 18.3334H5C3.61929 18.3334 2.5 17.2141 2.5 15.8334V4.16675ZM5 3.33341C4.53976 3.33341 4.16667 3.70651 4.16667 4.16675L4.16667 15.8334C4.16667 16.2937 4.53976 16.6667 5 16.6667H10.8333V13.3334C10.8333 11.9527 11.9526 10.8334 13.3333 10.8334L15.8333 10.8334V4.16675C15.8333 3.70651 15.4602 3.33342 15 3.33342L5 3.33341ZM15.0667 12.5001L13.3333 12.5001C12.8731 12.5001 12.5 12.8732 12.5 13.3334V15.3369L15.0667 12.5001Z'
        fill='#1C70EE'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.6665 5.83325H13.3332V7.49992H6.6665V5.83325ZM6.6665 9.16659H10.8332V10.8333H6.6665V9.16659Z'
        fill='#1C70EE'
      />
    </svg>
  )
}

export default CompleteProfileCard
