import React, { useMemo } from "react";
import styles from "../../../styles/index.pc.module.scss";
import Image from "next/image";

const JobCardPc = ({ item, index, currentIndex, changeDetail }) => {
  const { salary_info = {}, company = {} } = item || {};

  const showLocation = useMemo(() => {
    if (item?.job_tags?.length > 0) {
      return item?.job_tags?.join(" · ");
    }

    return "";
  }, [item]);

  return (
    <div
      key={item.job_id}
      className={`${styles.listItem} ${index === currentIndex ? styles.listItemActive : ""}`}
      onClick={(e) => changeDetail(e, index)}
    >
      <div className={styles.wrapper}>
        <div className={styles.jobHireTop}>
          <h3 className={styles.jobHireTopTitle}>
            {item.job_title}
            {item?.is_urgent && (
              <span className={styles.jobHireTitleUrgent}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='15'
                  height='14'
                  viewBox='0 0 15 14'
                  fill='none'
                >
                  <path
                    d='M10.2416 1.75098H6.0334C5.83777 1.75098 5.6605 1.86618 5.58105 2.04494L2.97486 7.90887C2.82938 8.23621 3.06899 8.60492 3.4272 8.60492H6.09248L4.78982 13.1642C4.73302 13.363 4.98066 13.5049 5.12343 13.3553L11.7669 6.39552C12.0676 6.08051 11.8443 5.55872 11.4088 5.55872H9.13868L10.6844 2.46736C10.8489 2.13823 10.6096 1.75098 10.2416 1.75098Z'
                    fill='black'
                  />
                </svg>
                {item?.urgent_text}
              </span>
            )}
          </h3>
          <div className={styles.salary}>
            <span className={styles.salaryText}>
              {salary_info?.text}
              {salary_info?.type_text && (
                <span className={styles.month}>[{salary_info?.type_text}]</span>
              )}
            </span>
          </div>
          <div className={styles.listTag}>
            {item?.job_tags?.length > 0
              ? item?.job_tags.map((tag) => {
                  return (
                    <span className={styles.jobCardLocationItem} key={tag}>
                      {tag}
                    </span>
                  );
                })
              : null}
          </div>
        </div>
        <div className={styles.listCompany}>
          <Image
            src={company.company_logo}
            alt={company.company_name}
            className={styles.listCompanyLogo}
            width={36}
            height={36}
          ></Image>
          <a className={styles.jobHireCompanyName} href={company.company_url} target='_blank'>
            {company.company_name}
          </a>
        </div>
      </div>
    </div>
  );
};

export default JobCardPc;
