import classNames from "classnames";
import styles from "./index.module.scss";
import Link from "components/Link";

const DotIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginBottom: 4 }}
      width='3'
      height='2'
      viewBox='0 0 3 2'
      fill='none'
    >
      <circle cx='1.4165' cy='1' r='0.75' fill='black' />
    </svg>
  );
};

export const CompanyCardH5 = ({ company, lang }) => {
  const industryValue = company.company_tags;
  // console.log("companycompany", company);
  const { is_default_description } = company as any;
  const companyUrl = company?.company_url || "/";
  const country = company?.country_culture?.[0] || "";
  return (
    <Link
      className={styles.companyCardH5}
      to={"/" + lang + companyUrl}
      title={company?.company_name}
    >
      <div className={styles.topRoot}>
        <div className={styles.header}>
          <img
            alt={company?.company_name}
            src={company?.company_logo || company?.logo}
            className={styles.logo}
          />

          <div style={{ flex: 1, overflow: "hidden" }}>
            <div className={styles.name}>{company.company_name}</div>
            {Boolean(industryValue.length) && (
              <div
                className={styles.tags}
                style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
              >
                {industryValue.map((v, i) => {
                  return (
                    <>
                      {i !== 0 && (
                        <span style={{ margin: "0 8px" }}>
                          <DotIcon />
                        </span>
                      )}
                      <span className={styles.industryItem}>{v}</span>
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </div>

        <div className={classNames(styles.desc, is_default_description && styles.defaultDesc)}>
          {company?.description}
        </div>
      </div>

      <div className={styles.bottomContent}>
        <span className={styles.numberFlag}>
          <span>{company.jobs_num}</span>
          <span className={styles.text}>{company.suffix_text}</span>
        </span>
        <span className={styles.country}>
          {/* {company.more_text} */}
          {country}
        </span>
      </div>
    </Link>
  );
};
