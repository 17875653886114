import { convertSpecialCharacters } from "helpers/utilities";

export function splitArrayIntoGroups(arr: any[], groupSize: number) {
  if (!Array.isArray(arr)) return arr
  if (arr.length == 1 || arr.length == 0) return arr
  if (arr.length <= groupSize) return arr
  return arr.reduce((acc, curr, index) => {
    if (index % groupSize === 0) {
      acc.push([]);
    }
    acc[acc.length - 1].push(curr);
    return acc;
  }, []);
}

// Generates a search path based on the provided location values and keyword.
export function genWordLocationPath({
  locationValue,
  keyword
}: {
  locationValue: {
    region: string[],
    location: string[]
  },
  keyword: string
}) {

  const isEmptyLocation = !locationValue?.location?.length
  const searchWords = (keyword || '').trim()
  // console.log('genWordLocationPath', { locationValue, keyword, isEmptyLocation })

  // (不存在searchWords, 不存在location) ->  job-search
  if (isEmptyLocation && !searchWords) {
    return {
      searchPath: 'job-search'
    }
  }

  // (存在searchWords, 不存在location) ->  [searchWords]-jobs
  if (searchWords && isEmptyLocation) {
    const words = convertSpecialCharacters((searchWords || '').trim())
    return {
      searchPath: `${words}-jobs`
    }
  }

  const onlyOneLocation = locationValue?.location?.length == 1
  const multipleLocation = locationValue?.location?.length > 1

  // (不存在searchWords, 一个location) ->  [location]-jobs
  if (!isEmptyLocation && !searchWords && onlyOneLocation) {
    const { location } = locationValue
    return {
      searchPath: `${location[0]}-jobs`
    }
  }

  // (存在searchWords, 一个location) -> [searchWords]-jobs-in-[location]
  if (searchWords && !isEmptyLocation && onlyOneLocation) {
    const { location } = locationValue
    return {
      searchPath: `${searchWords}-jobs-in-${location[0]}`
    }
  }

  // (不存在searchWords, 多个location) -> job-search?region=xxx&location=xxx
  if (!isEmptyLocation && !searchWords && multipleLocation) {
    return {
      searchPath: `job-search`,
      multipleLocation: true,
    }
  }


  // (存在searchWords, 多个location) -> [searchWords]-jobs?region=xxx&location=xxx
  if (searchWords && !isEmptyLocation && multipleLocation) {
    return {
      searchPath: `${searchWords}-jobs`,
      multipleLocation: true
    }
  }

  return {
    searchPath: `job-search`,
  }
}