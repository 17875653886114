'use client'
import React, { useState, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { useRouter, usePathname } from 'next/navigation'
import styles from '../Header.module.scss'
import Link from 'components/Link'
import { useSharedData } from 'bossjob-remote/dist/hooks'
import { DefaultAvatar } from 'images'
import CountryLanguage from '../PublicHeader/countryLanguage'
import dynamic from 'next/dynamic'
const base64DefaultAvatar =
  'data:image/webp;base64,UklGRpoBAABXRUJQVlA4WAoAAAAQAAAALwAALwAAQUxQSD4AAAABT6CgbRuGP+b+jUREZB5JAkWxrVRAAjJgg08DCxjBCEQwgra+W11F9H8CSrB1q7C+nkti558wTKnEZm/suFZQOCA2AQAAUAkAnQEqMAAwAD6JNpZHpSKiITHWbfigEQloAKwywkPno3mRWTtHRANuzdoAI9DOkbLEvbwD4DlpYYVck2PSPNz8VA13iNzGN/iy8NVbaw4nKYAA/u/YP+i7fl/zq7fG+Y4L8UPHMP/xDgpN57//xUXRCaada7X2Jqu3tA3lRQc9qAjeoUtiilX40bN5u12QNEbD7VfUdSbn1Uzt33h99JQQXpaBju+9OQ24VimFveCzbveLLRHyLjmkS2ZuufJ+6l8zTjSm9oDpA+TmVdZBA85eVU+hqrDB4SUgMi06TTFPUwOf15QAR8IZEcFf/8MSIjwOqr22R+4wodYDSPmPq0VHmBjgeFV8yw/1hzLSL8NijwFM8CK5lR++xwgqR+cput0l1jUB/AK+vwNnLkvULLJhrc4AAA=='

interface IProps {
  langKey: string
  lang: any
  userInfo: any
  initUnread?: number
  countryKey: string
  isMobile?: boolean
}

const Dropdown = dynamic(() => import('antd/es/dropdown'), { ssr: false })
const DropDownMenu = dynamic(() => import('../Common/DropDownMenu'), { ssr: false })

const NavRight = (props: IProps) => {
  const { langKey, lang, userInfo, initUnread = 0, countryKey, isMobile } = props
  const router = useRouter()
  const pathname = usePathname()
  const { manageResume, Chat } = lang || {}
  const unread =
    typeof window !== 'undefined' ? useSharedData('CHAT_TOTAL_UNREAD') ?? initUnread : initUnread
  const totalUnread = unread
  const [showUnCompletedDot, setShowUnCompletedDot] = useState(false)

  const [open, setOpen] = useState(false)
  const [unreadNotification, setUnreadNotification] = useState(0)

  const isVip = useMemo(() => userInfo?.vip?.is_vip, [userInfo])
  const { avatar } = userInfo
  useEffect(() => {
    if (userInfo?.job_preferences) {
      const hasJobPreferences = userInfo.job_preferences?.length > 0
      setShowUnCompletedDot(!userInfo?.is_profile_completed || !hasJobPreferences)
    }
  }, [userInfo])
  const Avatar = (
    <img
      src={avatar ?? base64DefaultAvatar}
      width={isMobile ? 26 : 40}
      height={isMobile ? 26 : 40}
      style={{ borderRadius: '50%', border: isVip ? '1px solid #EBEEF0' : '' }}
      alt='avatar'
      onError={(e) => {
        console.log({ userInfoOnError: e })
        ;(e.target as HTMLInputElement).src = DefaultAvatar
      }}
    />
  )

  return (
    <ul className={styles.headerLinksList}>
      <React.Fragment>
        <li
          className={classNames([styles.headerLink, styles.headerLinkRightItem])}
          style={{ flexDirection: 'row', alignItems: 'center' }}
        >
          <div className={styles.countryItem}>
            {pathname !== '/chat/[chat_id]' ? (
              <Link title='Jobs' to={'/' + langKey + '/chat/list'}>
                <span className={classNames([styles.headerLinkText, styles.headerRightLinkText])}>
                  {Chat}
                  {totalUnread ? (
                    <span
                      className={classNames({
                        [styles.unread]: true,
                        [styles.unread_99]: Number(totalUnread) > 99
                      })}
                    >
                      {Number(totalUnread) > 99 ? '99+' : totalUnread}
                    </span>
                  ) : null}
                </span>
              </Link>
            ) : (
              <span className={classNames([styles.headerLinkText, styles.headerRightLinkText])}>
                {Chat}
                {totalUnread ? (
                  <span
                    className={classNames({
                      [styles.unread]: true,
                      [styles.unread_99]: Number(totalUnread) > 99
                    })}
                  >
                    {Number(totalUnread) > 99 ? '99+' : totalUnread}
                  </span>
                ) : null}
              </span>
            )}
          </div>
        </li>
        <li
          className={classNames([
            styles.headerLink,
            styles.headerLinkLogin,
            styles.headerLinkRightItem
          ])}
        >
          <div className={styles.countryItem}>
            <Link
              style={{
                whiteSpace: 'nowrap',
                cursor: 'pointer',
                position: 'relative',
                display: 'flex',
                lineHeight: '16.5px'
              }}
              className={classNames(showUnCompletedDot ? styles.unCompleted : '')}
              title={manageResume}
              to={!pathname.includes('/manage-profile') ? '/' + langKey + '/manage-profile' : ''}
            >
              <span className={classNames([styles.headerLinkText, styles.headerRightLinkText])}>
                {manageResume}
              </span>
            </Link>
          </div>
        </li>
        <li className={classNames([styles.headerLink, styles.headerLinkRightItem])}>
          <div className={styles.countryItem + ' ' + styles.countryItemCountry}>
            <CountryLanguage countryKey={countryKey} />
          </div>
        </li>
        <li className={classNames([styles.headerLink, styles.headerLinkRightItem])}>
          <Dropdown
            placement='bottomRight'
            trigger={['hover']}
            open={open}
            onOpenChange={(open) => {
              setOpen(open)
            }}
            forceRender
            overlayClassName={styles.authUserMenu}
            dropdownRender={() => {
              return (
                <DropDownMenu
                  ref={undefined}
                  langKey={langKey}
                  lang={lang}
                  pathname={pathname}
                  setDorpDownOpen={setOpen}
                  onUnreadChange={setUnreadNotification}
                />
              )
            }}
          >
            <div
              className={classNames(
                styles.profileProtectedWrapper,
                unreadNotification ? styles.profileHasUnread : ''
              )}
            >
              {isVip ? (
                <div className={styles.vipAvatar}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='10'
                    viewBox='0 0 24 10'
                    fill='none'
                    style={{
                      position: 'absolute',
                      bottom: isMobile ? '-1px' : '-1px',
                      right: isMobile ? '-8px' : '-5px'
                    }}
                  >
                    <path
                      d='M7.25286 3.19689C7.96334 1.82348 9.32153 0.893372 10.8678 0.893372H18.8283C21.3342 0.893372 23.3657 2.90809 23.3657 5.39337C23.3657 7.87865 21.3342 9.89337 18.8283 9.89337H0.365723C0.365723 9.89337 2.403 9.00828 3.21554 8.40617C5.01283 7.07434 6.37525 4.89336 7.25286 3.19689Z'
                      fill='#FFE1D0'
                    />
                    <g filter='url(#filter0_i_6874_166131)'>
                      <path
                        d='M9.28418 2.54843H10.4196L10.9794 5.92645L11.0661 6.63802H11.1292L11.4682 5.92645L13.2816 2.54843H14.4564L11.413 8.14718H10.2934L9.28418 2.54843Z'
                        fill='#FF9000'
                      />
                      <path
                        d='M14.9822 2.54843H16.0466L15.0453 8.14718H13.9809L14.9822 2.54843Z'
                        fill='#FF9000'
                      />
                      <path
                        d='M16.049 8.14718L17.0503 2.54843H18.9426C19.1949 2.54843 19.4262 2.58753 19.6364 2.66572C19.8467 2.74392 20.028 2.85339 20.1805 2.99414C20.3382 3.13489 20.4591 3.30431 20.5432 3.50241C20.6325 3.7005 20.6772 3.91945 20.6772 4.15924C20.6772 4.46681 20.622 4.74049 20.5116 4.98029C20.4065 5.22009 20.2567 5.42339 20.0622 5.59021C19.8677 5.75181 19.6338 5.87692 19.3605 5.96554C19.0924 6.04895 18.7981 6.09065 18.4774 6.09065H17.4761L17.1134 8.14718H16.049ZM18.6193 5.08976C18.9032 5.08976 19.1397 5.02199 19.3289 4.88646C19.5182 4.7457 19.6128 4.52937 19.6128 4.23744C19.6128 4.03413 19.5497 3.86992 19.4236 3.74481C19.3027 3.61449 19.1239 3.54932 18.8874 3.54932H17.9334L17.6574 5.08976H18.6193Z'
                        fill='#FF9000'
                      />
                    </g>
                    <defs>
                      <filter
                        id='filter0_i_6874_166131'
                        x='9.28418'
                        y='2.54843'
                        width='11.3931'
                        height='6.09875'
                        filterUnits='userSpaceOnUse'
                        color-interpolation-filters='sRGB'
                      >
                        <feFlood flood-opacity='0' result='BackgroundImageFix' />
                        <feBlend
                          mode='normal'
                          in='SourceGraphic'
                          in2='BackgroundImageFix'
                          result='shape'
                        />
                        <feColorMatrix
                          in='SourceAlpha'
                          type='matrix'
                          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                          result='hardAlpha'
                        />
                        <feOffset dy='0.5' />
                        <feGaussianBlur stdDeviation='0.25' />
                        <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                        <feColorMatrix
                          type='matrix'
                          values='0 0 0 0 1 0 0 0 0 0.250837 0 0 0 0 0.182731 0 0 0 0.25 0'
                        />
                        <feBlend
                          mode='normal'
                          in2='shape'
                          result='effect1_innerShadow_6874_166131'
                        />
                      </filter>
                    </defs>
                  </svg>
                  {Avatar}
                </div>
              ) : (
                Avatar
              )}
            </div>
          </Dropdown>
        </li>
      </React.Fragment>
    </ul>
  )
}

export default NavRight