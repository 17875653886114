"use client";
import React, { useState, useEffect, useRef, useMemo, useTransition } from "react";
import { CompanyCardH5 } from "./_h5/compontents/companyCard";

import { useRouter } from "next/navigation";

import { SearchPanelH5 } from "./_h5/compontents/Search";

import styles from "./h5.module.scss";
import { Loading } from "./_h5/compontents/CircleLoading/CircleLoading";
import { NoData } from "./_h5/compontents/NoData";
import { SearchCardH5Root, SearchInput } from "./_h5/compontents/Header";
import classNames from "classnames";
import { formatTemplateString } from "helpers/formatter";

const Icon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M4.66602 11.3333L11.3327 4.66667M11.3327 4.66667H4.66602M11.3327 4.66667V11.3333'
        stroke='black'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const FilterIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M4.5 8H12.5M2.5 4H14.5M6.5 12H10.5'
        stroke='#515151'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const getFilterNumbers = (searchQuery) => {
  let counter = 0;
  let counterKeys = ["benefits", "company_size_ids", "financing_stage_ids", "industry_ids"];

  counterKeys.map((key) => {
    let value = searchQuery?.[key] || "";
    if (value) {
      counter += value.split(",").length;
    }
  });
  return counter;
};

const MobilePage = (props) => {
  const { data = {}, params, searchParams, companies, hasSession, ...rest } = props;

  const [value, setValue] = useState(searchParams.query || "");
  // console.log("query value", data, params, rest, searchParams, value);
  //
  let queryLength = useMemo(() => {
    return getFilterNumbers(searchParams);
  }, [searchParams]);

  const [open, setOpen] = useState(false);
  const router = useRouter();

  const [loading, transition] = useTransition();

  const push = (params, options?) => {
    const urlParams = new URLSearchParams({ ...params, query: value, t: Date.now() });
    transition(() => {
      router.push(location.pathname + "?" + urlParams.toString(), options);
    });
  };
  const { new_title, search_info } = data;
  const langKey = params.lang;

  return (
    <div>
      <div>
        <SearchCardH5Root>
          {/*  */}
          <div
            className={styles.h5CompanyHeader}
            dangerouslySetInnerHTML={{
              __html: formatTemplateString(
                new_title.new_title,
                `<span class="${styles.findTxt}">${new_title.new_title_first}</span>`,
                `<span class="${styles.idealTxt}">${new_title.new_title_second}</span>`
              )
            }}
          ></div>
          <div style={{ width: "100%", marginTop: 20 }}>
            <SearchInput
              value={value}
              onChange={(value) => {
                setValue(value);
              }}
              onSearch={(query) => {
                push({ ...searchParams, query: query });
              }}
              placeholder={data?.search_info?.input_text}
            />
          </div>
          <div
            className={classNames(styles.filters, Boolean(queryLength) && styles.hasFilterNumber)}
            onClick={(e) => {
              setOpen(true);
            }}
          >
            <FilterIcon />
            {/* Filters */}
            {search_info?.filters_text}
            {Boolean(queryLength) && <span className={styles.filterNumber}>{queryLength}</span>}
          </div>
        </SearchCardH5Root>

        <SearchPanelH5
          data={data?.search_info}
          open={open}
          searchParams={searchParams}
          onClose={(value) => {
            if (value) {
              push(value);
            }
            setOpen(false);
          }}
        />
      </div>
      <div style={{ padding: "8px 12px" }}>
        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "35vh 0"
            }}
          >
            <Loading />
          </div>
        )}
        {!loading && (
          <>
            {Boolean(companies?.length) ? (
              companies.map((v) => {
                return <CompanyCardH5 lang={langKey} company={v} key={v.id} />;
              })
            ) : (
              <div style={{ padding: "calc(35vh - 90px) 0" }}>
                <NoData />
              </div>
            )}
          </>
        )}
      </div>
      {/*  */}
      {/*  */}
      {!loading && Boolean(companies?.length) && (
        <div className={styles.footer}>
          <a
            href={!hasSession ? "/get-started" : "https://app.adjust.com/1jygza27"}
            className={styles.viewMoreBtn}
            target='_self'
          >
            {/* View more companies */}
            {search_info?.more_companies_text}
            <Icon />
          </a>
        </div>
      )}
    </div>
  );
};
export default MobilePage;
