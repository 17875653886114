"use client";
import { getCookie } from "helpers/cookies";
import React from "react";
import { postNotification } from "bossjob-remote/dist/clientStorage";
import styles from "../../styles/index.pc.module.scss";

const googleTag = ["hp_job_for_you_more_click", "hp_popular_jobs_more_click"];
import { addEventTag } from "helpers/utilities";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";

const LinkMoreClient = ({ moreText, langKey }) => {
  const sessionid = getCookie("sessionid");
  const gtagName = sessionid ? googleTag[0] : googleTag[1];

  const toLink = () => {
    addEventTag(gtagName);
    if (sessionid) {
      window.open(`/${langKey}/jobs-hiring/job-search`);
      return;
    } else {
      postNotification("SHOW_LOGIN_MODAL");
      return "/get-started";
    }
  };

  return (
    <div className={styles.moreJob}>
      <Button variant='whiteBtn' btnSize='size36' onClick={toLink}>
        {moreText}
      </Button>
    </div>
  );
};

export default LinkMoreClient;
