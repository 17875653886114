"use client";
import React, { createContext, useEffect, useState } from "react";

const PageDataContext = createContext({
  transData: {
    showButton: undefined,
    isOriginal: undefined
  },
  setTransData: undefined
});

const JobTransProvider = ({ data, children }) => {
  const [transData, setTransData] = useState({
    showButton: data?.job_translation?.show_translation_button,
    isOriginal: !data?.job_translation?.show_translation_button
  });

  useEffect(() => {
    setTransData({
      showButton: data?.job_translation?.show_translation_button,
      isOriginal: !data?.job_translation?.show_translation_button
    });
  }, [data?.job_translation]);

  return (
    <PageDataContext.Provider
      value={{
        transData,
        setTransData
      }}
    >
      {children}
    </PageDataContext.Provider>
  );
};
function useJobTrans() {
  const context = React.useContext(PageDataContext);
  if (context === undefined) {
    throw new Error("useCount must be used within a JobTransProvider");
  }
  return context;
}

export { JobTransProvider, useJobTrans };
