import React from 'react'
import styles from '../../common.module.scss'

// import Logo from '../../images/logo.png'
import ChangeLanguage from './ChangeLang'
import linkToHunt from 'helpers/linkToHunt'
import Link from 'next/link'
import classNames from 'classnames'
import dynamic from 'next/dynamic'

import { LogoIconMobile } from './icons'
const MaintainNotification = dynamic(() => import('components/MaintainNotification'), {
  ssr: false
})

const DesiredHeader = (props) => {
  const { lang, langKey, className = '' } = props
  return (
    <>
      <div className={classNames(styles.desiredHeader, className)}>
        <MaintainNotification lang={props.lang?.header} langKey={langKey} />
        <div className={styles.desiredHeaderContainer}>
          <div className={styles.desiredHeaderLogo}>
            <Link href={`/${langKey}`}>
              <LogoIconMobile />
            </Link>
          </div>
          <div className={styles.desiredHeaderRight}>
            <Link href={linkToHunt('boss')} className={styles.desiredHeaderHiring}>
              {lang?.profile?.ImHiring}
            </Link>
          </div>
        </div>
      </div>

      {/* clone header for fixed content height */}
      <div style={{ pointerEvents: 'none', opacity: 0 }}>
        <div className={classNames(styles.desiredHeader, styles.desiredHeaderFixed, className)}>
          <MaintainNotification lang={props.lang?.header} langKey={langKey} />
          <div className={styles.desiredHeaderContainer}>
            <div className={styles.desiredHeaderLogo}>
              <Link href={`/${langKey}`}>
                <LogoIconMobile />
              </Link>
            </div>
            <div className={styles.desiredHeaderRight}>
              <Link href={linkToHunt('boss')} className={styles.desiredHeaderHiring}>
                {lang?.profile?.ImHiring}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DesiredHeader
