import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Select } from 'antd';
import styled from '@emotion/styled';
import { updateWebsites } from '../../service';
import CancelConfirmModal from '../common/Modal/CancelConfirmModal';
import { useLanguage } from 'app/components/providers/languageProvider';
import { useDeviceType } from '../../util';
import { useManageProfileData } from '../../DataProvider';
import { BossButton } from 'components/BossButton/BossButton'

const { TextArea } = Input;

const { Option } = Select;

function splitUrl(url) {
  // 验证URL的正则表达式
  const urlRegex = /^https?:\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

  if (!url || !urlRegex.test(url)) {
    return { prefix: '', url: '' };
  }

  const urlObj = new URL(url);
  const prefix = urlObj.protocol + "//"; // 获取协议，例如：https://
  const restUrl = urlObj.hostname; // 剩余的URL

  return { prefix, url: restUrl };
}

const EditLink = (props) => {
  const [form] = Form.useForm();
  const { onCancel, onSubmit, config, operateType, initialValues } = props
  const { prefix, url } = splitUrl(initialValues?.url || '')

  const [loading, setLoading] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [urlPrefix, setUrlPrefix] = useState(prefix || 'https://')

  const { langDictionary, parentRef } = useManageProfileData()
  const langDictData = langDictionary || useLanguage()
  const {
    manageResume,
    btns_lang,
    modal_lang,
    form_item_lang
  } = langDictData

  const { isMobile } = useDeviceType()

  const editRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (parentRef.current && parentRef.current.contains(event.target) &&
        !editRef.current.contains(event.target)) {
        handleShowModal('cancel-confirm');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectPrefix = (value) => {
    setUrlPrefix(value)
  }

  const selectBefore = (
    <Select defaultValue="https://" onChange={handleSelectPrefix}>
      <Option value="https://">https://</Option>
      <Option value="http://">http://</Option>
    </Select>
  )


  const handleSubmit = async () => {
    try {
      let sendData: any = {}
      await form.validateFields()
      const formData = form.getFieldsValue()
      sendData = {
        ...formData,
        id: initialValues?.id,
      }
      setLoading(true)
      const res = await updateWebsites(sendData)
      setLoading(false)
      if (res.data.code == 0) {
        onSubmit && onSubmit()
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleShowModal = (modalType) => {
    setModalType(modalType)
  };

  const handleCancel = () => {
    setModalType(null)
  };

  const handleOk = () => {
    onCancel && onCancel()
  };

  return (
    <EditLinkWrap ref={editRef}>
      <Form
        className='links-form'
        layout={'vertical'}
        size='middle'
        form={form}
        initialValues={{
          ...initialValues
        }}
      >
        {isMobile && (
          <div className='title'>
            {operateType === 'add' ? manageResume.link.add : manageResume.link.edit}
          </div>
        )}
        <Form.Item
          name='url'
          label={manageResume.link.url || 'URL'}
          rules={[{ required: true, message: '' }]}
        >
          {/* <Input addonBefore={selectBefore} /> */}
          <Input placeholder='http://' />
        </Form.Item>
        <Form.Item name='title' label={manageResume.link.title || 'Display name of the link'}>
          <Input placeholder={form_item_lang.placeholder.input || 'Please enter'} />
        </Form.Item>
        <Form.Item name='description' label={manageResume.link.description || 'Description'}>
          <TextArea
            placeholder={form_item_lang.placeholder.input || 'Please enter'}
            showCount
            maxLength={100}
          />
        </Form.Item>
        <Form.Item className='form-buttons'>
          <BossButton btnSize='size32' btnType='whiteOutlineBtn' onClick={() => handleOk()}>
            {btns_lang.cancel || 'Cancel'}
          </BossButton>
          <BossButton
            btnSize='size32'
            style={{ marginLeft: 12 }}
            onClick={handleSubmit}
            loading={loading}
          >
            {btns_lang.confirm || 'Confirm'}
          </BossButton>
        </Form.Item>
      </Form>
      <CancelConfirmModal
        open={modalType === 'cancel-confirm'}
        onCancel={handleCancel}
        onOk={handleOk}
        cancelText={btns_lang.cancel || 'Cancel'}
        okText={btns_lang.confirm || 'Comfirm'}
        title={
          modal_lang.cancel_modal_title ||
          'There is unsaved content. Are you sure you want to exit without saving?'
        }
        content={
          modal_lang.cancel_modal_content ||
          'After exiting editing, the updated content will not be saved.'
        }
        maskClosable={false}
        closable={false}
      />
    </EditLinkWrap>
  )
};
export default EditLink;

const EditLinkWrap = styled.div`
  .links-form {
    display: flex;
    padding: 20px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: rgba(245, 247, 251, 0.6);
    margin: 4px;
    border-radius: 12px;
    .ant-form-item {
      width: 100%;
      margin-bottom: 0;
      .proficiency-help {
        color: #888888;
        font-size: 12px;
        margin-top: 8px;
      }
    }
    .title {
      color: #121212;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .form-buttons {
      margin-top: 16px;
      text-align: right;
    }
  }
`