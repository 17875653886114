"use client";
import React from "react";
import classNames from "classnames";
import useViewMore from "../useViewMore";
import styles from "./RemoteDetail.pc.module.scss";

export default function RemoteDetail({ data }) {
  const { showMore, expand, setExpand, viewMoreRef } = useViewMore();
  if (data) {
    const not_open_country_list = data.not_open_country_list || [];
    const open_country_list = data.open_country_list || [];
    const language_require_list = data.language_require_list || [];

    return (
      <div className={styles.remoteDetail}>
        <div className={styles.remoteDetailBox}>
          <h4>{data.title}</h4>
          <div className={styles.viewMoreContent} ref={viewMoreRef}>
            {not_open_country_list.length ? (
              <div>
                <p>
                  <span className={styles.label}>{not_open_country_list[0]}</span>&nbsp;&nbsp;
                  {not_open_country_list[1]}
                </p>
              </div>
            ) : open_country_list.length ? (
              <p>
                <span className={styles.label}>{data.open_country_text}</span>：
                {open_country_list.join(" | ")}
              </p>
            ) : null}
          </div>
          {showMore ? (
            <span
              className={classNames({
                [styles.viewMore]: true,
                [styles.close]: expand
              })}
              onClick={() => setExpand((state) => !state)}
            >
              {!expand ? data?.view_all_text : data?.view_less_text}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='14'
                height='15'
                viewBox='0 0 14 15'
                fill='none'
              >
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M6.87246 8.60247L3.09263 4.82264C2.86482 4.59483 2.49547 4.59483 2.26767 4.82264L2.14392 4.94638C1.91612 5.17418 1.91612 5.54353 2.14392 5.77134L6.87246 10.4999L11.601 5.77134C11.8288 5.54353 11.8288 5.17419 11.601 4.94638L11.4773 4.82264C11.2495 4.59483 10.8801 4.59483 10.6523 4.82264L6.87246 8.60247Z'
                  fill='black'
                />
              </svg>
            </span>
          ) : null}
          {language_require_list.length ? (
            <p>
              <span className={styles.label}>{data.language_require_text}</span>：
              {language_require_list.join(" | ")}
            </p>
          ) : null}
        </div>
        {data?.is_prompt ? (
          <div className={styles.openCountryWrap}>
            <div className={styles.openCountryTip}>
              <span className={styles.tipIcon}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                >
                  <path d='M10.8345 7.50002V11.6667H9.16781V7.50002H10.8345Z' fill='#FF9000' />
                  <path d='M9.16699 12.9167H10.8337V14.5834H9.16699V12.9167Z' fill='#FF9000' />
                  <path
                    d='M8.55322 2.5324C9.19304 1.4127 10.8075 1.4127 11.4474 2.5324L18.5754 15.0065C19.2103 16.1175 18.408 17.5 17.1283 17.5H2.87225C1.59255 17.5 0.790271 16.1175 1.42518 15.0065L8.55322 2.5324ZM10.0003 3.35929L2.87225 15.8334H17.1283L10.0003 3.35929Z'
                    fill='#FF9000'
                  />
                </svg>
              </span>
              {data.remote_country_warn}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
  return null;
}
