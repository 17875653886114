'use client'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Avatar, Input, Tooltip, Radio, Space, DatePicker, Select } from 'antd'
import { usePathname } from 'next/navigation'
import { getCountryId, getCountryKey } from 'helpers/country'
import { addEventTag } from 'helpers/utilities'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { CustomRadio } from '../CustomRadio'
import { LinkContext } from 'app/components/providers/linkProvider'
import { uploadUserAvatarService } from 'store/services/users/uploadUserAvatar'
import { updateUserCompleteProfileService } from 'store/services/users/updateUserCompleteProfile'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import { avatar3 } from '../../images'
import SimpleSteps from '../Step/Simple'
import { ArrowIcon, AvatarIcon, HelperIcon, InfoIcon, UploadIcon } from './icons'
import AvatarModal from '../AvatarModal'
import SafeInfo from '../Common/SafeInfo'
import LoadingButton from '../Common/LoadingButton'
import { getDefaultAvatarNumber, getUrlSearchString, isChatProcess } from '../utils'

import styled from '@emotion/styled'
import { useMainResumeContext } from '../Main/context'
import { formatNationality } from './utils'
import { BackIcon } from '../Common/icons'

export const BaseProfileH5 = (props: { lang: any; config: any }) => {
  const { lang = {} } = props
  const { userInfo: userDetail, getUserInfo } = useMainResumeContext()
  const { push } = useContext(LinkContext)
  const pathname = usePathname()
  const [loading, setLoading] = useState(false)
  const profile = lang.profile || {}
  const countryId = getCountryId()
  // console.log('props::', props)

  const experiencedList = [
    {
      label: lang?.profile?.experienced,
      value: 1
    },
    {
      label: lang?.profile?.freshGraduate,
      value: 2
    }
  ]

  const sgIdentityList = useMemo(() => {
    return props.config?.user_identity_lists || []
  }, [props.config])

  const noticePeriodList = useMemo(() => {
    return props.config?.notice_period_lists || []
  }, [props.config])

  const nationalityList = useMemo(() => {
    return formatNationality(props.config?.nationality_lists) || []
  }, [props.config])

  const country_national = useMemo(() => {
    return props.config?.country_national || []
  }, [props.config])

  const defaultNationalityId = useMemo(() => {
    if (countryId == 241) return undefined
    return country_national.find((item) => item.country_id === countryId)?.national_id
  }, [countryId, country_national])

  // console.log('experiencedList::', experiencedList)
  // console.log('sgIdentityList::', sgIdentityList)
  // console.log('noticePeriodList::', noticePeriodList)
  // console.log('nationalityList', nationalityList)

  const [openAvatarModal, setOpenAvatarModal] = useState(false)

  const countryKey = getCountryKey()
  const isSG = countryKey === 'sg'
  const chatProcess = isChatProcess()

  const [hasWorkExp, setHasWorkExp] = useState(false)
  const [needNextStep, setNeedNextStep] = useState(true)

  const [formData, setFormData] = useState({
    avatar: null,
    avatar_file: null,
    first_name: null,
    last_name: null,
    work_exp: null,
    availability: null,
    sg_identity: null,
    work_since: null,
    select_avatar_index: null,
    nationality_id: defaultNationalityId
  })

  const disabledSubmit = useMemo(() => {
    if (formData.work_exp == 1 && !formData.work_since) {
      return true
    }
    if (isSG && !formData.sg_identity) {
      return true
    }

    if (formData.first_name?.length > 50 || formData.last_name?.length > 50) {
      return true
    }

    return !formData.first_name || !formData.last_name || !formData.availability || !formData.avatar
  }, [formData])

  // console.log('disabledSubmit::', disabledSubmit)

  useEffect(() => {
    // console.log('userDetail::', userDetail)
    if (userDetail?.id) {
      const {
        first_name,
        last_name,
        avatar,
        notice_period_id,
        working_since,
        is_fresh_graduate,
        user_identity,
        is_resume,
        is_educations,
        is_work_experiences,
        nationality_id
      } = userDetail

      const numberPart = getDefaultAvatarNumber(avatar)

      setFormData({
        ...formData,
        select_avatar_index: numberPart || null,
        avatar: avatar || avatar3.src,
        avatar_file: null,
        first_name: first_name || null,
        last_name: last_name || null,
        work_exp: is_fresh_graduate ? 2 : 1,
        availability: notice_period_id || null,
        sg_identity: user_identity ? user_identity.id : null,
        work_since: working_since ? dayjs(working_since) : null,
        nationality_id: nationality_id || defaultNationalityId
      })
      // 是否进入一下步骤
      setNeedNextStep(!(is_resume || (is_educations && is_work_experiences)))
      setHasWorkExp(is_fresh_graduate ? false : true)
    }
  }, [JSON.stringify(userDetail)])

  const handleBack = () => {
    if (chatProcess) {
      const searchWords = getUrlSearchString(1)
      push(`${pathname}${searchWords}`)
    } else {
      window.history.back()
    }
  }

  const handleSubmit = async () => {
    try {
      const payload = {
        first_name: formData.first_name,
        last_name: formData.last_name,
        notice_period_id: formData.availability,
        is_fresh_graduate: formData.work_exp == 1 ? 0 : 1 //0 有经验的，1应届生
      }

      if (countryKey === 'sg') {
        payload['user_identity_id'] = formData.sg_identity
      }

      if (formData.work_exp == 1) {
        payload['working_since'] = formData.work_since
          ? formData.work_since.format('YYYY-MM-DD')
          : null
      }

      if (formData.nationality_id) {
        payload['nationality_id'] = formData.nationality_id
      }

      handleUpdateProfile(payload)
      // push(`${pathname}?step=3`)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleUploadAvatar = () => {
    setOpenAvatarModal(true)
  }

  const handleUpdateProfile = async (payload) => {
    console.log('payload', { payload, formData })

    setLoading(true)
    const searchWords = getUrlSearchString(3)

    addEventTag(chatProcess ? 'new_user_chat_basic_next' : 'new_user_complete_basic_next')

    if (formData.avatar_file) {
      let newFile = formData.avatar_file
      if (formData.select_avatar_index) {
        const filename = `icon-avatar-${formData.select_avatar_index}.png`
        newFile = new File([formData.avatar_file], filename, { type: formData.avatar_file.type })
      }
      // console.log('newFile', newFile)
      await uploadUserAvatarService(newFile)
    }

    updateUserCompleteProfileService(payload)
      .then((res) => {
        if (res.data) {
          if (getUserInfo) {
            getUserInfo().then(() => {
              let url = `${pathname}${searchWords}`
              push(url)
            })
          }
        }
      })
      .catch((error) => {
        console.log('error', error)
      })
      .finally(() => setLoading(false))
  }

  const handleAvatarImageFile = (file: any, index: number) => {
    console.log('get file', file, index)
    setFormData({
      ...formData,
      avatar: URL.createObjectURL(file),
      avatar_file: file,
      select_avatar_index: index
    })
    setOpenAvatarModal(false)
  }

  const validateNameErrorTip = (first, last) => {
    if (first?.length > 50 || last?.length > 50) {
      return false
    }
    return true
  }

  const disabledDate = (current) => {
    return current && current > dayjs()
  }

  return (
    <WrapContainer>
      <div className={'baseProfileH5 yoloJobseekerCompletedSelect'}>
        <div className={'baseProfileH5_main'}>
          {/* <div className={'baseProfileH5_base'}>
            <AvatarIcon className={'baseProfileH5_icon'} />
          </div> */}
          <h1 className={'baseProfileH5_title'}>
            {profile.steps?.text1}{' '}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='9'
              height='9'
              viewBox='0 0 9 9'
              fill='none'
            >
              <circle cx='4.5' cy='4.5' r='4.5' fill='#3CEAF6' />
            </svg>
          </h1>
          <p className={'baseProfileH5_subtitle'}>{profile.baseProfile?.subtitle}</p>

          <div className={'baseProfileH5_avatar'}>
            <div className={'baseProfileH5_avatarImg'} onClick={handleUploadAvatar}>
              <Avatar src={formData.avatar} alt='avatar' size={60} />
              <UploadIcon className={'baseProfileH5_uploadIcon'} />
            </div>
            <p className={'baseProfileH5_avatarText'}>
              <InfoIcon style={{ flexShrink: 0 }} /> {profile.baseProfile?.avatarTip}
            </p>
          </div>

          <div className={'baseProfileH5_item'}>
            <p className={'baseProfileH5_label'}>{profile.baseProfile?.fullName}</p>
            <p className={'baseProfileH5_info'}>
              {profile.baseProfile?.fullNameTip}
              <Tooltip
                overlayStyle={{ maxWidth: '80%' }}
                placement='top'
                title={profile.baseProfile?.fullNameMoreTip}
              >
                <HelperIcon className={'baseProfileH5_helperIcon'} />
              </Tooltip>
            </p>
            <div className={'baseProfileH5_value'}>
              <Input
                value={formData.first_name}
                onChange={(e) =>
                  setFormData({ ...formData, first_name: (e.target.value || '').trimStart() })
                }
                className={'baseProfileH5_input'}
                placeholder={profile.baseProfile?.firstName}
              />
              <Input
                value={formData.last_name}
                onChange={(e) =>
                  setFormData({ ...formData, last_name: (e.target.value || '').trimStart() })
                }
                className={'baseProfileH5_input'}
                placeholder={profile.baseProfile?.lastName}
              />
            </div>
            {validateNameErrorTip(formData.first_name, formData.last_name) ? null : (
              <p className={'baseProfileH5_error'}>{profile.baseProfile?.checkNameTip}</p>
            )}
          </div>

          <div className={'baseProfileH5_item'}>
            <p className={'baseProfileH5_label'}>{profile.baseProfile?.workExperience}</p>
            <div className={'baseProfileH5_value'}>
              <div className='baseProfileH5_value_wrapper'>
                {experiencedList.map((item) => (
                  <CustomRadio
                    checked={item.value == formData.work_exp}
                    label={item.label}
                    value={item.value}
                    key={item.value}
                    onChange={(e) => {
                      console.log('e', e.target.value)
                      setHasWorkExp(item.value == 1)
                      setFormData({ ...formData, work_exp: e.target.value })
                    }}
                  />
                ))}
              </div>

              {/* <Radio.Group
                className={'baseProfileH5_radio'}
                value={formData.work_exp}
                onChange={(e) => {
                  setHasWorkExp(e.target.value == 1)
                  setFormData({ ...formData, work_exp: e.target.value })
                }}
              >
                <Space direction='vertical'>
                  {experiencedList.map((item) => (
                    <Radio value={item.value} key={item.value}>
                      {item.label}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group> */}
            </div>
          </div>

          {hasWorkExp && (
            <div className={'baseProfileH5_item'}>
              <p className={'baseProfileH5_label'}>{profile.baseProfile?.startWorkSince}</p>
              <div className={'baseProfileH5_value'}>
                <DatePicker
                  picker='month'
                  className={'baseProfileH5_date'}
                  value={formData.work_since}
                  placeholder={profile.baseProfile?.startWorkSincePlaceholder}
                  disabledDate={disabledDate}
                  onChange={(e) => {
                    setFormData({ ...formData, work_since: e })
                  }}
                />
              </div>
            </div>
          )}

          <div className={'baseProfileH5_item'}>
            <p className={'baseProfileH5_label'}>{profile.availability}</p>
            <p className={'baseProfileH5_info'}>{profile.availabilityTips}</p>
            <div className={'baseProfileH5_value'}>
              <div className='baseProfileH5_value_wrapper'>
                {noticePeriodList.map((item) => (
                  <CustomRadio
                    checked={item.id == formData.availability}
                    label={item.value}
                    value={item.id}
                    key={item.id}
                    onChange={(e) => setFormData({ ...formData, availability: e.target.value })}
                  />
                ))}
              </div>
              {/* <Radio.Group
                className={'baseProfileH5_radio'}
                value={formData.availability}
                onChange={(e) => setFormData({ ...formData, availability: e.target.value })}
              >
                <Space direction='vertical'>
                  {noticePeriodList.map((item) => (
                    <Radio value={item.id} key={item.id}>
                      {item.value}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group> */}
            </div>
          </div>

          {isSG && (
            <div className={'baseProfileH5_item'}>
              <p className={'baseProfileH5_label'}>{profile.singaporeIdentity}</p>
              <div className={'baseProfileH5_value'}>
                <div className='baseProfileH5_value_wrapper'>
                  {sgIdentityList.map((item) => (
                    <CustomRadio
                      checked={item.id == formData.sg_identity}
                      label={item.value}
                      value={item.id}
                      key={item.id}
                      onChange={(e) => {
                        setFormData({ ...formData, sg_identity: e.target.value })
                      }}
                    />
                  ))}
                </div>
                {/* <Radio.Group
                  className={'baseProfileH5_radio'}
                  value={formData.sg_identity}
                  onChange={(e) => setFormData({ ...formData, sg_identity: e.target.value })}
                >
                  <Space direction='vertical'>
                    {sgIdentityList.map((item) => (
                      <Radio value={item.id} key={item.id}>
                        {item.value}
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group> */}
              </div>
            </div>
          )}

          <div className={'baseProfileH5_item'}>
            <p className={'baseProfileH5_label'}>{profile.nationality}</p>
            <p className={'baseProfileH5_info'}>{profile.nationalityTips}</p>
            <div className={'baseProfileH5_value'}>
              <Select
                allowClear
                style={{ height: '42px', width: '100%' }}
                options={nationalityList}
                popupClassName='selectGlobalPopupClassName'
                suffixIcon={<ArrowIcon />}
                placeholder={profile.nationalityPlaceholder}
                value={formData.nationality_id}
                onChange={(value) => {
                  setFormData({ ...formData, nationality_id: value })
                }}
              />
            </div>
          </div>
          <div className={'baseProfileH5_safeInfo'}>
            <SafeInfo lang={lang} />
          </div>
        </div>
        <div className={'baseProfileH5_footerFixed'}></div>
      </div>
      <div
        className={'baseProfileH5_footer' + (openAvatarModal ? ' baseProfileH5_footerFixed' : '')}
      >
        {chatProcess ? (
          <>
            <div className={'baseProfileH5_back'} onClick={handleBack}>
              <BackIcon />
              {profile.back}
            </div>
          </>
        ) : null}

        <Button
          className={chatProcess ? '' : 'baseProfileH5_continue'}
          loading={loading}
          disabled={disabledSubmit || loading}
          onClick={handleSubmit}
          variant='primaryBtn'
          btnSize='size44'
        >
          {needNextStep ? profile.continue : profile.confirm}
        </Button>
      </div>

      {openAvatarModal && (
        <AvatarModal
          open={openAvatarModal}
          lang={lang}
          onOk={handleAvatarImageFile}
          selectAvatarIndex={formData.select_avatar_index}
          onCancel={() => setOpenAvatarModal(false)}
        />
      )}
    </WrapContainer>
  )
}

export default BaseProfileH5

const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;

  .baseProfileH5 {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    background-color: #fff;
    overflow: auto;

    &_main {
      display: flex;
      width: 100%;
      min-height: 600px;
      padding: 32px 20px;
      flex-direction: column;
      align-items: start;
      box-sizing: border-box;
    }

    &_base {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
    }

    &_icon {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
    }

    &_title {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 32px;
      margin: 0 auto;
    }

    &_date {
      height: 42px;
      border-radius: 4px;
      width: 100%;
    }

    &_subtitle {
      color: #3b3c3c;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      margin-top: 16px;
    }

    &_avatar {
      margin-top: 24px;
    }

    &_avatarImg {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
      position: relative;
      border: 1px solid #f4f5f5;
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-avatar {
        border: 0;
      }
    }

    &_uploadIcon {
      position: absolute;
      flex-shrink: 0;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
      z-index: 1;
      bottom: 0;
      right: -3px;
    }

    &_avatarText {
      margin-top: 16px;
      color: #3b3c3c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      svg {
        position: relative;
        top: 3px;
      }
    }

    &_item {
      margin-top: 32px;
      width: 100%;
    }

    &_label {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      padding-bottom: 6px;
    }

    &_info {
      color: #3b3c3c;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }

    &_helperIcon {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      margin-left: 4px;
      vertical-align: middle;
    }

    &_value {
      margin-top: 12px;
      display: flex;
      gap: 8px;

      &_wrapper {
        gap: 24px;
        flex-direction: column;
        display: flex;
      }
    }

    &_radio {
      .ant-space {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }

    &_input {
      width: 50%;
      height: 42px;
      outline: none;
      padding: 4px 11px;
      border-width: 1px;
      color: rgba(0, 0, 0, 0.88);
      border-style: solid;
      font-size: 16px;
    }

    &_input::placeholder {
      color: #646566;
    }

    &_safeInfo {
      margin-top: 40px;
    }

    &_footer {
      display: flex;
      gap: 13px;
      width: 100%;
      padding: 10px 20px;
      background: #fff;

      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 12;
      justify-content: space-between;
    }

    &_footerFixed {
      height: 80px;
      width: 100%;
      opacity: 0;
      padding: 10px 20px;
      pointer-events: none;
    }

    &_back {
      display: flex;
      padding: 10px 0;
      justify-content: center;
      align-items: center;
    }

    &_continue {
      width: 100%;
    }

    &_error {
      color: red;
      margin-top: 8px;
    }
  }
`
