import React from 'react'
import styled from '@emotion/styled'
import { Progress } from 'antd'

import UploadResumeWrap from './Wrap'
import { useUploadResumeContext } from './context'

const UploadMain = (props: { lang: any }) => {
  const { lang = {} } = props
  const profile = lang.profile || {}
  const { progress } = useUploadResumeContext()
  // console.log('progress', progress)

  return (
    <UploadResumeWrap {...props}>
      <ContentWrap>
        <div className='modal-content'>
          <div className='progress-file-info'>
            <FileIcon className='progress-file-icon' />
            <Progress
              className='progress-file-progress'
              size={'small'}
              type='line'
              strokeColor={'#0066FF'}
              percent={progress}
              showInfo={false}
            />
            <p className='progress-file-message'>{profile?.uploadResumeModal?.uploadingTip}</p>
          </div>
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  )
}
const media = {
  sm: `@media (max-width: 576px)`,
  md: `@media (min-width: 800px)`,
  msd: `@media (max-width: 799px)`
}
const ContentWrap = styled.div`
  .modal-content {
    text-align: center;
  }

  .progress-file-info {
    display: flex;
    padding: 37px 24px 52px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    ${media.msd} {
      padding: 48px 0 0 0;
    }
  }

  .progress-file-progress {
    width: 200px;
    max-width: 80%;
    margin: 30px 0 24px;
  }

  .progress-file-message {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
`

export default UploadMain

const FileIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='39' height='52' viewBox='0 0 39 52' fill='none'>
    <g clipPath='url(#clip0_845_112649)'>
      <path
        d='M11.4987 0.410156H35.0091C37.2183 0.410156 39.0091 2.20102 39.0091 4.41016V47.6904C39.0091 49.8996 37.2183 51.6904 35.0091 51.6904H4.8164C2.60726 51.6904 0.816406 49.8996 0.816406 47.6904V11L11.4987 0.410156Z'
        fill='white'
      />
      <path
        d='M37.7301 1.69216C36.9168 0.877887 35.7891 0.410156 34.6362 0.410156H11.7335C11.441 0.410156 11.1616 0.526338 10.9542 0.73266L1.13891 10.548C0.932588 10.7543 0.816406 11.0348 0.816406 11.3272V47.3164C0.816406 49.7282 2.77847 51.6903 5.19024 51.6903H34.6362C35.7891 51.6903 36.9168 51.2225 37.7301 50.4093C38.5554 49.584 39.0101 48.4863 39.0101 47.3174V4.78399C39.0101 3.61516 38.5554 2.51645 37.7311 1.69216H37.7301ZM12.1902 2.6136H12.2683V9.69166C12.2683 10.8885 11.2948 11.862 10.0979 11.862H3.01985V11.7839L12.1902 2.6136ZM36.8066 47.3164C36.8066 47.8964 36.5813 48.4412 36.1716 48.8508C35.768 49.2545 35.2091 49.4868 34.6362 49.4868H5.19024C3.99337 49.4868 3.01985 48.5133 3.01985 47.3164V14.0645H10.0979C12.5097 14.0645 14.4717 12.1024 14.4717 9.69066V2.6136H34.6362C35.2081 2.6136 35.768 2.84496 36.1716 3.24959C36.5803 3.65823 36.8056 4.20308 36.8056 4.78399V47.3164H36.8066Z'
        fill='black'
      />
      <path
        d='M27.2367 26.9066C26.806 26.4759 26.1089 26.4759 25.6782 26.9066L21.0159 31.5689V19.506C21.0159 18.8981 20.5232 18.4043 19.9142 18.4043C19.3053 18.4043 18.8125 18.8981 18.8125 19.506V31.5689L14.1502 26.9056C13.7195 26.4749 13.0225 26.4749 12.5918 26.9056C12.1621 27.3363 12.1621 28.0333 12.5918 28.464L19.135 35.0072C19.5457 35.4329 20.2828 35.4329 20.6934 35.0072L27.2367 28.464C27.6673 28.0333 27.6673 27.3363 27.2367 26.9056V26.9066Z'
        fill='#0066FF'
      />
      <path
        d='M26.4587 41.8748H13.3713C12.7633 41.8748 12.2695 41.3821 12.2695 40.7731C12.2695 40.1642 12.7633 39.6714 13.3713 39.6714H26.4587C27.0677 39.6714 27.5604 40.1642 27.5604 40.7731C27.5604 41.3821 27.0667 41.8748 26.4587 41.8748Z'
        fill='black'
      />
    </g>
    <defs>
      <clipPath id='clip0_845_112649'>
        <rect
          width='38.1927'
          height='51.2801'
          fill='white'
          transform='translate(0.816406 0.410156)'
        />
      </clipPath>
    </defs>
  </svg>
)
