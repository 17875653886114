'use client'
import React, { useContext, useEffect, useMemo } from 'react'
import { useSearchParams } from 'next/navigation'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { getCookie } from 'helpers/cookies'
import { LinkContext } from 'app/components/providers/linkProvider'
import { getLang } from 'helpers/country'
import { useMainResumeContext, withMainResumeProvider } from './context'

import { CommonHeader } from '../Common/Header'
import BaseProfile from '../BaseProfile'
import AttachedResume from '../AttachedResume'
import DesiredPc from '../DesiredForm/index.pc'
import DesiredMobile from '../DesiredForm/index.mobile'

import styles from '../../common.module.scss'

import Loading from 'app/components/loading'
import { isMobileDevice } from 'helpers/utilities'
import DesiredHeaderMobile from '../Common/DesiredHeader.mobile'

export const MainLayout = (props: { lang: any; config: any }) => {
  const sessionid = getCookie('sessionid')
  const { push } = useContext(LinkContext)
  const searchParams = useSearchParams()
  const step = searchParams.get('step')
  const { getUserInfo } = useMainResumeContext()
  const [loading, setLoading] = React.useState(true)
  const isMobile = isMobileDevice()

  const langKey = getLang()
  if (!sessionid) {
    push(`/${langKey}/get-started`)
  }

  const showDesired = useMemo(() => {
    if (!step) return true
    return ![2, 3].includes(Number(step))
  }, [step])

  useEffect(() => {
    if (sessionid) {
      setLoading(true)
      getUserInfo().finally(() => setLoading(false))
    }
  }, [])

  return (
    <CacheProvider value={createCache({ key: 'complete-profile', prepend: true })}>
      <div className={styles.main} translate='no'>
        {showDesired ? (
          isMobile ? (
            <DesiredMobile {...props} initLoading={loading} />
          ) : (
            <DesiredPc {...props} initLoading={loading} />
          )
        ) : (
          <>
            <main className={styles.container}>
              {isMobile ? (
                <DesiredHeaderMobile
                  className={styles.commonHeader}
                  lang={props.lang}
                  langKey={langKey}
                />
              ) : (
                <CommonHeader lang={props.lang} langKey={langKey} />
              )}
              {loading ? (
                <div style={{ minHeight: 'calc(100vh - 180px)', position: 'relative' }}>
                  <Loading />
                </div>
              ) : (
                <>
                  {Number(step) == 2 && <BaseProfile {...props} />}
                  {Number(step) == 3 && <AttachedResume {...props} />}
                </>
              )}
            </main>
          </>
        )}
      </div>
    </CacheProvider>
  )
}

export default withMainResumeProvider(MainLayout)
