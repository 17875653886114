'use client'

import React from 'react'

import { getProfileCompleteStep } from './utils'

import styles from './index.module.scss'
import { useRouter } from 'next/navigation'
import { getLang } from 'helpers/country'

const Button = (props: { userDetail: any; dictionary: any }) => {
  const { userDetail = {}, dictionary = {} } = props

  const router = useRouter()
  const lang = getLang()
  const { profile = {} } = dictionary

  const handleClick = () => {
    const step = getProfileCompleteStep(userDetail)
    // console.log('click', { userDetail, step, lang })
    if (!userDetail?.is_job_preferences) {
      const { pathname, search } = window.location
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}&from=chat`)
      sessionStorage.setItem('redirectPage', `${pathname}${search}`)
    } else {
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}`)
    }
  }

  return (
    <button onClick={handleClick} className={styles.button}>
      {profile.completedCard?.button}{' '}
    </button>
  )
}

const PTag = (props: { userDetail: any; dictionary: any }) => {
  const { userDetail = {}, dictionary = {} } = props

  const router = useRouter()
  const lang = getLang()
  const { profile = {} } = dictionary

  const handleClick = () => {
    const step = getProfileCompleteStep(userDetail)
    // console.log('click', { userDetail, step, lang })
    if (!userDetail?.is_job_preferences) {
      const { pathname, search } = window.location
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}&from=chat`)
      sessionStorage.setItem('redirectPage', `${pathname}${search}`)
    } else {
      router.push(`/${lang}/jobseeker-complete-profile?step=${step}`)
    }
  }

  return (
    <p onClick={handleClick} className={styles.pTag}>
      <span>{profile.completedCard?.button}</span> <SmallArrowIcon />
    </p>
  )
}

const SmallArrowIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M5.19526 2.86201C5.45561 2.60166 5.87772 2.60166 6.13807 2.86201L10.8047 7.52868C11.0651 7.78903 11.0651 8.21114 10.8047 8.47149L6.13807 13.1382C5.87772 13.3985 5.45561 13.3985 5.19526 13.1382C4.93491 12.8778 4.93491 12.4557 5.19526 12.1953L9.39052 8.00008L5.19526 3.80482C4.93491 3.54447 4.93491 3.12236 5.19526 2.86201Z'
        fill='#1C70EE'
      />
    </svg>
  )
}

export { PTag }

export default Button
