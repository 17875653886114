import React from 'react'
import msgNoData from './msgNoData.png'

const NoMsgIcon = () => {
  return (
    <div
      style={{
        width: 90,
        height: 145
      }}
    >
      <img
        style={{
          maxWidth: '100%',
          height: 'auto'
        }}
        src={msgNoData.src}
        alt=''
      />
    </div>
  )
}

export const NoMsg = ({ text = 'No notifications' }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#F8F9FA'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 12,
          alignItems: 'center',
          background: '#F8F9FA'
        }}
      >
        <NoMsgIcon />
        <span style={{ color: '#ACAEB1', fontSize: 14, fontWeight: 500 }}>{text}</span>
      </div>
    </div>
  )
}
