"use client";
import React, { useState } from "react";
import Image from "next/image";
import styles from "../../styles/index.pc.module.scss";

import Loading from "app/components/loading";
import { fetchJobs } from "../../services";
import { Tabs, Empty } from "antd";
import CardList from "./CardList/index.pc";
import { getCookie } from "helpers/cookies";
import classNames from "classnames";
import { AvatarVip } from "../../images/svg";
import LinkMoreClient from "./LinkMore.pc.client";
import { addEventTag } from "helpers/utilities";

const googleTagTabs = [
  "hp_job_for_you_classification_click",
  "hp_popular_jobs_classification_click_"
];

interface JobsHiringPcProps {
  langKey: string;
  pageData: any;
}

const JobsHiringPc = (props: JobsHiringPcProps) => {
  const langKey = props?.langKey;
  const pageData = props?.pageData || {};
  let jobsData = pageData.jobs || [];
  const sessionid = getCookie("sessionid");

  const { title } = jobsData || {};

  const { tabs, more_text } = jobsData || {};
  const [loading, setLoading] = useState(false);
  // 兼容处理登陆情况
  const [type, setType] = useState(jobsData?.tabs[0].main_function_id || 0);
  const [jobs, setJobs] = useState(
    jobsData?.tabs?.find((e) => e?.main_function_id == type)?.jobs || jobsData?.tabs?.[0]?.jobs
  );

  const items = tabs.map((e, index) => {
    return {
      key: e.main_function_id || index,
      label: e.name,
      seo_value: e.seo_value
    };
  });

  const handleGoogleTag = (key) => {
    const tabName = items?.find((e) => e.key == key);
    const gtagEventName = sessionid ? googleTagTabs[0] : googleTagTabs[1];

    if (!sessionid) {
      addEventTag(gtagEventName + tabName?.seo_value);
    } else {
      addEventTag(gtagEventName);
    }
  };

  const onChange = (key: string) => {
    // console.log("onChange", key);
    handleGoogleTag(key);

    setType(Number(key));

    if (sessionid) {
      // setJobs(jobsData?.tabs?.[key]?.jobs);
      setLoading(true);
      const tabName = items?.find((e) => e.key == key);
      const tab_id = tabName?.seo_value == "recommended" ? 2 : 1;
      const params = {
        job_preferences_id: jobsData?.preference_id,
        is_remote: jobsData?.is_remote_preference,
        recommend_tab_id: tab_id
      };
      // console.log("params", params);
      fetchJobs(params)
        .then((res) => {
          setJobs(res?.data?.data);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      fetchJobs({ main_job_function_id: key })
        .then((res) => {
          setJobs(res?.data?.data);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleJobPref = () => {
    if (sessionid) {
      addEventTag("hp_job_for_you_pre_click");
      window.open(`/${langKey}/manage-profile?tab=job-preferences`, "_blank");
    }
  };

  // const login = (e) => {
  //   if (!sessionid && e.target.id === "home-login-btn-bzp5zrma") {
  //     sessionStorage.setItem("redirectPage", window?.location?.pathname);
  //     postNotification("SHOW_LOGIN_MODAL");
  //     return;
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("click", login, true);
  //   return window.removeEventListener("click", login);
  // }, []);

  const { highlight_text, text } = jobsData || {};

  const newText = text.replace(highlight_text, `<span>${highlight_text}</span>`);

  const right_text = jobsData?.right_text || "";
  const right_highlight_text = jobsData?.right_highlight_text || "";

  const rightNewText = right_text.replace(right_highlight_text, ``);

  return (
    <section className={styles.jobsHiring}>
      <div className={styles.jobsHiringBox}>
        <h2>{title}</h2>
        <p className={styles.improve} dangerouslySetInnerHTML={{ __html: newText }}></p>

        <div className={styles.jobTabWrap}>
          <Tabs defaultActiveKey={type} items={items} onChange={onChange} />
          {sessionid && (
            <div className={styles.jobTab}>
              <div
                className={classNames([
                  styles.jobTabAvatarWrapper,
                  jobsData?.is_vip ? styles.jobTabAvatarVip : ""
                ])}
              >
                <Image
                  className={styles.jobTabAvatar}
                  src={jobsData?.user_avatar}
                  width={28}
                  height={28}
                  alt=''
                ></Image>
                {jobsData?.is_vip && <AvatarVip />}
              </div>
              <span className={styles.jobTabText}>{rightNewText}:</span>
              <span className={styles.jobTabInfo} onClick={handleJobPref}>
                {right_highlight_text}
              </span>
            </div>
          )}
        </div>

        {loading ? (
          <div className={styles.jobTabLoading}>
            <Loading />
          </div>
        ) : (
          <>
            {jobs?.length > 0 ? (
              <>
                <CardList jobs={jobs || []} id={type} sessionid={sessionid} />
                <LinkMoreClient moreText={more_text} langKey={langKey} />
              </>
            ) : (
              <div className={styles.emptyContent}>
                <Empty />
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default JobsHiringPc;
