"use client";
import React, { useEffect, useRef, useState } from "react";
import { debounce } from "lodash-es";
import { toPairs } from "ramda";
import { isMobile } from "react-device-detect";
import classNames from "classnames";

import { addEventTag, convertSpecialCharacters } from "helpers/utilities";
import { genWordLocationPath } from "../../helper";
import useSearchHistory from "helpers/useSearchHistory";

// import HomeSearchInPc from "app/(new-pages)/components/HomeSearch/pc/index.pc";
import CommonSearchPc from "app/(new-pages)/components/Search/pc/index.pc";
import StickySearch from "./StickySearch.pc.client";

import { fetchSearchSuggestionService } from "store/services/jobs/fetchSearchSuggestion";
import { zipLocation } from "../../services";

import styles from "../../styles/index.pc.module.scss";
import { SearchLogo } from "../../images/icons-search";
import PopoverDownload from "./PopoverDownload";

const transObject = (params) => {
  const result = {};
  params.forEach((e, index) => {
    result[`query_histories[${index}]`] = e;
  });
  return result;
};

export const handleFilterSuggestionList = (suggestionList = []) => {
  return suggestionList.map((item) => {
    if (item?.length && typeof item === "string") {
      return { value: item, label: item };
    }

    return {
      ...item,
      label: item?.value
    };
  });
};

const google_tags = ["hp_location_click", "hp_search_focus", "hp_search_click"];

interface SearchInputProps {
  langKey: string;
  pageData: {
    search_info: any;
  };
}

const SearchInput = (props: SearchInputProps) => {
  const isPC = !isMobile;

  const { langKey } = props;
  const search_info = props?.pageData?.search_info || {};
  const location_lists = search_info?.location_lists || [];

  const [locationValue, setLocationValue] = useState({ region: [], location: [] });
  const [query, setQuery] = React.useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [searchHistories, addSearchHistory] = useSearchHistory();
  const [selectQuery, setSelectQuery] = useState(null);

  const [isShow, setIsShow] = useState(false);
  const [isDownloadAppOpacity, setIsDownloadAppOpacity] = useState(false);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const timer = useRef(null);

  useEffect(() => {
    isTouchBottom();
    window.document.addEventListener("scroll", useFn);
    return () => {
      window.document.removeEventListener("scroll", useFn);
      clearTimeout(timer?.current);
    };
  }, []);

  const isTouchBottom = () => {
    if (isPC) {
      const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop;

      if (scrollTopHeight > 780) {
        setIsDownloadAppOpacity(true);
      } else {
        setIsDownloadAppOpacity(false);
      }

      if (scrollTopHeight > 150) {
        setIsShow(true);
        return;
      }
    }

    setIsShow(false);
  };

  const throttle = (func, delay) => {
    let timer = null;
    return function () {
      if (!timer) {
        timer = setTimeout(() => {
          func();
          timer = null;
        }, delay);
      }
    };
  };

  const useFn = throttle(() => {
    isTouchBottom();
  }, 300);

  const onSearch = (value, option) => {
    const isEvent = !option;

    const val = isEvent ? query : value;

    // console.log("onSearch", { query, value, option, isEvent, locationValue });

    if (option) {
      setSelectQuery(option);
    }

    if (val && val.trim()?.length) {
      addSearchHistory(val);
    }
    setQuery(val);

    addEventTag(google_tags[2]);

    if (option) {
      // console.log("val", { val, value, option });
      pushJobPage({ value: val, type: option?.type });
    } else {
      // console.log("val", { selectQuery, val, value });
      pushJobPage({ value: val, type: selectQuery?.type });
    }
  };

  const handleQuery = (val) => {
    // console.log("query", val);
    setSelectQuery(null);
    setQuery(val);
    handleSuggestionSearch(val);
  };

  const pushJobPage = async ({ value = "", type = "" }) => {
    const keyWords = value?.trim?.().replace(/\s+/g, " ");
    const words = convertSpecialCharacters((keyWords || "").trim());
    const result = genWordLocationPath({ locationValue: locationValue, keyword: words });

    let params = {};

    if (type) {
      params["queryFields"] = type;
    }

    // if (result?.multipleLocation) {
    params["location"] = locationValue?.location;
    params["region"] = locationValue?.region;
    // }

    const zippedParams = await zipLocation(params);
    const url = new URLSearchParams(toPairs(zippedParams)).toString();
    // console.log("url", { locationValue, result, url, zippedParams });

    const { searchPath } = result;
    const urlPath = "/" + langKey + `/jobs-hiring/${searchPath}/` + (url ? "?" + url : "");
    // console.log("urlPath", urlPath);

    window.location.href = urlPath;
  };

  const handleSuggestionSearch = debounce(async (val) => {
    const valueLength = val?.length ?? 0;
    if (valueLength < 1) {
      setSuggestionList(searchHistories as any);
    } else {
      const qs = transObject(searchHistories);
      fetchSearchSuggestionService({ size: 5, query: val, ...qs }).then((data) => {
        const items = data?.data?.data?.items || [];
        setSuggestionList(items);
      });
    }
  }, 300);

  const inputPlaceholder = (
    <span className={styles.inputPlaceholder}>
      {search_info?.input_left_text}
      <span className={styles.inputPlaceholder_center}>{search_info?.input_middle_text}</span>
      {search_info?.input_right_text}
    </span>
  );

  return (
    <>
      <div>
        <div>
          <CommonSearchPc
            location_filter={handleFilterSuggestionList(location_lists)}
            visibleLocation={true}
            locationValue={locationValue}
            setLocationValue={(value) => {
              setLocationValue(value);
              addEventTag(google_tags[0]);
            }}
            locationLabel={search_info?.location_text}
            locationPlaceholder={search_info?.location_text}
            locationOptions={{
              searchBoardPlaceholder: search_info?.search_text,
              boardClearText: search_info?.reset,
              boardApplyFiltersText: search_info?.save
            }}
            whereLabelText={search_info?.where_text}
            query={query}
            setQuery={handleQuery}
            onSearch={onSearch}
            inputPlaceholder={inputPlaceholder}
            inputOptions={{
              companyGroupText: search_info?.input_right_text,
              jobTitleGroupText: search_info?.input_left_text,
              historyGroupText: search_info?.search_history_text
            }}
            suggestionList={handleFilterSuggestionList(suggestionList)}
            inputSearchText={search_info?.search_text}
            onFocus={() => {
              addEventTag(google_tags[1]);
              if (!query?.length) {
                setSuggestionList(searchHistories as any);
              }
            }}
          />

          <StickySearch
            langKey={langKey}
            search_info={search_info}
            isShow={false}
            isDownloadAppOpacity={isDownloadAppOpacity}
          />
        </div>
      </div>
      {isShow && (
        <div
          className={styles.searchAreaFix}
          style={{ paddingBottom: isMouseEnter ? "0" : "15px" }}
        >
          <div className={styles.box}>
            <SearchLogo className={styles.searchLogo} />
            <CommonSearchPc
              location_filter={handleFilterSuggestionList(location_lists)}
              visibleLocation={true}
              locationValue={locationValue}
              setLocationValue={(value) => {
                setLocationValue(value);
                addEventTag(google_tags[0]);
              }}
              locationLabel={search_info?.location_text}
              locationPlaceholder={search_info?.location_text}
              locationOptions={{
                searchBoardPlaceholder: search_info?.search_text,
                boardClearText: search_info?.reset,
                boardApplyFiltersText: search_info?.save
              }}
              inputOptions={{
                companyGroupText: search_info?.input_right_text,
                jobTitleGroupText: search_info?.input_left_text,
                historyGroupText: search_info?.search_history_text
              }}
              whereLabelText={search_info?.where_text}
              query={query}
              setQuery={handleQuery}
              onSearch={onSearch}
              inputPlaceholder={inputPlaceholder}
              suggestionList={handleFilterSuggestionList(suggestionList)}
              inputSearchText={search_info?.search_text}
              onFocus={() => {
                addEventTag(google_tags[1]);
                if (!query?.length) {
                  setSuggestionList(searchHistories as any);
                }
              }}
            />

            <StickySearch
              langKey={langKey}
              search_info={search_info}
              isShow={true}
              isDownloadAppOpacity={isDownloadAppOpacity}
              onMouseEnter={() => {
                setIsMouseEnter(true);
              }}
              onMouseLeave={() => {
                timer.current = setTimeout(() => {
                  setIsMouseEnter(false);
                }, 500);
              }}
            />
          </div>

          <PopoverDownload
            show={isMouseEnter}
            onMouseEnter={() => {
              clearTimeout(timer.current);
              setIsMouseEnter(true);
            }}
            onMouseLeave={() => {
              setIsMouseEnter(false);
            }}
            search_info={search_info}
          />
        </div>
      )}
    </>
  );
};

export default SearchInput;
