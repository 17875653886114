"use client";
import React, { useEffect } from "react";
import { useSharedData } from "bossjob-remote/dist/hooks";
import guestLogin from "helpers/guest";
const GetLogin = () => {
  const fileBaseToken = useSharedData("FIREBASE_MESSAGING_TOKEN");
  useEffect(() => {
    if (fileBaseToken) {
      guestLogin(fileBaseToken).catch((e) => {
        console.log(e);
      });
    }
  }, [fileBaseToken]);

  // useEffect(() => {
  // if (typeof window !== "undefined") {
  //   window.scrollTo(0, 0);
  // }
  // }, []);

  return null;
};
export default GetLogin;
