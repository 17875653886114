export const DotSvg = (
  <svg xmlns='http://www.w3.org/2000/svg' width='2' height='3' viewBox='0 0 2 3' fill='none'>
    <circle cx='0.75' cy='1.60156' r='0.75' fill='#515151' />
  </svg>
);

export const AvatarVip = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='8' viewBox='0 0 20 8' fill='none'>
      <path
        d='M6.23335 2.43173C6.79859 1.32747 7.88797 0.57959 9.12849 0.57959H15.5432C17.5606 0.57959 19.1961 2.21506 19.1961 4.23252C19.1961 6.24997 17.5606 7.88544 15.5432 7.88544H0.679688C0.679688 7.88544 2.31982 7.16696 2.97397 6.67819C4.42712 5.59241 5.52716 3.81137 6.23335 2.43173Z'
        fill='#FFE1D0'
      />
      <g filter='url(#filter0_i_4537_65855)'>
        <path
          d='M7.85938 1.92285H8.77342L9.22409 4.66499L9.29392 5.24262H9.3447L9.61764 4.66499L11.0776 1.92285H12.0234L9.57321 6.4677H8.67186L7.85938 1.92285Z'
          fill='#FF9000'
        />
        <path d='M12.4467 1.92285H13.3036L12.4974 6.4677H11.6405L12.4467 1.92285Z' fill='#FF9000' />
        <path
          d='M13.3055 6.4677L14.1116 1.92285H15.635C15.8381 1.92285 16.0243 1.95459 16.1936 2.01806C16.3628 2.08154 16.5088 2.17041 16.6316 2.28466C16.7585 2.39892 16.8558 2.53645 16.9235 2.69725C16.9955 2.85806 17.0315 3.03579 17.0315 3.23045C17.0315 3.48012 16.987 3.70228 16.8982 3.89694C16.8135 4.0916 16.6929 4.25663 16.5363 4.39205C16.3798 4.52323 16.1915 4.62479 15.9714 4.69673C15.7556 4.76444 15.5186 4.79829 15.2605 4.79829H14.4544L14.1624 6.4677H13.3055ZM15.3747 3.98581C15.6033 3.98581 15.7937 3.93079 15.946 3.82077C16.0984 3.70651 16.1745 3.5309 16.1745 3.29392C16.1745 3.12889 16.1238 2.99559 16.0222 2.89403C15.9249 2.78823 15.781 2.73534 15.5906 2.73534H14.8225L14.6003 3.98581H15.3747Z'
          fill='#FF9000'
        />
      </g>
      <defs>
        <filter
          id='filter0_i_4537_65855'
          x='7.85938'
          y='1.92285'
          width='9.17188'
          height='4.94492'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='0.4' />
          <feGaussianBlur stdDeviation='0.2' />
          <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 0.250837 0 0 0 0 0.182731 0 0 0 0.25 0'
          />
          <feBlend mode='normal' in2='shape' result='effect1_innerShadow_4537_65855' />
        </filter>
      </defs>
    </svg>
  );
};
