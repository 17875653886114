"use client";
import React from "react";
import Link from "next/link";
import Image from "next/image";
import classNames from "classnames";

import styles from "../../styles/index.pc.module.scss";

import Web3Image from "../../images/web3.png";
import RemoteJobImage from "../../images/remote.png";
import { addEventTag } from "helpers/utilities";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";

const AdContent = ({ langKey, data }) => {
  if (!data) return null;

  return (
    <div className={styles.recoContent}>
      <div className={classNames([styles.recoItem, styles.recoItemFirst])}>
        <div>
          <Image className={styles.recoItemImage} src={Web3Image} alt={"web3"} />
          <div className={styles.recoItemTitle}>{data?.web3_job_title}</div>
          <div className={styles.recoItemSubtitle}>{data?.web3_job_text}</div>
        </div>
        <Link
          onClick={() => addEventTag("hp_ads_click")}
          href={`/${langKey}/web3-jobs-hiring/job-search`}
          className={styles.recoItemButton}
          target='_blank'
        >
          <Button variant='whiteBtn' btnSize='size44' className={styles.recoItemButtonText}>
            {data?.get_started_text}
          </Button>
        </Link>
      </div>
      <div className={classNames([styles.recoItem, styles.recoItemSecond])}>
        <div>
          <Image className={styles.recoItemImage} src={RemoteJobImage} alt={"web3"} />
          <div className={styles.recoItemTitle}>{data?.remote_job_title}</div>
          <div className={styles.recoItemSubtitle}>{data?.remote_job_text}</div>
        </div>
        <Link
          onClick={() => addEventTag("hp_ads_click")}
          href={`/${langKey}/remote-jobs-hiring/jobs`}
          className={styles.recoItemButton}
          target='_blank'
        >
          <Button variant='blackBtn' btnSize='size44' className={styles.recoItemButtonText}>
            {data?.get_started_text}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default AdContent;
