import { useState, useRef, useCallback } from "react";

export default function useViewMore(toggle = true, ...rest) {
  // Need to set initial CSS
  // such as height, overflow

  const [expand, setExpand] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const initOffsetHeight = useRef(0);
  const [isInitHeight, setIsInitHeight] = useState(false);
  if (toggle === undefined) {
    toggle = true;
  }
  const viewMoreRef = useCallback(
    (el) => {
      if (el) {
        if (!isInitHeight) {
          initOffsetHeight.current = el.offsetHeight;
          setIsInitHeight(true);
        }
        if (!expand && el.scrollHeight > initOffsetHeight.current) {
          el.style.cssText = `height: ${initOffsetHeight.current}px`;
          setShowMore(true);
        } else {
          el.style.cssText = "height: auto; max-height: none";
          if (!toggle) {
            setShowMore(false);
          }
        }
        // window?.scrollTo({
        //   top: 0,
        //   left: 0
        // });
      }
    },
    [expand, isInitHeight, ...rest]
  );

  return {
    showMore,
    expand,
    setExpand,
    setShowMore,
    viewMoreRef
  };
}
