"use client";
import React from "react";
import styles from "./Benefits.pc.module.scss";

const ShowMore = ({ data, showMore, handleClick }) => {
  if (data.benefits_info.length > 3) {
    return (
      <div className={styles.showmore} onClick={handleClick}>
        <span>{!showMore ? data.more_text : data.less_text}</span>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='14'
          height='15'
          viewBox='0 0 14 15'
          fill='none'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M6.87246 8.60253L3.09263 4.8227C2.86482 4.59489 2.49547 4.59489 2.26767 4.8227L2.14392 4.94644C1.91612 5.17425 1.91612 5.54359 2.14392 5.7714L6.87246 10.4999L11.601 5.7714C11.8288 5.54359 11.8288 5.17425 11.601 4.94644L11.4773 4.8227C11.2495 4.59489 10.8801 4.59489 10.6523 4.8227L6.87246 8.60253Z'
            fill='black'
          />
        </svg>
      </div>
    );
  }
  return null;
};

export default ShowMore;
