/* eslint-disable @next/next/no-img-element */
'use client'
import React, { useContext } from 'react'

import Link from 'components/Link'
import Image from 'next/image'

import Accordian from 'components/Accordian'
import Text from 'components/Text'
import QrCodeDraw from 'components/QrCodeDraw/QrCodeDraw'
import { languageContext } from 'app/components/providers/languageProvider'
import { getCountryKey } from 'helpers/country'
import { getCookie } from 'helpers/cookies'
import useWindowSize from 'hooks/useWindowSize'
import { pushToResume } from 'helpers/push'
// import { getLang } from 'helpers/country'

import {
  footer_apple_download,
  footer_googleplay_download,
  FooterGalaxyAppStore,
  FooterHuaweiAppStore,
  FooterXiaomiAppStore
} from 'images'
import telegramSvg from '../svg/telegram.svg'
import xiaohongshuSvg from '../svg/xiaohongshu.svg'

import style from './index.module.scss'
import styles from 'components/Footer/Footer.module.scss'
import linkToHunt from 'helpers/linkToHunt'
import adjustUrlList from 'helpers/adjustUrlList'

const socialInfo = {
  jp: [
    {
      title: 'Bossjob Facebook',
      link: 'https://www.facebook.com/bossjobjp',
      img: `${process.env.S3_BUCKET_URL}/landing/facebook.svg`
    },
    {
      title: 'Bossjob LinkedIn',
      link: 'https://linkedin.com/company/bossjobjp',
      img: `${process.env.S3_BUCKET_URL}/landing/inlink.svg`
    },
    {
      title: 'Bossjob Instagram',
      link: 'https://www.instagram.com/bossjob_japan',
      img: `${process.env.S3_BUCKET_URL}/landing/ins.svg`
    },
    {
      title: 'Bossjob Youtube',
      link: 'https://www.youtube.com/@bossjobjp',
      img: `${process.env.S3_BUCKET_URL}/landing/youtube.svg`
    },
    {
      title: 'Bossjob X',
      link: 'https://x.com/bossjobjp',
      img: `${process.env.S3_BUCKET_URL}/landing/twitterX.svg`
    },
    {
      title: 'Bossjob Tiktok',
      link: 'https://tiktok.com/@bossjobjp',
      img: `${process.env.S3_BUCKET_URL}/landing/tiktok.svg`
    },
    {
      title: 'Bossjob Telegram',
      link: 'https://t.me/jobs',
      img: telegramSvg
    },
    {
      title: 'Bossjob xiao hong shu',
      link: 'https://www.xiaohongshu.com/user/profile/655ec9030000000008001fea?xsec_token=&xsec_source=pc_note',
      img: xiaohongshuSvg
    }
  ],
  sg: [
    {
      title: 'Bossjob Facebook',
      link: 'https://www.facebook.com/BossjobSG',
      img: `${process.env.S3_BUCKET_URL}/landing/facebook.svg`
    },
    {
      title: 'Bossjob LinkedIn',
      link: 'https://linkedin.com/company/bossjobofficial',
      img: `${process.env.S3_BUCKET_URL}/landing/inlink.svg`
    },
    {
      title: 'Bossjob Instagram',
      link: 'https://www.instagram.com/bossjobsg',
      img: `${process.env.S3_BUCKET_URL}/landing/ins.svg`
    },
    {
      title: 'Bossjob Youtube',
      link: 'https://www.youtube.com/@BossjobOfficial',
      img: `${process.env.S3_BUCKET_URL}/landing/youtube.svg`
    },
    {
      title: 'Bossjob X',
      link: 'https://x.com/BossjobOfficial',
      img: `${process.env.S3_BUCKET_URL}/landing/twitterX.svg`
    },
    {
      title: 'Bossjob Tiktok',
      link: 'https://www.tiktok.com/@bossjobsg',
      img: `${process.env.S3_BUCKET_URL}/landing/tiktok.svg`
    },
    {
      title: 'Bossjob Telegram',
      link: 'https://t.me/jobs',
      img: telegramSvg
    }
  ],
  ph: [
    {
      title: 'Bossjob Facebook',
      link: 'https://www.facebook.com/BossjobPH/',
      img: `${process.env.S3_BUCKET_URL}/landing/facebook.svg`
    },
    {
      title: 'Bossjob LinkedIn',
      link: 'https://www.linkedin.com/company/bossjobofficial/',
      img: `${process.env.S3_BUCKET_URL}/landing/inlink.svg`
    },
    {
      title: 'Bossjob Instagram',
      link: 'https://www.instagram.com/bossjobph/',
      img: `${process.env.S3_BUCKET_URL}/landing/ins.svg`
    },
    {
      title: 'Bossjob Youtube',
      link: 'https://www.youtube.com/@BossjobOfficial',
      img: `${process.env.S3_BUCKET_URL}/landing/youtube.svg`
    },
    {
      title: 'Bossjob X',
      link: 'https://x.com/BossjobOfficial',
      img: `${process.env.S3_BUCKET_URL}/landing/twitterX.svg`
    },
    {
      title: 'Bossjob Tiktok',
      link: 'https://www.tiktok.com/@bossjobph',
      img: `${process.env.S3_BUCKET_URL}/landing/tiktok.svg`
    },
    {
      title: 'Bossjob Telegram',
      link: 'https://t.me/jobs',
      img: telegramSvg
    }
  ]
}

const Footer = (props) => {
  const { blogAddressUrl } = props
  const contextLang = useContext(languageContext)
  const isLogin = getCookie('sessionid') ? true : false

  const {
    about,
    aboutBossjob,
    termsConditions,
    legal,
    FAQ,
    talents,
    allJobs,
    createJobAlert,
    createFree,
    careerGuide,
    courses,
    recruiter,
    getStarted,
    popularJobs,
    ItJobs,
    financeJobs,
    customerService,
    BpoJobs,
    salesJobs,
    healthcareJobs,
    downloadBossjobApp,
    followUs,
    technology,
    corporation,
    IndonesiaPT,
    JapanYolo,
    JapanAddress
    // @ts-ignore
  } = contextLang?.foot || {}

  // const langKey = getLang()
  const countryKey = getCountryKey()
  const country_key = ['jp', 'sg', 'ph'].includes(countryKey) ? countryKey : 'ph'
  const follow_use = socialInfo[country_key]
  const careerGuideUrl = countryKey === 'jp' ? 'https://bossjob.jp/blog' : 'https://blog.bossjob.ph'
  // countryKey === 'jp'
  //   ? 'https://blog.bossjob.jp'
  //   : 'https://blog.bossjob.ph/category/career-advice/'

  const colData = [
    {
      title: about,
      links: [
        {
          key: `/company/bossjob-1668`,
          child: aboutBossjob
        },
        {
          key: blogAddressUrl.PrivacyPolicy,
          child: termsConditions
        },
        {
          key: blogAddressUrl.PrivacyPolicy,
          child: legal
        },
        {
          key: blogAddressUrl.TalentsFAQ,
          child: FAQ
        }
      ]
    },
    {
      title: talents,
      links: [
        {
          key: `/jobs-hiring/job-search`,
          child: allJobs
        },
        {
          key: `/jobs-hiring`,
          child: createJobAlert
        },
        {
          key: `/resumetemplate`,
          // key: isLogin ? pushToResume('my-resume') : pushToResume(),
          child: createFree
        },
        {
          key: careerGuideUrl,
          child: careerGuide
        },
        {
          key: 'https://academy.bossjob.ph/courses/search-courses',
          child: courses
        }
      ]
    },
    {
      title: recruiter,
      links: [
        {
          key: linkToHunt(''),
          child: getStarted
        }
      ]
    },
    {
      title: popularJobs,
      links: [
        // ...currentCounties,
        {
          key: `/jobs-hiring/information-technology-jobs?page=1`,
          child: ItJobs
        },
        {
          key: `/jobs-hiring/finance-audit-tax-jobs?page=1`,
          child: financeJobs
        },
        {
          key: `/jobs-hiring/customer-service-operations-jobs?page=1`,
          child: customerService
        },
        {
          key: `/jobs-hiring/bpo-jobs`,
          child: BpoJobs
        },
        {
          key: `/jobs-hiring/sales-jobs?page=1`,
          child: salesJobs
        },
        {
          key: `/jobs-hiring/healthcare-medical-jobs?page=1`,
          child: healthcareJobs
        }
      ]
    }
  ]
  const { width } = useWindowSize()
  const isMobile = width < 540

  if (isMobile) {
    return (
      <div className={style.mobile_footer}>
        <div className={style.mobile_footer_container}>
          <div className={style.mobile_footer_title}>{downloadBossjobApp}</div>
          <div className={style.mobile_footer_download}>
            <div className={style.mobile_footer_download_appStore}>
              <Link href={adjustUrlList[2]?.link} target={'_blank'}>
                <Image src={footer_apple_download} alt='AppStore' width={140} height={42} />
              </Link>
              <Link href={adjustUrlList[2]?.link} target={'_blank'}>
                <Image src={footer_googleplay_download} alt='GooglePlay' width={140} height={42} />
              </Link>
              <Link href={process.env.GALAXY_APP_STORE_LINK} target={'_blank'}>
                <Image src={FooterGalaxyAppStore} alt='GalaxyStore' width={140} height={42} />
              </Link>
              <Link href={process.env.HUAWEI_APP_STORE_LINK} target={'_blank'}>
                <Image src={FooterHuaweiAppStore} alt='HuaweiStore' width={140} height={42} />
              </Link>
              <Link href={process.env.XIAOMI_APP_STORE_LINK} target={'_blank'}>
                <Image src={FooterXiaomiAppStore} alt='XiaomiStore' width={140} height={42} />
              </Link>
            </div>
          </div>
          <div className={style.mobile_list_container}>
            {colData.map((item, index) => {
              return (
                <Accordian
                  key={index}
                  paddedLine
                  paddedContent
                  dark
                  title={
                    <Text textStyle='sm' bold className={style.mobile_list_title}>
                      <span className={style.mobile_list_title}>{item.title}</span>
                    </Text>
                  }
                >
                  <ul className={styles.mobile_list}>
                    {item.links.map((link, index) => {
                      return link.key === '/resumetemplate' ? (
                        <a
                          className={style.mobile_footer_link}
                          style={{ color: '#fff', fontSize: '12px' }}
                          href='#'
                          onClick={(e) => {
                            e.preventDefault()
                            isLogin ? pushToResume('my-resume') : pushToResume()
                          }}
                        >
                          {link.child}
                        </a>
                      ) : (
                        <li key={index}>
                          <Link
                            className={style.mobile_footer_link}
                            href={link.key}
                            title={link.child}
                          >
                            <Text textStyle='sm'>{link.child}</Text>
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </Accordian>
              )
            })}
          </div>
          <div className={style.mobile_follow_us}>
            <div className={style.site_map_title}>{followUs}</div>
            <div className={style.site_map_follow}>
              {follow_use.map((item, index) => {
                return (
                  <Link href={item.link} target={'_blank'} key={index}>
                    <img src={item.img} alt='img' />
                  </Link>
                )
              })}
            </div>
          </div>
          <div className={style.site_copy_right}>
            Copyright © {new Date().getFullYear()} {technology}
            <div style={{ marginTop: 10 }}>{corporation}</div>
            <p>{IndonesiaPT}</p>
            <p>
              {JapanYolo} <span>|</span>
              {JapanAddress}
            </p>
          </div>
        </div>
        <div className={style.mobile_bg_wrapper}>
          {/* <MySvgComponent></MySvgComponent> */}
          <img
            src={`${process.env.S3_BUCKET_URL}/landing/Mobile4-min.jpg`}
            alt={'bg'}
            className={style.mobile_bg}
          />
        </div>
      </div>
    )
  }
  return (
    <div className={style.footer_container}>
      <img
        src={`${process.env.S3_BUCKET_URL}/landing/Web4-min.jpg`}
        alt={'bg'}
        className={style.bg}
      />
      <div className={style.footer_title}>{downloadBossjobApp}</div>
      <div className={style.footer_download}>
        <div className={style.footer_download_appStore}>
          <Link href={adjustUrlList[6]?.link} target={'_blank'}>
            <Image src={footer_apple_download} alt='AppStore' width={140} height={42} />
          </Link>
          <Link href={adjustUrlList[6]?.link} target={'_blank'}>
            <Image src={footer_googleplay_download} alt='GooglePlay' width={140} height={42} />
          </Link>
          <Link href={process.env.GALAXY_APP_STORE_LINK} target={'_blank'}>
            <Image src={FooterGalaxyAppStore} alt='GalaxyStore' width={140} height={42} />
          </Link>
          <Link href={process.env.HUAWEI_APP_STORE_LINK} target={'_blank'}>
            <Image src={FooterHuaweiAppStore} alt='HuaweiStore' width={140} height={42} />
          </Link>
          <Link href={process.env.XIAOMI_APP_STORE_LINK} target={'_blank'}>
            <Image src={FooterXiaomiAppStore} alt='XiaomiStore' width={140} height={42} />
          </Link>
        </div>
        {/* <img
          className={style.qrcode}
          src={`${process.env.S3_BUCKET_URL}/landing/footer_download_qrcode.png`}
          alt='_'
        /> */}
        <div className={style.qrcode}>
          <QrCodeDraw text={adjustUrlList[6].link} ecl='H' width={88} height={88} />
        </div>
      </div>
      <div className={style.footer_site_map}>
        {colData.map((item, index) => {
          return (
            <div className={style.site_map_col} key={index}>
              <div className={style.site_map_title}>{item.title}</div>
              {item.links.map((_item, index) => {
                return _item.key === '/resumetemplate' ? (
                  <a
                    className={style.site_map_link}
                    href='#'
                    onClick={(e) => {
                      e.preventDefault()
                      isLogin ? pushToResume('my-resume') : pushToResume()
                    }}
                  >
                    {_item.child}
                  </a>
                ) : (
                  <Link
                    className={style.site_map_link}
                    target={'_blank'}
                    href={_item.key}
                    key={index}
                  >
                    {' '}
                    {_item.child}
                  </Link>
                )
              })}
            </div>
          )
        })}
        <div className={style.site_map_col}>
          <div className={style.site_map_title}>{followUs}</div>
          <div className={style.site_map_follow}>
            {follow_use.map((item, index) => {
              return (
                <Link href={item.link} target={'_blank'} key={index}>
                  <img src={item.img} alt='img' />
                </Link>
              )
            })}
          </div>
        </div>
      </div>
      <div className={style.site_copy_right}>
        Copyright © {new Date().getFullYear()} {technology}
        <p> {corporation}</p>
        <p>{IndonesiaPT}</p>
        <p>
          {JapanYolo}
          {/* <span>|</span> */}
          {/* {JapanAddress} */}
        </p>
      </div>
    </div>
  )
}

export default Footer
