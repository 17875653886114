"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { AutoComplete } from "antd";
import { HistoryIcons } from "images";
import { SearchIconMobile } from "../assets/icon";
import searchIcon from "../assets/icon/searchIcon.svg";
import historyIcon from "../assets/icon/history.svg";
import jobTitleIcon from "../assets/icon/jobTitle.svg";
import companyIcon from "../assets/icon/company.svg";

import styles from "./style/index.module.scss";

function groupByType(data) {
  const grouped = data.reduce((acc, item) => {
    const type = item?.type || "local_history";

    if (!acc[type]) {
      acc[type] = [];
    }

    acc[type].push(item);

    return acc;
  }, {});

  return Object.keys(grouped).map((type) => ({
    type: type,
    list: grouped[type]
  }));
}

const Title = (props) => {
  const { type, inputOptions } = props;

  if (type == "company") {
    return (
      <div className={styles.groupTitle}>
        <Image src={companyIcon} alt={inputOptions.companyGroupText} width='17' height='17' />
        {inputOptions.companyGroupText}
      </div>
    );
  }

  if (type == "job_title") {
    return (
      <div className={styles.groupTitle}>
        <Image src={jobTitleIcon} alt={inputOptions.jobTitleGroupText} width='17' height='17' />
        {inputOptions.jobTitleGroupText}
      </div>
    );
  }

  return (
    <div className={styles.groupTitle}>
      <Image src={historyIcon} alt={inputOptions.historyGroupText} width='17' height='17' />
      {inputOptions.historyGroupText}
    </div>
  );
};

function transformData(inputData, inputOptions) {
  return inputData.map((item) => ({
    label: <Title type={item.type} inputOptions={inputOptions} />,
    options: item.list || []
  }));
}

const SearchMobile = ({
  setQuery,
  query,
  placeholder,
  suggestionList = [],
  onSearch,
  inputOptions = {
    companyGroupText: "Company name",
    jobTitleGroupText: "Job title",
    historyGroupText: "Search history"
  },
  onFocus = () => {}
}) => {
  const autoCompleteRef = useRef();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(suggestionList);
  }, [suggestionList]);

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const handleEnter = (ev) => {
    if (ev.keyCode == 13 && onSearch) {
      onSearch();
      (autoCompleteRef.current as any)?.blur();
    }
    ev.stopPropagation();
    ev.preventDefault();
  };

  const handleSelect = (value, option) => {
    setQuery(value);
    onSearch(value, option);
    (autoCompleteRef.current as any)?.blur();
  };

  const groupedData = groupByType(options);
  // console.log("groupedData", groupedData);

  const groupedOptions = transformData(groupedData, inputOptions);

  return (
    <div className={styles.container}>
      <AutoComplete
        rootClassName={styles.customAutoComplete}
        ref={autoCompleteRef}
        onSearch={handleSearch}
        onSelect={handleSelect}
        placeholder={placeholder}
        value={query}
        options={groupedOptions}
        optionRender={(ev) => <ItemRender {...ev} />}
        onKeyUp={handleEnter}
        tabIndex={-1}
        onFocus={onFocus}
        getPopupContainer={(triggerNode) => triggerNode || document.body}
        popupMatchSelectWidth={false}
      />

      <div className={styles.searchButton} onClick={onSearch}>
        <SearchIconMobile />
      </div>
    </div>
  );
};

const ItemRender = (option) => {
  const { type, logo_url } = option.data;

  let icon = historyIcon;

  if (type == "company") {
    icon = logo_url;
  }

  if (type == "job_title") {
    icon = searchIcon;
  }
  return (
    <div className={styles.autoCompleteItem}>
      {type == "company" && <Image src={icon} alt='icons' width='17' height='17' />}
      <p>{option.label}</p>
    </div>
  );
};

export default SearchMobile;
