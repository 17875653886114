import { useContext, useState } from "react";
// import { Card, CardTitle } from "../card";

import styles from "./index.module.scss";
// import classNames from "classnames";
import { languageContext } from "app/components/providers/languageProvider";
// import { Preview } from "../Preview";
import { SecondaryHeader } from "../comm/MainHeader";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";
import { RecruiterModal } from "app/components/RecruiterModal";
import { getCookie } from "helpers/cookies";
import { postNotification } from "bossjob-remote/dist/clientStorage";

const OnlineIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
      <circle cx='5.88281' cy='5' r='4.5' fill='#34C759' stroke='white' />
    </svg>
  );
};

const HiBossItem = ({ item, onClick }) => {
  const { is_online } = item;
  return (
    <div className={styles.hiBossItem} onClick={onClick}>
      <div className={styles.avatar}>
        <img src={item.avatar}></img>
        {is_online && (
          <span className={styles.onlineWrapper}>
            <OnlineIcon />
          </span>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "4px", overflow: "hidden" }}>
        <div className={styles.name}>{item.full_name}</div>
        <div className={styles.position}>{item.job_title}</div>
        <div className={styles.lastOnlineWrapper}>{item.reply_label}</div>
      </div>
    </div>
  );
};

const Section = ({ children, gap = "20px" }) => {
  return <div style={{ display: "flex", flexDirection: "column", gap }}>{children}</div>;
};

export const HiBoss = ({ company_members }) => {
  const { members = [], title, less_text, more_text } = company_members || {};
  const [open, setOpen] = useState(false);

  const maxCountForCollapse = 4;
  const showCount = open ? members.length : maxCountForCollapse;
  const [recruiterId, setRecruiterId] = useState(null);
  const contextLang = useContext(languageContext);
  const { overview, tab } = contextLang.companyDetail;

  if (!members.length) {
    return null;
  }

  return (
    <Section>
      <SecondaryHeader>{title}</SecondaryHeader>
      <div className={styles.hiBossRoot}>
        {members.slice(0, showCount).map((v) => {
          return (
            <HiBossItem
              key={v.id}
              item={v}
              onClick={() => {
                let hasLogin = getCookie("sessionid");
                if (hasLogin) {
                  setRecruiterId(v.id);
                } else {
                  postNotification("SHOW_LOGIN_MODAL");
                }
              }}
            />
          );
        })}
      </div>
      {members.length > maxCountForCollapse && (
        <div style={{ marginTop: 4, textAlign: "center" }}>
          <Button
            btnSize='size40'
            variant='grayBtn'
            onClick={() => {
              setOpen((pre) => !pre);
            }}
          >
            {open ? less_text : more_text}
          </Button>
        </div>
      )}
      {recruiterId && (
        <RecruiterModal
          uid={recruiterId}
          display={!!recruiterId}
          onClose={() => setRecruiterId(null)}
          lang={{}}
        ></RecruiterModal>
      )}
    </Section>
  );
};
