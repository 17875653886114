import React from 'react'
import classNames from 'classnames'

export const IconCloseWrap = ({ onClose, isMobile }) => {
  return (
    <div
      className={classNames({
        'icon-close': true,
        'icon-close-mobile': isMobile
      })}
      onClick={onClose}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='40'
        height='40'
        viewBox='0 0 40 40'
        fill='none'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M36.6654 19.9997C36.6654 29.2044 29.2034 36.6663 19.9987 36.6663C10.794 36.6663 3.33203 29.2044 3.33203 19.9997C3.33203 10.7949 10.794 3.33301 19.9987 3.33301C29.2034 3.33301 36.6654 10.7949 36.6654 19.9997ZM14.9989 27.3577L20.0005 22.3561L25.0021 27.3577L27.3591 25.0007L22.3575 19.9991L27.3567 14.9999L24.9997 12.6429L20.0005 17.6421L15.0013 12.6429L12.6443 14.9999L17.6435 19.9991L12.6419 25.0007L14.9989 27.3577Z'
          fill='#C3C7CB'
        />
      </svg>
    </div>
  )
}
