"use client";
import React from "react";
import Link from "next/link";
import { addEventTag } from "helpers/utilities";

interface IProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  [key: string]: any;
}

const LinkToJob = (props: IProps) => {
  const { children, isMobile, company, ...rest } = props;

  const onClick = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    addEventTag("hp_top_companies_job_card_click");
  };
  return (
    <Link {...rest} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkToJob;
