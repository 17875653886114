import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { Checkbox } from "antd";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";

import styles from "./filter.module.scss";
import { useCompanyDetail } from "../../../DataProvider";

const CloseIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='21' viewBox='0 0 20 21' fill='none'>
      <path
        d='M15.3033 6.37523C15.6288 6.04979 15.6288 5.52215 15.3033 5.19671C14.9779 4.87128 14.4502 4.87128 14.1248 5.19671L10 9.3215L5.87523 5.19671C5.54979 4.87128 5.02215 4.87128 4.69671 5.19671C4.37128 5.52215 4.37128 6.04979 4.69671 6.37523L8.8215 10.5L4.69671 14.6248C4.37128 14.9502 4.37128 15.4779 4.69671 15.8033C5.02215 16.1288 5.54979 16.1288 5.87523 15.8033L10 11.6785L14.1248 15.8033C14.4502 16.1288 14.9779 16.1288 15.3033 15.8033C15.6288 15.4779 15.6288 14.9502 15.3033 14.6248L11.1785 10.5L15.3033 6.37523Z'
        fill='#515151'
      />
    </svg>
  );
};

export const Filter = ({ open, jobPageData, initValue, onClose }) => {
  const { lang, companyPageData } = useCompanyDetail();
  const { clear_text } = jobPageData?.search_info || {};
  const { apply_filters_text } = jobPageData;
  const {
    job_type_text,
    job_type_filter,
    degree_text,
    degree_filter,
    xp_lvl_text,
    xp_lvl_filter,
    function_filter,
    function_title
  } = jobPageData;

  const [innerValue, setInnerValue] = useState<Record<string, any>>({});

  useEffect(() => {
    if (open && initValue) {
      setInnerValue({ ...initValue });
    }
  }, [open, initValue]);
  useEffect(() => {
    let value = open ? "hidden" : "auto";
    document.body.style.overflow = value;
  }, [open]);

  // console.log("innervalue", innerValue);

  return (
    <Drawer
      height={"70vh"}
      // rootClassName={styles.outFilterRoot}
      style={{ padding: 0 }}
      placement='bottom'
      open={open}
      title={null}
      closeIcon={null}
      className={styles.filterRoot}
    >
      {/* setOpen */}
      <div
        className={styles.filterWrapper}
        onTouchMove={(e) => {
          e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div className={styles.filterHeader}>
          Filter
          {/*  */}
          <span
            className={styles.closeWrapper}
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon />
          </span>
        </div>
        <div className={styles.filterItemsRoot}>
          {Boolean(function_filter?.length) && (
            <div className={styles.filterItemRoot}>
              <div className={styles.filterItemHeader}>{function_title}</div>
              <div className={styles.filters}>
                {(function_filter || [])?.map((v, i) => {
                  return (
                    <label
                      key={v.id}
                      className={styles.filterItem}
                      onClick={(e) => {
                        setInnerValue((pre) => {
                          return { ...pre, jobFunction: v.id };
                        });
                      }}
                    >
                      <Checkbox checked={v.id === innerValue.jobFunction} />
                      {v.value}
                    </label>
                  );
                })}
              </div>
            </div>
          )}

          {Boolean(xp_lvl_filter?.length) && (
            <div className={styles.filterItemRoot}>
              <div className={styles.filterItemHeader}>{xp_lvl_text}</div>
              <div className={styles.filters}>
                {(xp_lvl_filter || [])?.map((v, i) => {
                  return (
                    <label
                      key={v.id}
                      className={styles.filterItem}
                      onClick={(e) => {
                        setInnerValue((pre) => {
                          return { ...pre, xpl: v.id };
                        });
                      }}
                    >
                      <Checkbox checked={v.id === innerValue.xpl} />
                      {v.value}
                    </label>
                  );
                })}
              </div>
            </div>
          )}
          {Boolean(degree_filter.length) && (
            <div className={styles.filterItemRoot}>
              <div className={styles.filterItemHeader}>{degree_text}</div>
              <div className={styles.filters}>
                {(degree_filter || [])?.map((v, i) => {
                  return (
                    <label
                      key={v.id}
                      className={styles.filterItem}
                      onClick={(e) => {
                        setInnerValue((pre) => {
                          return { ...pre, edu: v.id };
                        });
                      }}
                    >
                      <Checkbox checked={v.id === innerValue.edu} />
                      {v.value}
                    </label>
                  );
                })}
              </div>
            </div>
          )}
          {Boolean(job_type_filter?.length) && (
            <div className={styles.filterItemRoot}>
              <div className={styles.filterItemHeader}>{job_type_text}</div>
              <div className={styles.filters}>
                {(job_type_filter || [])?.map((v, i) => {
                  return (
                    <label
                      key={v.id}
                      className={styles.filterItem}
                      onClick={(e) => {
                        setInnerValue((pre) => {
                          return { ...pre, jobType: v.id };
                        });
                      }}
                    >
                      <Checkbox checked={v.id === innerValue.jobType} />
                      {v.value}
                    </label>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {/*  */}
        <div className={styles.bottomActions}>
          <Button
            variant='primaryOutlineBtn'
            btnSize='size40'
            onClick={() => {
              onClose({});
            }}
          >
            {clear_text}
          </Button>
          <Button
            variant='primaryBtn'
            btnSize='size40'
            onClick={() => {
              onClose(innerValue);
            }}
          >
            {apply_filters_text}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};
