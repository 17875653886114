'use client'

import React from 'react'
import Link from 'components/Link'
import styles from '../../Footer.module.scss'
import { getCountryKey, getCurLanguage } from 'helpers/country'

const AboutList = (props: any) => {
  const { blogAddressUrl, langKey, data } = props

  const countryKey = getCountryKey()

  const { jpOperatingCompanyInformation = '運営会社情報' } = data?.footer || {}
  const { aboutBossjob, dataPrivacyGuide, FAQ } = data?.foot || {}
  const { currentLangAlias } = getCurLanguage()
  const languageCode = currentLangAlias === 'tr' ? 'en' : currentLangAlias
  const FAQUrl = `https://bossjob.crisp.help/${languageCode}`
  const JPUrl = 'https://bossjob.jp/ja/company/yolo-technologyzhu-shi-hui-she-66750'

  return (
    <ul className={styles.footerDesktopLinkList}>
      <li>
        <Link
          className={styles.footerLink}
          to={`/${langKey}/company/bossjob-1668`}
          title={aboutBossjob}
          aTag={false}
          external={false}
        >
          <span>{aboutBossjob}</span>
        </Link>
      </li>
      <li>
        <Link
          className={styles.footerLink}
          to={blogAddressUrl?.DataPrivacyGuide}
          title={dataPrivacyGuide}
          external
        >
          <span>{dataPrivacyGuide}</span>
        </Link>
      </li>
      <li>
        <Link className={styles.footerLink} to={FAQUrl} title={FAQ} external>
          <span>{FAQ}</span>
        </Link>
      </li>

      {countryKey === 'jp' && (
        <li>
          <Link
            className={styles.footerLink}
            to={JPUrl}
            title={jpOperatingCompanyInformation}
            external
          >
            <span>{jpOperatingCompanyInformation}</span>
          </Link>
        </li>
      )}
    </ul>
  )
}

export default AboutList
