"use client";
import React from "react";
import styles from "../../styles/index.mobile.module.scss";

import { getCookie } from "helpers/cookies";

const LinkMoreMobileClient = ({ data, langKey }) => {
  const session = getCookie("sessionid");

  const handleMoreClick = () => {
    if (session) {
      window.location.href = `/${langKey}/companies`;
    } else {
      window.location.href = `/${langKey}/get-started`;
    }
  };

  return (
    <div className={styles.companiesMore}>
      <div className={styles.companiesMoreView} onClick={handleMoreClick}>
        {data?.more_text}{" "}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <path
            d='M4.66797 11.3332L11.3346 4.6665M11.3346 4.6665H4.66797M11.3346 4.6665V11.3332'
            stroke='black'
            strokeWidth='1.33333'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      </div>
    </div>
  );
};

export default LinkMoreMobileClient;
