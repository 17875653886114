import React, { useState, useEffect } from "react";
import { AutoComplete } from "antd";
import { OptionType, SearchInBoardPropsType } from "../../type";
import { DefaultOptionType as Option } from "antd/es/select";

import styles from "../../style/index.module.scss";
import { SearchIcon } from "../../assets";
import { uniqWith, isEqual } from "lodash-es";
// 扁平化 options 并生成完整路径的 label
const flattenOptionsWithPath = (options: Option[]): Option[] => {
  const result: OptionType[] = [];

  const flatten = (items: Option[], currentPath: string[], currentValue: (string | number)[]) => {
    items.forEach((item) => {
      const newPath = [...currentPath, item.label?.toString()];
      const newValue = [...currentValue, item.value];
      const labelPath = newPath.join(" / ");

      result.push({
        value: newValue,
        label: item.label,
        labelPath,
        level: newPath.length
      });

      if (item.children) {
        flatten(item.children, newPath, newValue);
      }
    });
  };

  flatten(options, [], []);
  return result;
};

const highlightMatch = (text: string, searchValue: string) => {
  const regex = new RegExp(`(${searchValue})`, "gi");
  return text.replace(regex, (match) => `<span class=${styles.highlight}>${match}</span>`);
};

const SearchInBoard = ({
  options,
  setValue,
  searchBoardPlaceholder,
  searchValue,
  setSearchValue,
  onChange,
  realTimeRefresh,
  value
}: SearchInBoardPropsType) => {
  const [optionsAfterFilter, setOptions] = useState<Option[]>([]);
  const [flattenedOptions, setFlattenedOptions] = useState<Option[]>([]);

  useEffect(() => {
    setFlattenedOptions(flattenOptionsWithPath(options));
  }, [options]);

  const handleSearch = (value: string) => {
    setSearchValue(value);

    const filteredOptions: Option[] = flattenedOptions
      .filter((item) => (item.label as string).toLowerCase().includes(value.toLowerCase()))
      .map((item) => ({
        ...item,
        value: item.value,
        currentLabel: item.label,
        title: item.labelPath,
        label: <span dangerouslySetInnerHTML={{ __html: highlightMatch(item.labelPath, value) }} />
      }));

    setOptions(
      filteredOptions.sort((a, b) => {
        return b.level - a.level;
      })
    );
  };

  const handleOnSelect = (data, option) => {
    if (realTimeRefresh) {
      const newArr = [];
      if (data?.length == 1) {
        options.map((item) => {
          if (item.value == data[0] && item.children?.length > 0) {
            item.children.map((k) => {
              newArr.push([item.value, k.value]);
            });
          }
        });
      }
      let arr = [];
      if (newArr?.length > 0) {
        arr = uniqWith([...value, ...newArr], isEqual);
      } else {
        arr = uniqWith([...value, data], isEqual);
      }
      onChange(arr, null);
      return;
    }
    setValue((item) => {
      return [...item, data];
    });
    setSearchValue(option.currentLabel);
  };

  return (
    <div className={styles.autoCompleteWrapper}>
      <AutoComplete
        options={optionsAfterFilter}
        onSearch={handleSearch}
        value={searchValue}
        placeholder={searchBoardPlaceholder || "Search"}
        getPopupContainer={(node) => node.parentElement}
        onSelect={handleOnSelect}
        className={styles.overrideAutoComplete}
        dropdownRender={(menu) => <>{searchValue && searchValue.length ? menu : null}</>}
        prefix={<SearchIcon />}
      />
    </div>
  );
};

export default SearchInBoard;
