import React from "react";
import styles from "../../../styles/index.pc.module.scss";

const Loading = () => {
  return (
    <div className={styles.cardLoading}>
      <div className={styles.cardLoadingContent}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
        >
          <path
            d='M14.666 3.99984C14.666 3.26346 15.263 2.6665 15.9993 2.6665C23.3631 2.6665 29.3327 8.63604 29.3327 15.9998C29.3327 23.3636 23.3631 29.3332 15.9993 29.3332C8.63555 29.3332 2.66602 23.3636 2.66602 15.9998C2.66602 15.2635 3.26297 14.6665 3.99935 14.6665C4.73573 14.6665 5.33268 15.2635 5.33268 15.9998C5.33268 21.8909 10.1083 26.6665 15.9993 26.6665C21.8904 26.6665 26.666 21.8909 26.666 15.9998C26.666 10.1088 21.8904 5.33317 15.9993 5.33317C15.263 5.33317 14.666 4.73622 14.666 3.99984Z'
            fill='#1C70EE'
          />
        </svg>
        <span>Loading...</span>
      </div>
    </div>
  );
};

export default Loading;
