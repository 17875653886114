import React from "react";
import classNames from "classnames";
import styles from "./Benefits.pc.module.scss";

export type propsType = {
  data?: {
    [key: string]: any;
  };
  showMore: boolean;
};

const ItemIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'>
    <rect width='20' height='20' fill='white' />
    <path
      d='M13.3333 2.5L13.9429 6.25199C14.1527 7.54346 14.957 8.66136 16.1148 9.27082L17.5 10L16.1148 10.7292C14.957 11.3386 14.1527 12.4565 13.9429 13.748L13.3333 17.5L12.7237 13.748C12.5139 12.4565 11.7096 11.3386 10.5518 10.7292L9.16663 10L10.5518 9.27082C11.7096 8.66136 12.5139 7.54346 12.7237 6.252L13.3333 2.5Z'
      stroke='#121212'
      strokeWidth='1.25'
      strokeLinejoin='round'
    />
    <path
      d='M2.5 7.49992H5.83333M4.16667 5.83325L4.16667 9.16659'
      stroke='#121212'
      strokeWidth='1.25'
      strokeLinecap='round'
    />
    <path
      d='M5.83337 15.8334H9.16671M7.50004 14.1667L7.50004 17.5001'
      stroke='#121212'
      strokeWidth='1.25'
      strokeLinecap='round'
    />
  </svg>
);

const BenefitsItem = ({ data, showMore }: propsType) => {
  return (
    <>
      <h3>{data.title}</h3>
      <ul>
        {data.benefits_info.map((item, idx) => {
          return (
            <li
              key={idx}
              className={classNames({
                [styles.hide]: idx + 1 > 3 && !showMore
              })}
            >
              <ItemIcon />
              <div className={styles.main}>
                <h4>{item.benefit_category}</h4>
                <p className={styles.tag}>{item?.benefits_tags?.join(", ")}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default BenefitsItem;
