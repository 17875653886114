import React, { useEffect, useState } from 'react'
import { Modal, Input, Upload, Button, message, ConfigProvider } from 'antd'
import { Global, css } from '@emotion/react'
import { getSharedData } from 'bossjob-remote/dist/clientStorage'
import Turnstile, { useTurnstile } from 'react-turnstile'
import { fetchFileUpload, fetchFeedback } from 'store/services/newIndex'
const { TextArea } = Input
const maxFileSize = 30 * 1000 * 1000
import type { UploadFile } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import msMY from 'antd/es/locale/ms_MY'
import jaJP from 'antd/es/locale/ja_JP'
import zhTW from 'antd/es/locale/zh_TW'
import en_US from 'antd/es/locale/en_US'
import trTR from 'antd/es/locale/tr_TR'
import idID from 'antd/es/locale/id_ID'
import { getDeviceUuid } from 'helpers/guest'
// antd 组件多语言
const antdInitLang = {
  en: en_US,
  'zh-cn': zhCN,
  ja: jaJP,
  'zh-tw': zhTW,
  ms: msMY,
  tr: trTR,
  id: idID
}
interface initProps {
  open: boolean
  onCancel: () => void
  langKey: string
  lang: any
}
const FeedBack = ({ open, onCancel, langKey, lang }: initProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [siteKey, setSiteKey] = useState('')
  const [turnstileValue, setTurnstileValue] = useState(null)
  const [disabled, setDisabled] = useState(true)
  const [desc, setDesc] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const turnstile = useTurnstile()
  console.log(lang, 6666)
  useEffect(() => {
    const host = window?.location?.host
    const key = /((?:us|uk|ca|br|ng|ae|lk)$)|localhost/.test(host)
      ? getSharedData('ONE_CF_TOKEN_ID')
      : getSharedData('CF_TOKEN_ID')
    setSiteKey(key)
  }, [])

  useEffect(() => {
    if (turnstileValue && desc) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [turnstileValue, desc])

  const handleCancel = () => {
    onCancel()
  }

  const uploadButton = (
    <div>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
      >
        <path
          d='M7 1.375V12.625'
          stroke='#121212'
          stroke-width='1.28571'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M1.375 7H12.625'
          stroke='#121212'
          stroke-width='1.28571'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
      <div
        style={{
          marginTop: 8
        }}
      >
        {lang?.accountSetting?.add}
      </div>
    </div>
  )
  const uploadProps = {
    name: 'file',
    accept: '.png, .jpg, .jpeg',
    multiple: false,
    beforeUpload: (file) => {
      if (file.size > maxFileSize) {
        message.error('to large')
        return false
      }
    },
    customRequest: async ({ file, onSuccess, onError }: any) => {
      const device_udid = await getDeviceUuid()
      fetchFileUpload(file, device_udid)
        .then((res) => {
          const data = res?.data?.data
          onSuccess(
            {
              url: data[0]
            },
            file
          )
        })
        .catch(() => {
          onError(file)
        })
    }
  }
  const reset = () => {
    turnstile?.reset()
    setTurnstileValue('')
  }
  console.log({ fileList })

  const onConfirm = () => {
    setLoading(true)
    const image_urls = fileList.filter((e) => e?.response?.url)?.map((e) => e?.response?.url)
    const params = {
      cf_token: turnstileValue,
      description: desc,
      role_id: 1,
      image_urls: image_urls
    }
    fetchFeedback(params)
      .then((res) => {
        message.success(res?.data?.message)
        onCancel()
      })
      .finally(() => setLoading(false))
  }
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList)

  const local = antdInitLang[langKey] || en_US
  // const a =   {
  //     feedback.title: 'Suggestion & Feedback',
  //     feedback.info: 'It is recommended that you leave your contact information in the description so that we can better solve the problem you encounter',
  //     feedback.description: 'description',
  //     feedback.placeholder: 'Please briefly describe your suggestions or problems, explain your demands and leave available contact information',
  //     feedback.descriptionImage: 'Description image',
  //     feedback.uploadTips: 'Upload up to three pictures, supporting JPG, JPEG, and PNG formats',
  //     feedback.humanMachineVerification : 'Human-machine verification',
  //    }

  return (
    <>
      <Global styles={GlobalStyles} />
      <Modal
        title={lang?.feedback?.title}
        wrapClassName='feedBackModal'
        open={open}
        footer={null}
        onCancel={handleCancel}
      >
        <p className='feedInfo'>{lang?.feedback?.info}</p>
        <div className='feedItem'>
          <p className='feedName'>
            {lang?.feedback?.description} <span>*</span>
          </p>
          <div>
            <TextArea
              placeholder={lang?.feedback?.placeholder}
              allowClear
              rows={4}
              showCount
              onChange={(e) => setDesc(e.target.value)}
              maxLength={200}
            />
          </div>
        </div>
        <div className='feedItem'>
          <p className='feedName'>{lang?.feedback?.descriptionImage}</p>
          <p className='feedUploadType'>{lang?.feedback?.uploadTips}</p>
          <ConfigProvider locale={local}>
            <Upload
              listType='picture-card'
              {...uploadProps}
              fileList={fileList}
              multiple={false}
              onChange={handleChange}
            >
              {fileList.length >= 3 ? null : uploadButton}
            </Upload>
          </ConfigProvider>
        </div>
        <div className='feedItem'>
          <p className='feedName'>{lang?.feedback?.humanMachineVerification}</p>
          {siteKey && (
            <Turnstile
              sitekey={siteKey}
              theme='light'
              onVerify={(token) => {
                setTurnstileValue(token)
              }}
              onExpire={() => {
                reset()
              }}
              retry='never'
              refreshExpired='never'
              appearance='always'
            />
          )}
        </div>
        <div className='feedFooter'>
          <Button onClick={onCancel}>{lang?.comm?.cancel}</Button>
          <Button type='primary' onClick={onConfirm} disabled={disabled} loading={loading}>
            {lang?.comm?.confirm}
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default FeedBack

const GlobalStyles = css`
  html,
  body {
    .feedBackModal {
      .ant-modal-content {
        padding: 36px;
      }
      /* .ant-input-data-count {
        bottom: 1px;
        inset-inline-end: 10px;
      } */
      .ant-modal-title {
        color: #121212;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
        padding: 0 !important;
        border: 1px solid #fff;
        border-radius: 8px !important;
        overflow: hidden !important;
        &:before {
          width: 100% !important;
          height: 100% !important;
        }
      }
      .ant-upload-wrapper .ant-upload-list .ant-upload-list-item-error {
        border: 1px solid red;
      }
      .feedInfo {
        color: #7d7d7d;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .feedName {
        color: #121212;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        padding-bottom: 4px;
        text-transform: capitalize;

        span {
          color: #fe4a4b;
          padding-left: 4px;
        }
      }
      .feedItem {
        padding-top: 32px;
      }
      .feedUploadType {
        color: #515151;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        padding: 0 0 8px;
        line-height: normal;
      }
      .anticon-eye {
        display: none;
      }
      .feedFooter {
        margin-top: 36px;
        gap: 13px;
        justify-content: flex-end;
        display: flex;
        .ant-btn-default {
          border-radius: 4px;
          border: 1px solid #e4e4e4;
          color: #515151;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          height: 40px;
          padding: 0 48px;
          line-height: 23px;
          box-shadow: none;
          &:hover {
            color: unset;
            border: 1px solid #e4e4e4;
          }
        }
        .ant-btn-primary {
          border-radius: 4px;
          background: #2378e5;
          height: 40px;
          padding: 0 48px;
          &:disabled {
            background: rgba(0, 0, 0, 0.04);
          }
        }
      }
    }
  }
`
