'use client'
import React, { useContext, useEffect, useMemo } from 'react'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { getCookie } from 'helpers/cookies'
import { LinkContext } from 'app/components/providers/linkProvider'
import { getLang } from 'helpers/country'
import { useMainResumeContext, withMainResumeProvider } from './context'

import { CommonHeader } from '../components/Common/Header'
import CompleteProfile from '../components/CompleteProfile'

import styles from './index.pc.module.scss'

import Loading from 'app/components/loading'
import { isMobileDevice } from 'helpers/utilities'
import DesiredHeaderMobile from '../components/Common/DesiredHeader.mobile'
import CompleteProfileH5 from '../components/CompleteProfile/h5'

export const MainLayout = (props: { lang: any; config: any }) => {
  const sessionid = getCookie('sessionid')
  const { push } = useContext(LinkContext)
  // const searchParams = useSearchParams()
  // const step = searchParams.get('step')
  const { getUserInfo } = useMainResumeContext()
  const [loading, setLoading] = React.useState(true)
  const isMobile = isMobileDevice()

  const langKey = getLang()
  if (!sessionid) {
    push(`/${langKey}/get-started`)
  }

  useEffect(() => {
    if (sessionid) {
      setLoading(true)
      getUserInfo().finally(() => setLoading(false))
    }
  }, [])

  return (
    <CacheProvider value={createCache({ key: 'complete-profile-thank', prepend: true })}>
      <div className={styles.main}>
        {isMobile ? (
          <DesiredHeaderMobile
            className={styles.commonHeader}
            lang={props.lang}
            langKey={langKey}
          />
        ) : (
          <CommonHeader lang={props.lang} langKey={langKey} />
        )}

        <main className={styles.container}>
          {loading ? (
            <div style={{ minHeight: 'calc(100vh - 180px)', position: 'relative', width: '100%' }}>
              <Loading />
            </div>
          ) : (
            <>{isMobile ? <CompleteProfileH5 {...props} /> : <CompleteProfile {...props} />}</>
          )}
        </main>
      </div>
    </CacheProvider>
  )
}

export default withMainResumeProvider(MainLayout)
