const adjustUrlList = [
  // H5
  {
    link: 'https://app.adjust.com/1i4nq4pa',
    adjustConf: 'https://suite.adjust.com/custom-links/1i4nq4pa/adgroup/link-urls',
    comment: '全局浮动下载横幅'
  },
  {
    link: "https://pbfh.adj.st/bossjob.ph/home?adj_t=1izto6rx",
    adjustConf: 'https://suite.adjust.com/custom-links/1izto6rx/adgroup/link-urls',
    comment: 'APP落地页下载'
  },
  {
    link: "https://pbfh.adj.st/bossjob.ph/home?adj_t=1iny6pk1",
    adjustConf: 'https://suite.adjust.com/custom-links/1iny6pk1/adgroup/link-urls',
    comment: 'APP落地页Footer'
  },

  // Web - 求职端
  {
    link: "https://app.adjust.com/1id1um8d",
    adjustConf: 'https://suite.adjust.com/custom-links/1id1um8d/adgroup/link-urls',
    comment: '主页横幅'
  },
  {
    link: "https://app.adjust.com/1ii3hya6",
    adjustConf: 'https://suite.adjust.com/custom-links/1ii3hya6/adgroup/link-urls',
    comment: '全局Footer'
  },
  {
    link: "https://app.adjust.com/1ik4j1mk",
    adjustConf: 'https://suite.adjust.com/custom-links/1ik4j1mk/adgroup/link-urls',
    comment: 'APP落地页'
  },
  {
    link: 'https://app.adjust.com/1isifr89',
    adjustConf: 'https://suite.adjust.com/custom-links/1isifr89/adgroup/link-urls',
    comment: 'APP落地页footer'
  },
  {
    link: 'https://pbfh.adj.st/bossjob.ph/job-details?adj_t=1irm40ji&adj_deep_link=bossjob://bossjob.ph/job-details?jobId=',
    adjustConf: 'https://suite.adjust.com/custom-links/1irm40ji/adgroup/link-urls',
    comment: '工作列表'
  },
  {
    link: 'https://pbfh.adj.st/bossjob.ph/chat-home?adj_t=1in8ahrl',
    adjustConf: 'https://suite.adjust.com/custom-links/1in8ahrl/adgroup/link-urls',
    comment: 'PC Chat 聊天窗口'
  },
  // new add H5
  {
    link: 'https://app.adjust.com/1iy211g7?adgroup=Chat',
    adjustConf: 'https://suite.adjust.com/custom-links/1iy211g7/campaign/link-urls',
    comment: 'H5 Chat聊天窗口'
  }
]

export const appStoreLink = 'https://apps.apple.com/us/app/bossjob-chat-job-search/id1592073585?mt=8'

export const googleStoreLink = 'https://play.google.com/store/apps/details?id=com.poseidon.bossjobapp&hl=en'

export default adjustUrlList