"use client";
import React from "react";
import JobClient from "../JobClient/JobClient.pc";
import styles from "./JobHeader.pc.module.scss";

const JobHeader = ({ sessionid, jobId, transData, data }) => {
  // console.log("JobHeader@data", data);

  return (
    <div className={styles.jobHeader}>
      {data.is_pending_job ? <span className={styles.pending}>{data.pending_job_text}</span> : null}
      <h3>{transData.isOriginal ? data.job_title : data.translationTitle}</h3>
      <p className={styles.companyName}>{data.company_name}</p>
      <div className={styles.horLine}></div>
      <div className={styles.infoContent}>
        {/* {data.is_urgent ? <span className={styles.urgent}>{data.urgent_text}</span> : null} */}
        <div className={styles.salaryInfo}>
          {data.salary_info?.text}
          {data.salary_info?.type_text ? (
            <span className={styles.type}>[{data.salary_info?.type_text}]</span>
          ) : null}
        </div>
        <div className={styles.detailInfo}>
          {data.location ? <span>{data.location}</span> : null}
          {data.location ? (
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='2'
                height='3'
                viewBox='0 0 2 3'
                fill='none'
              >
                <circle cx='0.75' cy='1.45508' r='0.75' fill='#515151' />
              </svg>
            </span>
          ) : null}
          {data.xp_lvl ? <span>{data.xp_lvl}</span> : null}
          {data.xp_lvl ? (
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='2'
                height='3'
                viewBox='0 0 2 3'
                fill='none'
              >
                <circle cx='0.75' cy='1.45508' r='0.75' fill='#515151' />
              </svg>
            </span>
          ) : null}
          {data.degree ? <span>{data.degree}</span> : null}
          {data.degree ? (
            <span>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='2'
                height='3'
                viewBox='0 0 2 3'
                fill='none'
              >
                <circle cx='0.75' cy='1.45508' r='0.75' fill='#515151' />
              </svg>
            </span>
          ) : null}
          {data.job_type ? <span>{data.job_type}</span> : null}
        </div>
      </div>
      <JobClient data={data?.allData} sessionid={sessionid} jobId={jobId} />
    </div>
  );
};

export default JobHeader;
