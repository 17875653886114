import styled from '@emotion/styled'
import React from 'react'

const UploadResumeWrap = (props: {
  children: React.ReactNode
  lang: any
  footer?: React.ReactNode
  title?: React.ReactNode
}) => {
  const { children, lang = {}, footer, title } = props
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  return (
    <ContentWrap>
      <div className='modal-content'>
        <h2 className='modal-title'>{title ? title : profile?.uploadResumeModal?.title}</h2>
        {children}
        <div className='tips'>
          <p className='title'>{profile?.uploadResumeModal?.tips}:</p>
          <p className='content'>1. {profile?.uploadResumeModal?.tip1}</p>
          <p className='content'>2. {profile?.uploadResumeModal?.tip2}</p>
        </div>
        {footer}
      </div>
    </ContentWrap>
  )
}

export default UploadResumeWrap

const media = {
  sm: `@media (max-width: 576px)`,
  md: `@media (min-width: 800px)`,
  msd: `@media (max-width: 799px)`
}

const ContentWrap = styled.div`
  .modal-closed {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .re-upload,
  .confirm {
    min-width: 160px;
  }
  .modal-title {
    color: #000;
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    padding-top: 16px;

    ${media.sm} {
      font-size: 20px;
    }
  }
  .footerWrapper {
    ${media.msd} {
      padding-top: 30px;
    }
  }
  .tips {
    text-align: left;
    margin-top: 32px;
    font-size: 14px;
    color: #666;
    padding-bottom: 28px;
    ${media.msd} {
      padding-bottom: 24px;
      padding-bottom: 0;
    }
    .title {
      color: #4b4c4d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.14px;
      padding-bottom: 6px;
    }
    .content {
      margin-top: 6px;
      color: #4b4c4d;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
`
