import * as React from "react";

export const IconSearch = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={17} height={16} fill='none' {...props}>
    <path
      fill='#B8B8B8'
      d='m13.647 14.09-1.402-1.402a6 6 0 1 1 .943-.943l1.401 1.402-.942.942ZM8.5 12.666a4.667 4.667 0 1 0 0-9.334 4.667 4.667 0 0 0 0 9.334Z'
    />
  </svg>
);

export const SearchLogo = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={96} height={24} fill='none' {...props}>
    <path
      fill='#1C70EE'
      d='M59.608 23.95c-1.15 0-1.924-.19-2.512-.405l.695-2.942c.395.14.81.213 1.23.216 1.281 0 1.656-.863 1.656-2.562V6.934h3.929v11.219c0 3.237-1.23 5.797-4.998 5.797Z'
    />
    <path
      fill='#1C70EE'
      fillRule='evenodd'
      d='M73.55 21.997c4.684 0 7.515-3.46 7.515-7.486s-2.833-7.435-7.515-7.435c-4.682 0-7.466 3.41-7.466 7.435 0 4.024 2.831 7.486 7.466 7.486Zm0-3.356c-2.318 0-3.605-1.91-3.605-4.131 0-2.22 1.286-4.13 3.605-4.13 2.365 0 3.654 1.91 3.654 4.13 0 2.22-1.289 4.13-3.654 4.13ZM82.44.955h3.683v6.484h2.644a7.205 7.205 0 0 1 5.114 2.127A7.266 7.266 0 0 1 96 14.702a7.28 7.28 0 0 1-2.119 5.136 7.217 7.217 0 0 1-5.114 2.128H82.44V.955Zm3.682 16.672v1.126h2.102l-.001-.003a4.074 4.074 0 0 0 2.895-1.203 4.115 4.115 0 0 0 1.198-2.907 4.12 4.12 0 0 0-1.199-2.906 4.084 4.084 0 0 0-2.894-1.204h-2.1v7.097Z'
      clipRule='evenodd'
    />
    <path
      fill='#1C70EE'
      d='M22.437 22.046c4.605 0 7.383-3.4 7.383-7.356 0-3.955-2.782-7.306-7.383-7.306-4.602 0-7.332 3.349-7.332 7.306 0 3.958 2.782 7.356 7.332 7.356Zm0-3.298c-2.275 0-3.539-1.877-3.539-4.058s1.264-4.06 3.539-4.06c2.326 0 3.59 1.879 3.59 4.06 0 2.182-1.264 4.056-3.59 4.056v.002ZM37.1 22.046c3.843 0 6.068-1.877 6.068-4.464 0-5.632-8.29-3.908-8.29-5.935 0-.763.859-1.371 2.174-1.371 1.719 0 3.288.762 4.147 1.624l1.474-2.59a8.972 8.972 0 0 0-5.624-1.926c-3.684 0-5.71 2.035-5.71 4.414 0 5.479 8.242 3.603 8.242 5.884 0 .864-.759 1.48-2.326 1.48-1.719 0-3.793-.964-4.804-1.928l-1.571 2.622c1.517 1.378 3.844 2.19 6.22 2.19Zm13.271 0c3.842 0 6.067-1.877 6.067-4.464 0-5.632-8.288-3.908-8.288-5.935 0-.763.86-1.371 2.175-1.371 1.718 0 3.286.762 4.144 1.624l1.474-2.59a8.975 8.975 0 0 0-5.613-1.928c-3.693 0-5.71 2.035-5.71 4.415 0 5.478 8.241 3.602 8.241 5.883 0 .864-.759 1.48-2.326 1.48-1.719 0-3.793-.964-4.804-1.928l-1.579 2.624c1.466 1.378 3.791 2.19 6.219 2.19Z'
    />
    <path
      fill='#1C70EE'
      fillRule='evenodd'
      d='M0 .955h3.702v6.519H6.36a7.243 7.243 0 0 1 5.142 2.139 7.304 7.304 0 0 1 2.13 5.164 7.319 7.319 0 0 1-2.13 5.163 7.255 7.255 0 0 1-5.142 2.14H0V.954Zm3.701 16.762v1.132h2.113v-.003a4.097 4.097 0 0 0 2.909-1.21 4.132 4.132 0 0 0 1.205-2.922 4.142 4.142 0 0 0-1.205-2.922 4.105 4.105 0 0 0-2.91-1.21h-2.11v7.135H3.7Z'
      clipRule='evenodd'
    />
    <path
      fill='#FFC53D'
      d='M60.187 3.308c0 1.322.964 2.3 2.294 2.3a2.236 2.236 0 0 0 2.135-1.41 2.25 2.25 0 0 0 .16-.89c0-1.38-.99-2.3-2.295-2.3-1.33 0-2.294.92-2.294 2.3Z'
    />
  </svg>
);
