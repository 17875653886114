"use client";
import React, { useEffect } from "react";
import Link from "next/link";
import { addEventTag } from "helpers/utilities";

interface LinkClientProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  [key: string]: any;
}

const LinkClient = (props: LinkClientProps) => {
  const { children, ...rest } = props;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    addEventTag("hp_popular_jobs_click");
  };
  return (
    <Link {...rest} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkClient;
