import React from "react";
// import MapClient from "./MapClient.pc";
import styles from "./Location.pc.module.scss";
import classNames from "classnames";

const Location = ({ data, className = "" }) => {
  if (data) {
    return (
      <div className={classNames([styles.location, className])}>
        <h3>{data.title}</h3>
        {data.address ? <p className={styles.address}>{data.address}</p> : null}
        {/* {data.distance ? (
          <p className={styles.distance}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              viewBox='0 0 16 16'
              fill='none'
            >
              <path
                d='M8.66671 7.33333H11.8667C12.6134 7.33333 12.9868 7.33333 13.272 7.47866C13.5229 7.60649 13.7269 7.81046 13.8547 8.06135C14 8.34656 14 8.71993 14 9.46667V14M8.66671 14V4.13333C8.66671 3.3866 8.66671 3.01323 8.52138 2.72801C8.39355 2.47713 8.18958 2.27316 7.93869 2.14532C7.65348 2 7.28011 2 6.53337 2H4.13337C3.38664 2 3.01327 2 2.72805 2.14532C2.47717 2.27316 2.2732 2.47713 2.14537 2.72801C2.00004 3.01323 2.00004 3.3866 2.00004 4.13333V14M14.6667 14H1.33337M4.33337 4.66667H6.33337M4.33337 7.33333H6.33337M4.33337 10H6.33337'
                stroke='#7D7D7D'
                strokeWidth='1.2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
            {data.distance}
          </p>
        ) : null} */}
        {/* <div className={styles.map}>
          <MapClient data={data} />
        </div> */}
      </div>
    );
  }

  return null;
};

export default Location;
