'use client'
import React, { useCallback, useState } from 'react'
import { useRouter, useSearchParams, usePathname, useParams } from 'next/navigation'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import styles from './Hamburger.module.scss'
import { toggleMenu } from 'store/actions/navigationBar/toggleMenu'
import Link from 'components/Link'
import linkToHunt from 'helpers/linkToHunt'
import { addEventTag } from 'helpers/utilities'
import classNames from 'classnames'
import { getCountryKey } from 'helpers/country'

interface HamburgerProps {
  disabled?: boolean
  lang?: any
  userInfo?: any
  isLogin: boolean
  pageType?: string
  isLoginPage?: boolean
}
const Hamburger = ({ lang, isLogin, isLoginPage = false }: HamburgerProps) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [openState, setOpenState] = useState(false)
  const pathname = usePathname()
  const handleShowMenu = useCallback(() => {
    postNotification('DISPATCH', toggleMenu(!openState))
  }, [toggleMenu, postNotification])

  // useEffect(() => {
  //   receiveNotification('DISPATCH_CLOSE', () => {
  //     setOpenState(false)
  //   })
  // }, [])
  const params = useParams()
  const handleToGetStarted = () => {
    router.push('/get-started')
    if (searchParams.get('from') === 'sg-h5') {
      window?.gtag?.('event', 'sgh5_job_detail_get_started')
    }

    setOpenState(false)
  }
  const countryKey = getCountryKey()
  return (
    <div className={styles.hamburgerWrapper}>
      {!isLogin && !isLoginPage && (
        <div
          className={styles.hamburgerWrapper_getStarted}
          // style={{ marginRight: 25 }}
          onClick={handleToGetStarted}
          {...(pathname.endsWith('/remote-jobs') && {
            'data-gtag-event': 'rl_active_get_started'
          })}
        >
          {lang.getStarted}
        </div>
      )}
      {
        !isLogin && isLoginPage && (
          <div>
            <Link
              to={linkToHunt('', params.lang as string, countryKey)}
              aTag
              style={{
                width: 122,
                height: 32,
                borderRadius: 8,
                fontSize: 14,
                fontWeight: 600,
                lineHeight: 22,
                color: '#000',
                background: '#F8F9FA',
                padding: 12,
                marginRight: 12
              }}
              onClick={() => {
                addEventTag('hp_employer_click')
              }}
              onMouseOver={() => {
                addEventTag('hp_employer_hover')
              }}
            >
              {lang.hiring}
            </Link>
          </div>
        )
        // </HtmlTooltip>
      }
      {/* <div
        className={classNames([styles.mobile_menu_off, openState ? styles.mobile_menu_open : null])}
        onClick={disabled ? null : handleShowMenu}
      >
        <div id={styles.hamburgerMenu} className={openState ? styles.active : null}>
          {isLogin && !openState ? (
            !userInfo?.vip?.is_vip ? (
              <img
                src={userInfo?.avatar || DefaultAvatar}
                className={styles.profileAvatar}
                alt='avatar'
                style={{ width: '27px', height: '27px' }}
              />
            ) : (
              <div className={styles.vipAvatar}>
                <img
                  src={userInfo?.avatar || DefaultAvatar}
                  className={styles.profileAvatar}
                  alt='avatar'
                />
                <img
                  src={vip_user_icon_url.src}
                  width={17}
                  height={7}
                  alt=''
                  style={{ position: 'absolute', bottom: '-2px', right: 0 }}
                />
              </div>
            )
          ) : (
            <>
              <span />
              <span />
              <span />
            </>
          )}
        </div>
      </div> */}
      <div
        style={{
          position: 'relative',
          marginRight: 4
        }}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          onClick={(e) => {
            e.stopPropagation()
            handleShowMenu()
          }}
        >
          <path
            d='M1.125 4.5C0.572715 4.5 0.125 4.94772 0.125 5.5C0.125 6.05228 0.572715 6.5 1.125 6.5H21C21.5523 6.5 22 6.05228 22 5.5C22 4.94772 21.5523 4.5 21 4.5H1.125Z'
            fill='#121212'
          />
          <path
            d='M6.5 12C6.5 11.4477 6.94772 11 7.5 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H7.5C6.94772 13 6.5 12.5523 6.5 12Z'
            fill='#121212'
          />
          <path
            d='M7.9375 18.5C7.9375 17.9477 8.38521 17.5 8.9375 17.5H20.4375C20.9898 17.5 21.4375 17.9477 21.4375 18.5C21.4375 19.0523 20.9898 19.5 20.4375 19.5H8.9375C8.38521 19.5 7.9375 19.0523 7.9375 18.5Z'
            fill='#121212'
          />
        </svg>
      </div>
    </div>
  )
}

export default Hamburger
