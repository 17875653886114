'use client'
import React, { useEffect, useState, useRef } from 'react'

import { fetchUserOwnDetailService } from 'store/services/users/fetchUserOwnDetail'
import { getLang } from 'helpers/country'
import styles from './index.module.scss'
import Toast from 'app/components/Toast'
import { isMobile } from 'react-device-detect'
import vip_activity_image_url from './vip_activity_image1.png'
import icon_close_url from './icon_close.svg'
import { getCookie } from 'helpers/cookies'
import { postNotification } from 'bossjob-remote/dist/clientStorage'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import vipResume from './vipResume.png'
export const VipActivity = ({ lang, style = {} }) => {
  const [referralCode, setReferralCode] = useState('')
  const [vipModal, setVipModal] = useState(false)

  const advertisingLink = lang?.advertisingLink || {}
  const sessionid = getCookie('sessionid')
  useEffect(() => {
    sessionid &&
      fetchUserOwnDetailService().then((res) => {
        if (res?.data?.data) {
          setReferralCode(res.data.data.referral_code)
        }
      })
  }, [sessionid])

  const handleVipModalClick = () => {
    if (sessionid) {
      setVipModal(true)
    } else {
      postNotification('SHOW_LOGIN_MODAL')
    }
  }

  return (
    <>
      <div className={styles.vipImage} onClick={handleVipModalClick} style={style}>
        {/* <Button variant='contained' className={styles.btn}>
          {advertisingLink.GetVipForFree}
        </Button>
        <span className={styles.desc}>{advertisingLink.InviteFriendsToGetAIResumeCoaching}</span>
        <img
          src={vip_activity_image_url.src}
          width={514}
          height={268}
          alt='vip_activity_image'
          className={styles.image}
        /> */}
        <p className={styles.title}>{advertisingLink.InviteFriendsToGetAIResumeCoaching}</p>
        <img src={vip_activity_image_url.src} alt='vip_activity_image' className={styles.image} />
        <div className={styles.btnBox}>
          <Button btnSize='size40' variant='blackBtn' style={{ borderRadius: '12px' }}>
            {advertisingLink.GetVipForFree}
          </Button>
        </div>
      </div>
      {vipModal && (
        <VipShareModal
          referral_code={referralCode}
          lang={lang}
          onCloseModal={() => setVipModal(false)}
        />
      )}
    </>
  )
}

export const VipShareModal = ({ referral_code, lang, onCloseModal }) => {
  const copyTextRef = useRef(null)
  const newGetStarted = lang?.newGetStarted || {}
  const langKey = getLang()

  return (
    <div className={styles.vipShareWrapper}>
      <div className={styles.vipShareModal}>
        <img
          className={styles.close}
          src={icon_close_url}
          alt=''
          width='15'
          height='15'
          onClick={onCloseModal}
        />
        <div className={styles.main}>
          <div className={styles.left}>
            <p className={styles.buttonText}>{newGetStarted.vipText.inviteFriendsToGet}</p>
            {!isMobile && (
              <p className={styles.blueText}>{newGetStarted.vipText.AIResumeCoaching}</p>
            )}
            <p className={styles.descText}>
              {
                newGetStarted.vipText
                  .HighQualityVIPResumeTemplateAndAIAssistantToHelpYouGetHighPayingOffer
              }
            </p>
            <div className={styles.linksBox}>
              <p className={styles.links} ref={copyTextRef}>
                {`${location.origin}/${langKey}/get-started?referral_code=${referral_code}&invited_source=resume_template`}
              </p>
            </div>
            <div className={styles.copyButton}>
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(copyTextRef.current.innerText)
                  Toast.success(lang?.advertisingLink?.linkCopiedSuccess || 'Link copied success!')
                }}
                style={{ width: '262' }}
                btnSize='size48'
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='25'
                  height='24'
                  viewBox='0 0 25 24'
                  fill='none'
                >
                  <path
                    d='M7.5 17L17.5 7'
                    stroke='white'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                  <path
                    d='M7.5 7H17.5V17'
                    stroke='white'
                    stroke-width='2'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
                <span className={styles.btnText}>{newGetStarted.vipText.copyLinkToInviteNow}</span>
              </Button>
            </div>
          </div>
          <div className={styles.right}>
            <img src={vipResume.src} />
          </div>
        </div>
      </div>
    </div>
  )
}
