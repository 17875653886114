import { ReactNode, useEffect, useState } from "react";
import { Select } from "antd";
import classNames from "classnames";

import Board from "./components/Board";
import { MultiSelectPcPropsType } from "./type";
import {
  DropdownIconBluePc,
  DropdownIconDark,
  DropdownIconPc,
  PlaceIcon,
  PullUPIcon
} from "./assets";

import styles from "./style/index.module.scss";

const MultiSelectPc = ({
  options,
  value,
  onChange,
  label,
  boardClearText,
  boardApplyFiltersText,
  searchBoardPlaceholder,
  visibleSearch,
  oneLabel,
  placeholder = label,
  wrapperClassName,
  arrowIconStyle = "default",
  showWhereLabel,
  whereLabelText = "Where",
  realTimeRefresh = false,
  cascaderPanelProps,
  ...rest
}: MultiSelectPcPropsType) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectValue, setSelectValue] = useState(value);

  useEffect(() => {
    setSelectValue(value);
  }, [value, open]);

  const onMultipleChange = (value) => {
    if (realTimeRefresh) {
      onChange(value, null);
    } else {
      setSelectValue(value);
    }
  };

  useEffect(() => {
    if (!open) {
      setSearchValue("");
    }
  }, [open]);

  const onCloseDropdown = () => {
    setOpen(false);
  };

  const selectedValue = value.length == 1 ? (oneLabel ? oneLabel : label) : label;

  const customLabel = (
    <div className={styles.inputLabel}>
      <div className={!value?.length ? "selectLabelPlaceholder" : "selectLabel"}>
        {!value?.length ? placeholder : selectedValue}
      </div>
      {value && value.length > 1 && (
        <div>
          <span className={styles.size}>{value.length > 99 ? "99+" : value.length}</span>
        </div>
      )}
    </div>
  );

  // Where label
  const whereLabel = (
    <div className={styles.whereLabelWarp}>
      <PlaceIcon />
      <div className={styles.whereLabel}>
        <div className={classNames({ [styles.whereMinLabel]: true, whereLabelWarp: true })}>
          {whereLabelText}
        </div>
        <div className={styles.inputLabel}>
          <div className={!value?.length ? "selectLabelPlaceholder" : "selectLabel"}>
            {!value?.length ? placeholder : selectedValue}
          </div>
        </div>
      </div>

      {value && value.length > 1 && (
        <div>
          <span className={styles.size}>{value.length > 99 ? "99+" : value.length}</span>
        </div>
      )}
    </div>
  );

  const DropdownIcon = (): ReactNode => {
    if (arrowIconStyle == "dark") {
      return <DropdownIconDark />;
    }

    return !!value.length ? <DropdownIconBluePc /> : <DropdownIconPc />;
  };

  return (
    <div
      className={classNames(
        {
          [styles.wrapperSelect]: true,
          [styles.wrapperSelectActive]: open
        },
        wrapperClassName
      )}
    >
      <Select
        className={classNames({
          controlAntdSelect: true,
          [styles.overrideSelect]: true,
          [styles.openState]: true,
          activeLabel: !!value.length
        })}
        open={open}
        onDropdownVisibleChange={(visible) => setOpen(visible)}
        dropdownRender={(menu) => (
          <>
            <Board
              options={options}
              value={value}
              onChange={onChange}
              onCloseDropdown={onCloseDropdown}
              boardClearText={boardClearText}
              boardApplyFiltersText={boardApplyFiltersText}
              searchBoardPlaceholder={searchBoardPlaceholder}
              visibleSearch={visibleSearch}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              setSelectValue={setSelectValue}
              selectValue={selectValue}
              onMultipleChange={onMultipleChange}
              realTimeRefresh={realTimeRefresh}
              cascaderPanelProps={cascaderPanelProps}
            />
          </>
        )}
        getPopupContainer={(node) => node.parentElement}
        placement='bottomLeft'
        suffixIcon={DropdownIcon()}
        options={[]}
        {...rest}
        optionLabelProp='label'
        value={{
          value: "customValue",
          label: showWhereLabel ? whereLabel : customLabel
        }}
      />
    </div>
  );
};

export default MultiSelectPc;
