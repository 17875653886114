import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { shuffle } from "lodash-es";
import { formatTemplateString } from "helpers/formatter";
import Skeleton from "@mui/material/Skeleton";
import { Image as AntImage } from "antd";
import {
  personalSettingBg1,
  personalSettingBg2,
  personalSettingBg3,
  personalSettingBg4
} from "images";

import { convertClientToBackendLang } from "helpers/country.base";
import { getLang } from "helpers/country";
import { fetchRecruiterDetail } from "../../../../service";

import styles from "./index.module.scss";

const bgs = [personalSettingBg1, personalSettingBg2, personalSettingBg3, personalSettingBg4];
interface initProps {
  Id: number;
  setRecruiterId: (id: number | null) => void;
  overview: any;
}
export const Preview = ({ Id, setRecruiterId, overview }: initProps) => {
  const [preview, setPreview] = useState<Record<string, any>>({});
  const [src] = useState(() => {
    const a = shuffle(bgs);
    return a[0];
  });

  useEffect(() => {
    if (!Id) return;
    let loading = true;
    const lang = getLang();
    fetchRecruiterDetail(Id, convertClientToBackendLang(lang))
      .then((result) => {
        if (!loading) {
          return;
        }
        const detail = result;
        if (!detail) {
          return;
        }
        setPreview(detail);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setPreview({});
      loading = false;
    };
  }, [Id]);

  const { display_badges, company, avatar, response_rate, response_rate_time_defeat_percentile } =
    preview;
  const hasBadges = display_badges?.length > 0;
  // console.log('company_size:', company)
  return (
    <>
      <div className={styles.mask}></div>
      <div className={classNames(styles.rootCls, hasBadges ? styles.hasBadges : "")}>
        <span className={styles.closeIcon} onClick={() => setRecruiterId(null)}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='15'
            height='16'
            viewBox='0 0 15 16'
            fill='none'
          >
            <path
              d='M9.28833 7.9822L14.6329 2.81678C14.8677 2.58981 14.9997 2.28197 14.9997 1.96099C14.9997 1.64001 14.8677 1.33218 14.6329 1.10521C14.3981 0.878242 14.0795 0.750732 13.7474 0.750732C13.4153 0.750732 13.0968 0.878242 12.862 1.10521L7.5174 6.27063L2.17283 1.10521C2.05655 0.992827 1.9185 0.90368 1.76658 0.842859C1.61465 0.782037 1.45181 0.750732 1.28737 0.750732C1.12292 0.750732 0.960086 0.782037 0.808158 0.842859C0.65623 0.90368 0.518184 0.992827 0.401904 1.10521C0.285623 1.21759 0.193383 1.35101 0.130453 1.49785C0.0675221 1.64468 0.0351323 1.80206 0.0351323 1.96099C0.0351323 2.11993 0.0675221 2.2773 0.130453 2.42414C0.193383 2.57097 0.285623 2.70439 0.401904 2.81678L5.75021 7.9822L0.405643 13.1476C0.281265 13.2579 0.181242 13.3913 0.111586 13.5399C0.0419305 13.6885 0.00408087 13.8492 0.000312151 14.0123C-0.00345657 14.1754 0.0269342 14.3375 0.0896564 14.4889C0.152379 14.6404 0.246137 14.778 0.365297 14.8935C0.484457 15.0091 0.626558 15.1001 0.783057 15.1612C0.939556 15.2223 1.10722 15.2522 1.27598 15.2491C1.44473 15.246 1.61109 15.2099 1.76505 15.143C1.91901 15.0762 2.0574 14.9799 2.17189 14.8601L7.51647 9.69467L12.862 14.8601C12.9783 14.9725 13.1164 15.0616 13.2684 15.1224C13.4204 15.1832 13.5833 15.2145 13.7478 15.2144C13.9123 15.2144 14.0751 15.183 14.2271 15.1222C14.379 15.0613 14.5171 14.9721 14.6334 14.8596C14.7496 14.7472 14.8419 14.6137 14.9048 14.4668C14.9677 14.3199 15 14.1625 15 14.0035C15 13.8446 14.9675 13.6872 14.9045 13.5403C14.8415 13.3934 14.7492 13.26 14.6329 13.1476L9.28833 7.9822Z'
              fill='white'
            />
          </svg>
        </span>
        {Object.keys(preview).length > 0 && (
          <>
            <div className={styles.bannerWrapper}>
              <img src={src} className={styles.image} />
            </div>
            <div className={styles.bottomBorder}>
              <div className={styles.user}>
                <AntImage src={avatar} className={styles.image} />
                <div className={styles.infoWrapper}>
                  <div className={styles.userName}>{preview.full_name}</div>
                  <div className={styles.jobTitle}>{preview.job_title}</div>
                </div>
              </div>
              <div className={styles.sevenDay}>
                {formatTemplateString(
                  overview.The7DayResponse,
                  (response_rate || 0) + "%",
                  response_rate_time_defeat_percentile + "%"
                )}
              </div>
            </div>
            <div>
              {hasBadges && (
                <div className={classNames(styles.medal, styles.sectionWrapper)}>
                  <div className={styles.title}>{overview.medalHonor}</div>
                  <div className={styles.badgeWrapper}>
                    {display_badges
                      .filter((e) => e.logo)
                      .map((v) => {
                        return (
                          <div key={v.badge_id} className={styles.badgeItem}>
                            <img height={76} width={60} src={v.logo} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {Boolean(company) && (
                <div className={classNames(styles.sectionWrapper, styles.company)}>
                  <div className={styles.title}>Company</div>
                  <div className={styles.companyContent}>
                    <img src={company.logo_url} className={styles.companyLogo} />
                    <div style={{ flex: "1", width: 0 }}>
                      <span className={styles.companyName}>{company.name}</span>
                      <div className={styles.labelBox}>
                        {(company?.company_tags || []).map((key) => {
                          return (
                            <span className={styles.label} key={key}>
                              {key}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {Object.keys(preview).length === 0 && (
          <div
            style={{
              minHeight: "500px"
            }}
          >
            {new Array(20).fill(1).map(() => (
              <Skeleton animation='wave' height={26} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
