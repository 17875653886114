'use client'
import React from 'react'
import { formatTemplateString } from 'helpers/formatter'
import styled from '@emotion/styled'

const companyInfo = {
  ph: {
    companyNameKey: 'corporation'
  },
  id: {
    companyNameKey: 'IndonesiaPT'
  },
  sg: {
    companyNameKey: 'SingaporeYolo'
  },
  jp: {
    companyNameKey: 'JapanYolo'
  }
}

function pathContainsSpecPath(currentPath, specPath) {
  return specPath.some((item) => currentPath.includes(item))
}

const Copyright = (props: any) => {
  const { data, countryKey } = props

  const currentYear = new Date().getFullYear()
  const companyNameKey = companyInfo[countryKey]?.companyNameKey || companyInfo.sg.companyNameKey
  const companyName = data?.foot[companyNameKey] || ''
  const specPath = ['company/', 'new-job/']
  const currentPath = decodeURIComponent(typeof window !== 'undefined' ? location.pathname : '')
  const isSpecPath = pathContainsSpecPath(currentPath, specPath)
  let style = null
  if (isSpecPath) style = { paddingBottom: '70px' }
  if (currentPath.includes('/settings')) style = { display: 'none' }
  return (
    <CopyrightStyle style={style}>
      <div className='copyright_wrap'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='80'
          height='20'
          viewBox='0 0 80 20'
          fill='none'
        >
          <path
            d='M49.6736 19.9581C48.7158 19.9581 48.0699 19.8006 47.5798 19.6206L48.1592 17.1692C48.4885 17.2856 48.8346 17.3465 49.1835 17.3493C50.2518 17.3493 50.5643 16.6299 50.5643 15.2138V5.77808H53.8381V15.1271C53.8381 17.8245 52.813 19.9581 49.6736 19.9581Z'
            fill='white'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M61.2915 18.3311C65.195 18.3311 67.5543 15.448 67.5543 12.0925C67.5543 8.73697 65.1933 5.89624 61.2915 5.89624C57.3898 5.89624 55.0701 8.73886 55.0701 12.0925C55.0701 15.4461 57.4294 18.3311 61.2915 18.3311ZM61.2915 15.5338C59.3603 15.5338 58.2875 13.9423 58.2875 12.0915C58.2875 10.2408 59.3595 8.64929 61.2915 8.64929C63.2629 8.64929 64.3372 10.2417 64.3372 12.0915C64.3372 13.9413 63.2629 15.5338 61.2915 15.5338Z'
            fill='white'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M68.7006 0.79541H71.7695V6.19894H73.973C74.7643 6.19894 75.548 6.35549 76.2792 6.65968C77.0105 6.96387 77.6749 7.40974 78.2346 7.9718C78.7943 8.53386 79.2384 9.20112 79.5413 9.93551C79.8441 10.6699 79.9999 11.457 79.9999 12.2519C79.9999 13.8572 79.365 15.3967 78.2346 16.5319C77.1042 17.667 75.5713 18.3047 73.973 18.3047H68.7006V0.79541ZM71.7686 14.689V15.6271H73.5202L73.5194 15.6252C73.9674 15.6254 74.4109 15.5369 74.8246 15.3648C75.2387 15.1927 75.6145 14.9404 75.9314 14.6223C76.2481 14.3042 76.4993 13.9266 76.6708 13.5109C76.842 13.0953 76.9302 12.6498 76.9302 12.2C76.9302 11.2916 76.5706 10.4203 75.9312 9.77797C75.2914 9.13559 74.4241 8.77473 73.5194 8.77473H71.7695V14.689H71.7686Z'
            fill='white'
          />
          <path
            d='M18.6971 18.372C22.5347 18.372 24.8496 15.5381 24.8496 12.2418C24.8496 8.94536 22.5317 6.15316 18.6971 6.15316C14.8626 6.15316 12.5876 8.94384 12.5876 12.2418C12.5876 15.5397 14.9056 18.372 18.6971 18.372ZM18.6971 15.6229C16.8013 15.6229 15.7483 14.0595 15.7483 12.2418C15.7483 10.424 16.8013 8.85904 18.6971 8.85904C20.6359 8.85904 21.6889 10.424 21.6889 12.2418C21.6889 14.0595 20.6359 15.6214 18.6971 15.6214V15.6229ZM30.9165 18.372C34.1188 18.372 35.9731 16.807 35.9731 14.6516C35.9731 9.95833 29.0653 11.3953 29.0653 9.70548C29.0653 9.07026 29.7805 8.56302 30.8767 8.56302C32.3088 8.56302 33.6169 9.19824 34.3321 9.9167L35.5602 7.75817C34.224 6.70552 32.5715 6.13966 30.8736 6.15316C27.8035 6.15316 26.1149 7.84914 26.1149 9.8319C26.1149 14.3972 32.9842 12.8338 32.9842 14.7348C32.9842 15.4549 32.3517 15.9683 31.0456 15.9683C29.6132 15.9683 27.8849 15.165 27.0421 14.3617L25.7327 16.5464C26.9976 17.6951 28.9364 18.372 30.9165 18.372ZM41.9756 18.372C45.1776 18.372 47.0319 16.807 47.0319 14.6516C47.0319 9.95833 40.1244 11.3953 40.1244 9.70548C40.1244 9.07026 40.841 8.56302 41.9371 8.56302C43.3693 8.56302 44.6757 9.19824 45.3909 9.9167L46.619 7.75817C45.2856 6.70719 43.6368 6.1409 41.9416 6.15164C38.864 6.15164 37.1832 7.84762 37.1832 9.83038C37.1832 14.3956 44.0508 12.8323 44.0508 14.7333C44.0508 15.4533 43.4184 15.9667 42.112 15.9667C40.6799 15.9667 38.9516 15.1635 38.1087 14.3602L36.7933 16.5464C38.0152 17.6951 39.9523 18.372 41.9756 18.372Z'
            fill='white'
          />
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M0 0.79541H3.08522V6.22796H5.30027C6.09597 6.22796 6.88384 6.38536 7.61897 6.69122C8.3541 6.99705 9.02203 7.4453 9.58467 8.01043C10.1473 8.57554 10.5936 9.2464 10.8981 9.98477C11.2026 10.7231 11.3594 11.5145 11.3594 12.3137C11.3594 13.9277 10.721 15.4756 9.58467 16.6169C8.44839 17.7582 6.90723 18.3994 5.30027 18.3994H0V0.79541ZM3.08427 14.7639V15.7073H4.84536L4.84443 15.7054C5.29474 15.7055 5.74066 15.6165 6.15669 15.4435C6.57274 15.2705 6.95079 15.0168 7.26919 14.697C7.58762 14.3772 7.84017 13.9975 8.01243 13.5796C8.1847 13.1617 8.27332 12.7138 8.27318 12.2615C8.27318 11.3482 7.91194 10.4722 7.26891 9.82639C6.62591 9.18054 5.7538 8.8177 4.84443 8.8177H3.08522V14.7639H3.08427Z'
            fill='white'
          />
          <path
            d='M50.1555 2.75691C50.1555 3.8586 50.9594 4.67385 52.0677 4.67385C52.3203 4.68002 52.5718 4.63465 52.8065 4.54052C53.0414 4.44636 53.2545 4.30537 53.4333 4.12613C53.6121 3.94687 53.7527 3.73308 53.8467 3.49773C53.9408 3.26237 53.986 3.01033 53.9798 2.75691C53.9798 1.60656 53.1557 0.839966 52.0677 0.839966C50.9594 0.839966 50.1555 1.60656 50.1555 2.75691Z'
            fill='#FFC53D'
          />
        </svg>
        <span className='copyright'>
          {formatTemplateString(data?.foot['copyright'], currentYear, companyName)}
        </span>
      </div>
    </CopyrightStyle>
  )
}

export default Copyright

const CopyrightStyle = styled.div`
  padding: 20px 24px 16px 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background: #202021;
  .copyright_wrap {
    display: flex;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    .copyright {
      color: rgba(255, 255, 255, 0.5);
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 145.455% */
    }
  }
`
