import React, { useRef } from "react";
import { useContext, useState } from "react";

import { languageContext } from "app/components/providers/languageProvider";
import styles from "./index.module.scss";
import { useCompanyDetail } from "../../../DataProvider";
import { Filter } from "./Filter";
import { MyInput } from "../comm/Select/Select";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";
// import classNames from "classnames";
// import { fetchCompanyJobs } from "../../../../service";
import { getCompanyJobs, getIDFromKeyword } from "../../../service";
import { useParams } from "next/navigation";
// import { convertClientToBackendLang } from "helpers/country.base";
import { Loading } from "../CircleLoading/CircleLoading";

import defaultImg from "./default.png";
const NoDataSvg = () => {
  return <img width={160} height={160} src={defaultImg.src} />;
};

const FilterIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M4 8H12M2 4H14M6 12H10'
        stroke='#515151'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const OnlineIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
      <circle cx='5.88281' cy='5' r='4.5' fill='#34C759' stroke='white' />
    </svg>
  );
};

const Salary = ({ salaryType, salaryRange, typeSize = 16 }) => {
  //
  return (
    <>
      {salaryRange}
      {salaryType && (
        <span
          style={{
            color: "#000",
            fontSize: typeSize,
            letterSpacing: "0.12px",
            fontWeight: "500",
            lineHeight: "22px",
            marginLeft: 8
          }}
        >
          {`[${salaryType}]`}
        </span>
      )}
    </>
  );
};

const JobCard = ({ item, lang, overview }) => {
  const { text, type_text } = item.salary_info || {};
  const { full_name, avatar, job_title, is_online } = item?.recruiter || {};

  const href = "/" + lang + item.job_url + "?source=company_detail";
  const isOnLine = is_online;

  return (
    <a href={href} className={styles.jobItem}>
      <div className={styles.jobItemTitle}>{item.job_title}</div>
      {/*  */}
      <div className={styles.jobItemSalary}>
        <Salary salaryType={type_text} salaryRange={text} />
      </div>
      {/*  */}
      <div className={styles.jobItemTags}>
        {(item?.job_tags || []).map((v, index) => {
          return (
            <React.Fragment key={v}>
              {index !== 0 && <span className={styles.dot}></span>}
              <span>{v}</span>
            </React.Fragment>
          );
        })}
      </div>
      {/*  */}
      {/*  */}
      <div className={styles.horLine}></div>

      <div className={styles.jobItemBottomWrapper}>
        <div className={styles.jobItemAvatar}>
          <img src={avatar} />
          {isOnLine && (
            <span style={{ position: "absolute", right: -3, bottom: 0 }}>
              <OnlineIcon />
            </span>
          )}
        </div>

        <div style={{ display: "flex", flex: 1, overflow: "hidden" }}>
          <div className={styles.jobNameWrapper}>
            <div className={styles.jobItemRecruiterName}>{full_name}</div>
            <div className={styles.dot} />
            <div className={styles.jobItemRecruiterRole}>{job_title}</div>
          </div>
          {/* <div className={classNames(styles.statusRow, !isOnLine && styles.offline)}>
            {isOnLine ? <OnlineIcon /> : <OfflineIcon />}
            Online
          </div> */}
        </div>
      </div>
    </a>
  );
};

export const JobPage = () => {
  const { lang, companyPageData } = useCompanyDetail();
  const { company_jobs: jobPageData = {} } = companyPageData || {};
  const moreText = companyPageData.company_header?.more_text;
  const [jobs, setJobs] = useState(companyPageData?.company_jobs?.jobs || []);
  console.log("companyPageData", companyPageData);

  const params = useParams();

  const id = getIDFromKeyword(params.keyword as any);

  const contextLang = useContext(languageContext);
  const { overview } = contextLang.companyDetail;

  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);

  const [filter, setFilter] = useState({
    jobFunction: null,
    xpl: null,
    edu: null,
    jobType: null
  });

  const pageRef = useRef(1);
  const [totalPage, setTotalPage] = useState(companyPageData?.company_jobs?.total_page || 1);

  console.log("jobPageData", jobPageData);
  // console.log("companyPageData", companyPageData);

  const searchFunction = (newFilter, page = pageRef.current) => {
    setLoading(true);
    const reqData: any = {
      // companyIds: id,
      size: 10,
      page,
      query: query,
      job_function_id: newFilter.jobFunction,
      job_type_ids: newFilter.jobType,
      degree_ids: newFilter.edu,
      xp_lvl_ids: newFilter.xpl
    };

    if (!reqData.job_function_id) {
      delete reqData.job_function_id;
    }

    Object.keys(reqData).forEach((key) => {
      let value = reqData[key];
      if (value === null || value === undefined || value === "") {
        delete reqData[key];
      }
    });
    // convertClientToBackendLang(lang),
    getCompanyJobs({ companyId: id, params: reqData })
      .then((res: any) => {
        let { jobs = [], total_page = 1 } = res.data.data?.company_jobs || {};

        if (jobs.length) {
          setJobs((pre) => {
            return [...pre, ...jobs];
          });
        } else {
          setJobs([]);
        }
        pageRef.current = page;
        setTotalPage(total_page);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setJobs([]);
        pageRef.current = 0;
        setTotalPage(0);
      });
  };

  const filterNumber = Object.keys(filter)
    .map((key) => filter[key])
    .filter(Boolean).length;

  // const
  return (
    <div className={styles.jobPageRoot} style={{ backgroundColor: "#FDFDFD" }}>
      <div className={styles.topSearchRoot}>
        <MyInput
          value={query}
          onChange={(value) => {
            setQuery(value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              setJobs([]);
              searchFunction(filter, 1);
              event?.target?.blur?.();
            }
          }}
          placeholder={jobPageData.search_info?.input_text}
        />
        <div
          className={styles.filterBtn}
          onClick={() => {
            setOpenFilter(true);
          }}
        >
          <FilterIcon />
          {/* Filters */}
          {jobPageData?.filters_title}

          {Boolean(filterNumber) && <span className={styles.filterNumber}>{filterNumber}</span>}
        </div>
      </div>

      {Boolean(jobs.length) ? (
        <div>
          {jobs.map((item, index) => {
            return <JobCard key={index} item={item} lang={lang} overview={overview} />;
          })}
        </div>
      ) : (
        Boolean(!jobs.length && !loading) && (
          <div
            className={styles.emptyNode}
            style={{
              padding: "100px 0"
            }}
          >
            <NoDataSvg />
            No data
          </div>
        )
      )}

      {!Boolean(jobs.length) && !(totalPage > pageRef.current) && loading && (
        <div className={styles.emptyNode}>
          <Loading />
        </div>
      )}

      {/*  */}
      {totalPage > pageRef.current && (
        <div style={{ textAlign: "center", padding: "16px 0" }}>
          <Button
            variant='whiteBtn'
            btnSize='size40'
            loading={loading}
            onClick={(e) => {
              searchFunction(filter, pageRef.current + 1);
              setLoading(true);
            }}
          >
            {moreText}
            {/* More */}
          </Button>
        </div>
      )}
      <Filter
        open={openFilter}
        jobPageData={jobPageData}
        onClose={(value) => {
          if (value) {
            setJobs([]);
            setFilter(value);
            searchFunction(value, 1);
          }
          setOpenFilter(false);
        }}
        initValue={filter}
      />
    </div>
  );
};
