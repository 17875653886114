import configuredAxios from 'helpers/configuredAxios'


export const fetchFileUpload = (file, deviceId) => {
  const axios = configuredAxios('apiGo')
  const headers = {
    'Content-Type': 'multipart/form-data'
  }

  const formData = new FormData()
  formData.append('files', file)
  formData.append('filename', file.name)
  formData.append('did', deviceId)
  return axios.post(`/file/upload`, formData)
}


export const fetchFeedback = (params) => {
  const axios = configuredAxios('apiGo')
  return axios.post(`/user/feedback`, params)
}

export const fetchInterviewDot = () => {
  const axios = configuredAxios('apiGo')
  return axios.get(`/interview/red_dot`)
}

