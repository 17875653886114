import React from 'react'
import styled from '@emotion/styled'
import { message, Upload } from 'antd'
import type { UploadProps } from 'antd'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'

import { maxFileSize, useUploadResumeContext } from './context'
import UploadResumeWrap from './Wrap'

const { Dragger } = Upload

const UploadMain = (props: { lang: any; userDetail: any }) => {
  const { lang = {}, userDetail } = props
  const { uploadResumeFile, fileData } = useUploadResumeContext()
  const profile = lang.profile || {}
  // console.log('profile::', profile)

  const uploadProps: UploadProps = {
    name: 'file',
    accept: '.pdf, .doc, .docx',
    multiple: false,
    showUploadList: false,
    beforeUpload: (file) => {
      // console.log('beforeUpload', file)
      if (file.size > maxFileSize) {
        message.error(profile?.uploadResumeModal?.errorTip)
        return false
      }
      // return false;
    },
    customRequest: (options) => {
      // console.log('customRequest', options)
      const resumeId = userDetail?.resume?.id || fileData?.data?.id
      // console.log('resumeId', { userDetail, fileData, resumeId })
      uploadResumeFile({ ...options, resumeId: resumeId })
    }
    // onChange(info) {
    //   const { status } = info.file;
    //   console.log('info', status, info)
    // },
    // onDrop(e) {
    //   console.log('Dropped files', e.dataTransfer.files);
    // },
  }

  return (
    <UploadResumeWrap {...props}>
      <ContentWrap>
        <div className='modal-content'>
          <Dragger {...uploadProps}>
            <div className='main-file-info'>
              {/* <CloudUploadIcon /> */}
              <Button variant='primaryBtn' btnSize='size32' className='main-btn-upload'>
                {profile?.uploadResumeModal?.uploadBtn}
              </Button>
              <div className='main-upload-desc'>{profile?.uploadResumeModal?.uploadDesc}</div>
            </div>
          </Dragger>
        </div>
      </ContentWrap>
    </UploadResumeWrap>
  )
}
const media = {
  sm: `@media (max-width: 576px)`,
  md: `@media (min-width: 800px)`,
  msd: `@media (max-width: 799px)`
}
const ContentWrap = styled.div`
  .modal-content {
    padding-top: 49px;
    text-align: center;
    padding-bottom: 20px;
    ${media.msd} {
      padding-top: 24px;
      padding-bottom: 0;
    }
  }

  .main-file-info {
    display: flex;
    padding: 43px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    ${media.msd} {
      padding: 33px 0;
    }
    .main-btn-upload {
    }
    .main-upload-desc {
      margin-top: 8px;
      color: #646566;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .progress-file-info {
    display: flex;
    margin-top: 32px;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px dashed #e4e4e4;
    background: #fcfcfc;
  }

  .progress-file-progress {
    width: 50%;
    margin: 24px 0;
  }

  .progress-file-message {
    color: #7d7d7d;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export default UploadMain
