"use client";
import React, { useState } from "react";

import styles from "../../styles/index.mobile.module.scss";

import JobCardMobile from "./CardList/JobCard.mobile";
import { getCookie } from "helpers/cookies";

interface JobsHiringMobileProps {
  langKey: string;
  pageData: any;
  device_udid?: string;
}

const JobsHiringMobile = (props: JobsHiringMobileProps) => {
  const langKey = props?.langKey;
  const pageData = props?.pageData || {};
  const jobsData = pageData?.recommend_jobs || {};
  const sessionid = getCookie("sessionid");

  const [jobs, setJobs] = useState<Array<any>>(jobsData.jobs || []);

  const handleLoadMore = () => {
    if (sessionid) {
      const title_seo = jobsData?.job_preference_title_seo;

      const url = title_seo
        ? `/${langKey}/jobs-hiring/${title_seo}-jobs`
        : `/${langKey}/jobs-hiring/job-search`;

      window.location.href = url;
    } else {
      window.location.href = `/${langKey}/get-started`;
    }
  };

  const handleClick = () => {
    let url = "";
    if (sessionid) {
      url = `https://app.adjust.com/1jn6gfqo`;
    } else {
      url = `/${langKey}/get-started`;
    }
    window.location.href = url;
  };

  return (
    <section className={styles.jobsHiring}>
      <div className={styles.jobsHiringBox}>
        <div className={styles.jobsHiringTop}>
          <h2 className={styles.jobsHiringTopTitle}>{jobsData?.title}</h2>
          <div className={styles.jobsHiringTopDesc}>
            {jobsData?.recommend_text}{" "}
            <span className={styles.jobsHiringTopLink} onClick={handleClick}>
              {jobsData?.recommend_highlight_text}
            </span>
          </div>
        </div>
        <div className={styles.jobsHiringContent}>
          {jobs && jobs?.length > 0
            ? jobs?.map((job: any, index: number) => {
                return <JobCardMobile key={job?.job_id} job={job} langKey={langKey} />;
              })
            : null}
        </div>
        <div className={styles.jobsHiringFooter}>
          {jobsData?.more_text && (
            <div className={styles.jobsHiringFooterMore} onClick={handleLoadMore}>
              {jobsData?.more_text}{" "}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='17'
                height='16'
                viewBox='0 0 17 16'
                fill='none'
              >
                <path
                  d='M5.16602 11.3334L11.8327 4.66675M11.8327 4.66675H5.16602M11.8327 4.66675V11.3334'
                  stroke='black'
                  strokeWidth='1.33333'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default JobsHiringMobile;
