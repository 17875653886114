'use client'
import React from 'react'
import styles from './index.module.scss'

const Refresh = (props) => {
  const { lang = {} } = props

  const onRefresh = () => {
    window.location.reload()
  }

  return (
    <>
      <span className={styles.refresh} onClick={() => onRefresh()}>
        {lang?.refresh || 'Refresh'}
      </span>
    </>
  )
}

export default Refresh
