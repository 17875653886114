import React from "react";
import styles from "../../../styles/index.pc.module.scss";
import networkErrorImage from "../../../images/network-error.png";

const NetworkError = ({ onClick }) => {
  return (
    <div className={styles.cardNetworkError}>
      <div className={styles.cardNetworkErrorContent}>
        <div className={styles.cardNetworkErrorIcon}>
          <img src={networkErrorImage.src} alt='' />
        </div>
        <span className={styles.cardNetworkErrorText}>Network Error</span>
        <button className={styles.cardNetworkErrorButton} onClick={onClick}>
          Try Again
        </button>
      </div>
    </div>
  );
};

export default NetworkError;
