export const ArrowSvg = (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.49408 3.57742C6.81951 3.25198 7.34715 3.25198 7.67259 3.57742L13.5059 9.41075C13.8314 9.73619 13.8314 10.2638 13.5059 10.5893L7.67259 16.4226C7.34715 16.748 6.81951 16.748 6.49408 16.4226C6.16864 16.0972 6.16864 15.5695 6.49408 15.2441L11.7382 10L6.49408 4.75593C6.16864 4.4305 6.16864 3.90286 6.49408 3.57742Z" fill="#B8B8B8" />
</svg>)

export const CompanySafeSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M6.38774 0.599687C6.72792 0.266495 7.27208 0.266494 7.61226 0.599686L8.8113 1.77409C8.9726 1.93208 9.18872 2.0216 9.41449 2.02394L11.0928 2.04136C11.5689 2.0463 11.9537 2.43108 11.9586 2.90723L11.9761 4.58551C11.9784 4.81128 12.0679 5.0274 12.2259 5.1887L13.4003 6.38774C13.7335 6.72792 13.7335 7.27208 13.4003 7.61226L12.2259 8.81131C12.0679 8.9726 11.9784 9.18872 11.9761 9.41449L11.9586 11.0928C11.9537 11.5689 11.5689 11.9537 11.0928 11.9586L9.41449 11.9761C9.18872 11.9784 8.9726 12.0679 8.81131 12.2259L7.61226 13.4003C7.27208 13.7335 6.72792 13.7335 6.38774 13.4003L5.1887 12.2259C5.0274 12.0679 4.81128 11.9784 4.58551 11.9761L2.90723 11.9586C2.43109 11.9537 2.0463 11.5689 2.04136 11.0928L2.02394 9.41449C2.0216 9.18872 1.93208 8.9726 1.77409 8.81131L0.599687 7.61226C0.266495 7.27208 0.266494 6.72792 0.599686 6.38774L1.77409 5.1887C1.93208 5.0274 2.0216 4.81128 2.02394 4.58551L2.04136 2.90723C2.0463 2.43109 2.43108 2.0463 2.90723 2.04136L4.58551 2.02394C4.81128 2.0216 5.0274 1.93208 5.1887 1.77409L6.38774 0.599687Z" fill="url(#paint0_linear_1879_17177)" />
    <path d="M4.57812 6.72842L6.35871 8.51865L9.41737 5.48047" stroke="white" stroke-width="1.3125" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient id="paint0_linear_1879_17177" x1="-2.2096e-07" y1="3.76923" x2="14.9227" y2="5.36348" gradientUnits="userSpaceOnUse">
        <stop stop-color="#2378E5" />
        <stop offset="1" stop-color="#005ACD" />
      </linearGradient>
    </defs>
  </svg>
)

export const JobTitleSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M11.25 2.8V4.1H12.55C13.627 4.1 14.5 4.97304 14.5 6.05V12.55C14.5 13.627 13.627 14.5 12.55 14.5H3.45C2.37305 14.5 1.5 13.627 1.5 12.55V6.05C1.5 4.97304 2.37305 4.1 3.45 4.1H4.75V2.8C4.75 2.08203 5.33203 1.5 6.05 1.5H9.95C10.668 1.5 11.25 2.08203 11.25 2.8ZM6.05 6.7H4.75V5.4H3.45C3.09102 5.4 2.8 5.69101 2.8 6.05L2.8 9.3H13.2V6.05C13.2 5.69102 12.909 5.4 12.55 5.4H11.25V6.7H9.95V5.4H6.05V6.7ZM13.2 10.6H2.8L2.8 12.55C2.8 12.909 3.09101 13.2 3.45 13.2H12.55C12.909 13.2 13.2 12.909 13.2 12.55V10.6ZM9.95 4.1V2.8L6.05 2.8V4.1H9.95Z" fill="#B8B8B8" />
  </svg>
)

export const SalarySvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect width="16" height="16" fill="white" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0563 1.28523C10.3752 1.10113 10.7829 1.21038 10.967 1.52925L12.3824 3.98079C12.5015 4.18717 12.5015 4.44145 12.3822 4.64777C12.2629 4.85408 12.0426 4.98105 11.8043 4.98079L6.15332 4.97465C5.85172 4.97432 5.5879 4.77155 5.51 4.48018C5.4321 4.18881 5.55952 3.88143 5.82071 3.73063L10.0563 1.28523ZM8.63742 3.64401L10.6496 3.6462L10.1456 2.77326L8.63742 3.64401Z" fill="#B8B8B8" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.66732 4.98419V13.4707H13.334V4.98419H2.66732ZM1.33398 4.93126C1.33398 4.23841 1.88695 3.65086 2.60065 3.65086H13.4007C14.1144 3.65086 14.6673 4.23841 14.6673 4.93126V13.5236C14.6673 14.2164 14.1144 14.804 13.4007 14.804H2.60065C1.88695 14.804 1.33398 14.2164 1.33398 13.5236V4.93126Z" fill="#B8B8B8" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.1752 9.22742C9.1752 8.00164 10.1822 7.02641 11.4014 7.02641H14.0007C14.3689 7.02641 14.6674 7.32489 14.6674 7.69308V10.7618C14.6674 11.13 14.3689 11.4284 14.0007 11.4284H11.4014C10.1822 11.4284 9.1752 10.4532 9.1752 9.22742ZM11.4014 8.35975C10.898 8.35975 10.5085 8.75843 10.5085 9.22742C10.5085 9.69641 10.898 10.0951 11.4014 10.0951H13.334V8.35975H11.4014Z" fill="#B8B8B8" />
  </svg>
)