import { ReactNode } from 'react'

interface RIfProps {
  show: boolean // 条件为布尔值
  children: ReactNode // 子节点可以是任意有效的 React 内容
}

export default function RIf({ show, children }: RIfProps) {
  return show ? children : null
}
