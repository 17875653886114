import Link from "components/Link";

import styles from "./index.module.scss";
import { ChatAndApplyBtn } from "../ChatAndApplyBtn";

const DotIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='2' height='2' viewBox='0 0 2 2' fill='none'>
      <circle cx='1.25' cy='1' r='0.75' fill='#515151' />
    </svg>
  );
};
const ViewAllIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
      <path
        d='M4.66663 11.3334L11.3333 4.66669M11.3333 4.66669H4.66663M11.3333 4.66669V11.3334'
        stroke='black'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

const Salary = ({ salaryType, salaryRange, typeSize = 14 }) => {
  return (
    <>
      <span
        style={{
          color: "#1C70EE",
          fontSize: 20,
          fontWeight: 700,
          lineHeight: "28px",
          marginRight: 8
        }}
      >
        {salaryRange}
      </span>
      {salaryType && (
        <span
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "24px"
          }}
        >
          {`[${salaryType}]`}
        </span>
      )}
    </>
  );
};

function isElementInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  );
}

export const HotJobItem = ({ item, lang, overview, companyLogo }) => {
  return (
    <Link href={`/${lang}${item.job_url}?source=company_detail`} target={"_blank"}>
      <div className={styles.hotJobCardRoot}>
        <div className={styles.hotJobTitle}>{item.job_title}</div>
        <div className={styles.salaryWrapper}>
          <Salary salaryType={item.salary_info.type_text} salaryRange={item.salary_info.text} />
          <div className={styles.applyBtnWrapper}>
            <ChatAndApplyBtn />
          </div>
        </div>
        <div className={styles.jobTags}>
          {item.job_tags.map((v, i) => {
            return (
              <>
                {/* {Boolean(i) && <DotIcon />} */}
                <span className={styles.tagItem} key={v}>
                  {v}
                </span>
              </>
            );
          })}
        </div>
      </div>
    </Link>
  );
};

export const HotJobs = ({ hotJobData, lang, overview, headerData, setTabValue }) => {
  const companyLogo = headerData?.logo_url;
  const { hot_jobs: hotJobs } = hotJobData;

  if (!hotJobs?.length) {
    return null;
  }

  return (
    <div className={styles.hotJobs}>
      {hotJobs.slice(0, 3).map((item, index) => {
        return (
          <HotJobItem
            key={index}
            item={item}
            lang={lang}
            overview={overview}
            companyLogo={companyLogo}
          />
        );
      })}
      <span
        className={styles.viewAllBtn}
        style={{ marginTop: 16 }}
        onClick={() => {
          setTabValue(3);
          let holder = document.getElementById("viewHolder");
          if (holder && !isElementInViewport(holder)) {
            holder.scrollIntoView({ behavior: "smooth" });
          }
        }}
      >
        {hotJobData.view_all_text}
        <ViewAllIcon />
      </span>
    </div>
  );
};
