import { HTMLProps, useCallback, useRef, useState } from "react";

import styles from "./index.module.scss";
import classNames from "classnames";
import { AutoComplete, Input } from "antd";
import { fetchCompanySuggestionsService } from "store/services/companies2/fetchCompanySuggestions";
import _ from "lodash";

export const SearchCardH5Root = (props: HTMLProps<HTMLDivElement>) => {
  const { className, ...rest } = props;

  return <div className={classNames(styles.searchCardH5Root, className)} {...rest} />;
};

const SearchIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.0668 21.0499L10.252 15.6333L11.6014 14.4337L16.4163 19.8504L15.0668 21.0499Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.31387 5.1028C4.48853 5.1028 2.19813 7.39319 2.19813 10.2185C2.19813 13.0439 4.48853 15.3343 7.31387 15.3343C10.1392 15.3343 12.4296 13.0439 12.4296 10.2185C12.4296 7.39319 10.1392 5.1028 7.31387 5.1028ZM0.392578 10.2185C0.392578 6.39601 3.49135 3.29724 7.31387 3.29724C11.1364 3.29724 14.2352 6.39601 14.2352 10.2185C14.2352 14.0411 11.1364 17.1398 7.31387 17.1398C3.49135 17.1398 0.392578 14.0411 0.392578 10.2185Z'
        fill='white'
      />
      <path
        d='M14.2949 5.13568C16.6538 5.13568 18.0691 3.41219 18.0691 0.950073C18.0691 3.41219 19.7203 5.13568 21.6074 5.13568C19.7203 5.13568 18.0691 6.61295 18.0691 9.07507C18.0691 6.61295 16.6538 5.13568 14.2949 5.13568Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.7613 3.6981C17.3311 3.04932 17.6629 2.10563 17.6629 0.950073H18.4754C18.4754 3.239 19.9926 4.72943 21.6074 4.72943V5.54193C19.9609 5.54193 18.4754 6.82074 18.4754 9.07507H17.6629C17.6629 7.92068 17.3321 7.04649 16.7732 6.46313C16.2165 5.88198 15.3878 5.54193 14.2949 5.54193V4.72943C15.3686 4.72943 16.1968 4.34088 16.7613 3.6981ZM16.2331 5.11169C16.6619 5.30386 17.0405 5.56762 17.3599 5.90104C17.6644 6.21886 17.9075 6.59188 18.0879 7.01222C18.4855 6.14363 19.1346 5.49274 19.9179 5.1127C19.131 4.68849 18.4845 3.97194 18.0886 3.06283C17.9101 3.49983 17.6706 3.89406 17.3718 4.23425C17.0517 4.59878 16.6693 4.89438 16.2331 5.11169Z'
        fill='white'
      />
    </svg>
  );
};

export const SearchInput = (props: {
  placeholder?: string;
  value: string;
  onSearch: any;
  onChange: any;
}) => {
  const { value, onChange, onSearch, placeholder } = props;
  const [loading, setSearchLoading] = useState(false);
  const [list, setSuggestionList] = useState([]);
  const queryRef = useRef("");
  const [open, setOpen] = useState(false);

  const wrapperSearch = (value) => {
    onSearch(value);
    setOpen(false);
  };

  const handleSearch = useCallback(
    _.debounce(() => {
      setSearchLoading(true);
      fetchCompanySuggestionsService({ size: 5, query: queryRef.current })
        .then((data) => {
          let list = (data.data?.data?.items_new || []).map((v) => {
            return {
              label: v.name,
              value: v.name,
              logo: v.logo_url
            };
          });
          setSuggestionList(list);
        })
        .finally(() => setSearchLoading(false));
    }, 500),
    []
  );
  const handleKeyDown = (event) => {
    let target = event.target;
    if (event.key === "Enter" || event.keyCode === 13) {
      event.preventDefault();
      clickRef.current = true;

      console.log("event", event);
      wrapperSearch(queryRef.current);
    }
  };
  const clickRef = useRef(false);

  return (
    <div
      // style={{ position: "relative", display: "flex", borderRadius: 12, backgroundColor: "#1C70EE" }}
      className={styles.autoCompleteRoot}
    >
      <AutoComplete
        value={value}
        popupClassName={styles.popupRoot}
        onDropdownVisibleChange={(visible) => {
          setTimeout(() => {
            if (visible && clickRef.current) {
              return;
            }
            setOpen(visible);
          }, 0);
        }}
        open={open}
        className={styles.inputH5Root}
        options={list.map((v) => {
          return {
            value: v.value,
            label: (
              <div className={styles.popupItem}>
                <img className={styles.logo} src={v.logo} />
                {v.value}
              </div>
            )
          };
        })}
        onSelect={(value) => {
          onChange(value);
          wrapperSearch(value);
        }}
        style={{ width: "100%", height: 56 }}
      >
        <Input
          placeholder={placeholder}
          onChange={(e) => {
            let value = e.target.value;
            queryRef.current = e.target.value;
            handleSearch();
            onChange(value);
          }}
          onKeyDown={handleKeyDown}
        />
      </AutoComplete>
      <span
        className={styles.searchBtn}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          clickRef.current = true;
          setTimeout(() => {
            clickRef.current = false;
          }, 0);
          setOpen(false);

          wrapperSearch(queryRef.current);
        }}
      >
        <SearchIcon /> <span className={styles.text}>Search</span>
      </span>
    </div>
  );
};
