"use client";
import React from "react";
import { postNotification } from "bossjob-remote/dist/clientStorage";
import styles from "../../styles/index.pc.module.scss";
import { getCookie } from "helpers/cookies";
import { addEventTag } from "helpers/utilities";
import { Button } from "app/(new-pages)/components/Button/pc/index.pc";

const LinkMoreClient = ({ data, langKey }) => {
  const sessionid = getCookie("sessionid");

  const toLink = () => {
    addEventTag("hp_top_companies_more_click");
    if (sessionid) {
      window.open(`/${langKey}/companies`);
      return;
    } else {
      postNotification("SHOW_LOGIN_MODAL");
      return "/get-started";
    }
  };
  return (
    <div className={styles.companiesMore}>
      <Button variant='grayBtn' btnSize='size36' onClick={toLink}>
        {data?.more_text}
      </Button>
    </div>
  );
};

export default LinkMoreClient;
