import styles from "./index.module.scss";

import NoDataImg from "../../../noData.png";

export const NoData = ({ text = "NO data" }) => {
  //
  return (
    <div className={styles.emptyNode}>
      {/* <NoDataSvg /> */}
      <img src={NoDataImg.src} width={160} height={160} />
      <div className={styles.noDataText}>{text}</div>
    </div>
  );
};
