import { googleStoreLink, appStoreLink } from "helpers/adjustUrlList";
import Image from "next/image";
import Link from "components/Link";

import styles from "../../styles/index.pc.module.scss";

import qrcodeImage from "../../images/qrcode.png";
import appStoreImage from "../../images/apple-store.png";
import googlePlayImage from "../../images/google-icon.png";
import tipImage from "../../images/tip-cover.png";
import tipImageJp from "../../images/tip-cover-jp.png";
import { getCountryKey } from "helpers/country";

const tipImages = {
  jp: tipImageJp,
  default: tipImage
};

const PopoverDownload = (props: any) => {
  const { search_info, onMouseEnter = () => {}, onMouseLeave = () => {}, show } = props;
  const { get_the_dice_app, you_must_install_app } = search_info;
  const countryKey = getCountryKey();
  const currentTipImage = tipImages[countryKey] || tipImages.default;

  return (
    <div
      className={styles.popover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ height: show ? "250px" : "0" }}
    >
      <div className={styles.popoverContainer}>
        <div className={styles.popoverMain}>
          <div className={styles.info}>
            <div className={styles.qrcode}>
              <img src={qrcodeImage.src} alt='' />
            </div>
            <div className={styles.getAppInfo}>
              <h5 className={styles.getApp}>{get_the_dice_app}</h5>
              <p className={styles.getAppTips}>{you_must_install_app}</p>
              <div className={styles.appStore}>
                <Link to={appStoreLink} external>
                  <Image src={appStoreImage.src} alt='AppStore' width={104} height={36} />
                </Link>
                <Link to={googleStoreLink} external>
                  <Image src={googlePlayImage.src} alt='GooglePlay' width={104} height={36} />
                </Link>
              </div>
            </div>
          </div>
          <div className={styles.coverDefault}>
            <img src={currentTipImage.src} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopoverDownload;
