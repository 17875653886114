import configuredAxios from "helpers/configuredAxios"
import { cache } from 'react'

export const fetchHomePageSEO = cache(async ({ lang }) => {
  const axios = await configuredAxios('apiGo')
  return axios.get(`/web/home_page_seo?lang=${lang}`)
})

export const fetchHomePage = cache(async ({ lang, device_udid }) => {
  const axios = await configuredAxios('apiGo')
  const params = { lang, device_udid }
  return axios.get(`/web/home_page_v3`, { params })
})

export const fetchMainFunction = cache(async ({ lang }) => {
  const axios = await configuredAxios('apiGo')
  return axios.get(`/web/main_function?lang=${lang}`)
})

export const fetchJobs = cache(async (params: {
  main_job_function_id?: any;
  job_preferences_id?: any;
  is_remote?: boolean;
  recommend_tab_id?: any;
}) => {
  const axios = await configuredAxios('apiGo')
  return axios.get(`/web/home_page_jobs_v3`, { params })
})

export const fetchJobDetail = cache(async ({ job_id }) => {
  const axios = await configuredAxios('apiGo')
  return axios.get(`/web/simple_job_detail/${job_id}`)
})


export async function zipLocation(params) {

  // console.log('zipLocation length', params?.location?.length)

  if (params?.location?.length > 6) {
    try {
      const [l1, l2, l3, ...rest] = params.location
      const axios = configuredAxios('apiGo')
      const zippedKey = await axios.post('/url_short/location', { location_seo_values: rest })
        .then(res => {
          return res.data.data?.short_code
        })

      const newParams = {
        ...params,
        location: [l1, l2, l3],
        locationKey: zippedKey
      }
      return newParams
    } catch (error) {
      console.log("zip location error:", error)
      return params
    }

  }
  return params
}

// export const fetchJobTabData = cache(async ({ main_job_function_id }) => {
//   const axios = await configuredAxios('apiGo')
//   const params = { main_job_function_id }
//   return axios.get(`/web/home_page_jobs_v3`, { params })
// })

export const fetchHomePageForMobile = async ({ lang, device_udid }) => {
  const axios = await configuredAxios('apiGo')
  const params = { lang, device_udid }
  return axios.get(`/web/home_page_h5`, { params })
}

export const fetchJobDataForMobile = async ({
  lang,
  device_udid,
  size,
  page,
  job_preferences_id
}: {
  lang: string
  device_udid: string
  size: number
  page: number
  job_preferences_id?: number
}
) => {
  const axios = await configuredAxios('apiGo')
  const params = { lang, device_udid, size, page, job_preferences_id }
  return axios.get(`/web/home_page_h5_jobs`, { params: params })
}