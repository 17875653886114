"use client";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../../styles/index.pc.module.scss";
import Loading from "./Loading";
import { JobTransProvider } from "app/(new-pages)/components/JobDetail/jobTransProvider";
import { getCountryKey } from "helpers/country";
import JobDetail from "app/(new-pages)/components/JobDetail/HomeJobDetail/JobDetail.pc";
import NetworkError from "./NetworkError";

import { fetchJobDetail } from "../../../services/index";
import { addEventTag } from "helpers/utilities";
import JobCardPc from "./JobCard.pc";

const googleTag = ["hp_job_for_you_card_click", "hp_popular_jobs_card_click"];

const Card = ({ jobs, id, sessionid }) => {
  const [detail, setDetail] = useState<any>(jobs[0]?.job_detail || {});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const countryKey = getCountryKey();
  const [error, setError] = useState(false);
  const detailRef = useRef(null);

  // console.log("Card@jobs:", { jobs, id, sessionid, detail });

  useEffect(() => {
    if (id) {
      setCurrentIndex(0);
      setDetail(jobs[0]?.job_detail || {});
      setError(!jobs[0]?.job_detail);
    }
  }, [jobs]);

  function handleScroll() {
    var headerOffset = 100;
    var elementPosition = detailRef.current?.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    const contentEl = detailRef.current.querySelector(".job-main-inner");

    contentEl.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  const fetchDetail = async (job_id) => {
    // console.log("fetchDetail job_id:", job_id);
    try {
      setLoading(true);
      const res = await fetchJobDetail({ job_id });
      // console.log("fetchDetail res:", res.data.data);
      setLoading(false);
      if (res?.data?.code == 0) {
        setDetail(res.data.data);
        setError(false);
      } else {
        setError(true);
      }
    } catch (error) {
      // console.log("fetchDetail error:", error);
      setLoading(false);
      setError(true);
    }
  };

  const changeDetail = (e, index) => {
    e.stopPropagation();
    // console.log("changeDetail", { e, index });
    const gtagEventName = sessionid ? googleTag[0] : googleTag[1];
    addEventTag(gtagEventName);

    fetchDetail(jobs[index].job_id);
    setCurrentIndex(index);
    handleScroll();
  };

  return (
    <JobTransProvider data={detail}>
      <div className={styles.content}>
        <div className={styles.list}>
          {jobs?.map((item, index) => {
            return (
              <JobCardPc
                key={item?.job_id}
                item={item}
                index={index}
                currentIndex={currentIndex}
                changeDetail={changeDetail}
              />
            );
          })}
        </div>
        <div className={styles.jobDetail}>
          {loading ? (
            <Loading />
          ) : error ? (
            <NetworkError onClick={() => fetchDetail(jobs[currentIndex]?.job_id)} />
          ) : (
            <JobDetail
              ref={detailRef}
              countryKey={countryKey}
              sessionid={sessionid}
              jobId={detail?.job_id}
              data={detail || {}}
            />
          )}
        </div>
      </div>
    </JobTransProvider>
  );
};

export default Card;
