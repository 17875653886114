'use client'
import React from 'react'
import styles from './index.module.scss'
import { pushToResume } from 'helpers/push'
import { Button } from 'app/(new-pages)/components/Button/pc/index.pc'
import resume from './resume.png'

const ExcellentResumeBanner = ({ advertisingLink }) => {
  const handleClick = () => {
    pushToResume()
  }

  return (
    <>
      <div className={styles.container} onClick={handleClick}>
        <div className={styles.excellentResume}>{advertisingLink.excellentResume}</div>
        <div className={styles.openTheDoorToSuccess}>{advertisingLink.openTheDoorToSuccess}</div>
        <img src={resume.src} />
        <div className={styles.button}>
          <Button btnSize='size40' variant='blackBtn' style={{ borderRadius: '12px' }}>
            {advertisingLink.createMyResume}
          </Button>
        </div>
      </div>
    </>
  )
}
export default ExcellentResumeBanner
