import React, { useState } from 'react'
import { getLanguageId } from 'helpers/country'
import { S3BucketJobseeker } from 'images'
import { ChangeLangAndRegion } from '../Common/ChangeLangAndRegion'

/* Style */
import styles from '../Header.module.scss'
import { languages } from 'helpers/country.base'
import flagInterIcon from 'images/flags/flag_inter.png'
import { getFlagByKey } from 'helpers/flag'

const countryLanguage = ({ countryKey }) => {
  const languageId = getLanguageId()
  const [show, setShow] = useState(false)

  const languageSlug = languages.find((item) => item.id === languageId)?.slug
  const click = () => {
    setShow(true)
  }
  const countryFlagIcon = getFlagByKey(countryKey)

  return (
    <>
      <span
        onClick={click}
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40 }}
      >
        {/* <Flag /> */}
        <span
          style={{
            display: 'flex',
            width: 16,
            height: 16,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            borderRadius: '50%',
            backgroundImage: `url(${countryFlagIcon})`,
            marginRight: 8
            // border: '1px solid #f6f6f6'
          }}
        />
        {/* <span className={styles.separator} style={{ padding: '0 8px' }}>
          |
        </span> */}
        <span className={styles.label}>{languageSlug}</span>
      </span>

      <ChangeLangAndRegion
        show={show}
        onAction={({ show }) => {
          setShow(show)
        }}
      />
    </>
  )
}

export default countryLanguage
