import FeedBack from "components/Footer/components/PC/FeedBack";
import styles from "./index.module.scss";
import { useState } from "react";
import { useCompanyDetail } from "../../../DataProvider";
import { getLanguageCode } from "helpers/country";

import { useSharedData } from "bossjob-remote/dist/hooks";
import { getCookie } from "helpers/cookies";

import { postNotification } from "bossjob-remote/dist/clientStorage";

const ReminderIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <path
        d='M11.4885 23.8455C11.642 23.945 11.8188 23.9984 11.9993 23.9997C12.1799 23.9984 12.3567 23.945 12.5102 23.8455C12.6772 23.7427 16.4789 21.2759 17.805 20.2481C19.08 19.2664 20.1247 17.994 20.8601 16.5273C21.1412 15.9307 21.3017 15.2799 21.3317 14.6155V5.1902C21.3416 4.96348 21.2795 4.7397 21.1551 4.55377C21.0307 4.36783 20.851 4.23021 20.644 4.16236L12.2941 1.38719C12.1037 1.31495 11.895 1.31495 11.7046 1.38719L3.35469 4.16236C3.14769 4.23021 2.96798 4.36783 2.84359 4.55377C2.7192 4.7397 2.65712 4.96348 2.66704 5.1902V14.6463C2.70128 15.3002 2.86168 15.94 3.13857 16.5273C3.87595 17.9926 4.92034 19.2645 6.19367 20.2481C7.55914 21.317 11.3215 23.7427 11.4885 23.8455Z'
        fill='#1C70EE'
      />
      <path
        d='M10.8977 15.9999C10.9019 15.9999 10.9061 16 10.9103 16H10.8899L10.8977 15.9999Z'
        fill='white'
      />
      <path
        d='M10.5117 15.9179C10.6337 15.9699 10.7649 15.9977 10.8977 15.9999C11.0353 15.9971 11.171 15.9668 11.2965 15.9106C11.4244 15.8534 11.5389 15.7706 11.6329 15.6675L17.3327 9.34005L15.806 8L10.8492 13.5013L8.10113 10.6297L6.66602 12.0101L10.1775 15.6877C10.2721 15.7859 10.3857 15.8642 10.5117 15.9179Z'
        fill='white'
      />
    </svg>
  );
};

const DotIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4' fill='none'>
      <circle cx='2' cy='2' r='1.25' fill='#ACAEB1' />
    </svg>
  );
};

const Flag = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'>
      <path
        d='M2.33337 8.75002C2.33337 8.75002 2.91671 8.16669 4.66671 8.16669C6.41671 8.16669 7.58337 9.33335 9.33337 9.33335C11.0834 9.33335 11.6667 8.75002 11.6667 8.75002V2.33335C11.6667 2.33335 11.0834 2.91669 9.33337 2.91669C7.58337 2.91669 6.41671 1.75002 4.66671 1.75002C2.91671 1.75002 2.33337 2.33335 2.33337 2.33335M2.33337 12.8334L2.33337 1.16669'
        stroke='#7D7D7D'
        strokeWidth='1.05'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export const Reminder = () => {
  const [openForm, setOpenForm] = useState({
    open: false,
    key: 0
  });
  const { companyPageData } = useCompanyDetail();
  const about = companyPageData.company_about;

  const onOpenClick = () => {
    if (getCookie("sessionid")) {
      setOpenForm({
        open: true,
        key: Math.random()
      });
    } else {
      postNotification("SHOW_LOGIN_MODAL");
    }
  };

  const data = [
    about.imm_text_one,
    about.imm_text_two,
    about.imm_text_three,
    about.imm_text_four,
    about.imm_text_five,
    about.imm_text_six
  ].filter(Boolean);

  const langDic = useSharedData("DICTIONARY") || {};
  const langKey = getLanguageCode();
  let FB = FeedBack as any;

  return (
    <>
      <div>
        {/* <div style={{ backgroundColor: "#fff", paddingBottom: 12, borderRadius: "0 0 16px 16px" }}>
          <div
            className={styles.reportBtn}
            onClick={(e) => {
              onOpenClick();
            }}
          >
            <Flag />
            {about.report_title}
          </div>
        </div> */}

        <div style={{ padding: 12, borderRadius: 16, backgroundColor: "#fff" }}>
          <div className={styles.reminderRoot}>
            <div style={{ textAlign: "center" }}>
              <ReminderIcon />
            </div>
            <div className={styles.header}> {about.safety_title}</div>
            <div className={styles.bodyText1}>{about.safety_text}</div>
            <div className={styles.bodyText2}>
              <div>{about.safety_text_one}</div>
            </div>
            {/*  */}
            <div
              style={{ marginTop: 16 }}
              dangerouslySetInnerHTML={{
                __html: `${about.safety_text_two}`
              }}
            />
            <ul className={styles.list}>
              {data.map((v) => {
                return (
                  <li className={styles.listItem} key={v}>
                    <span className={styles.dotWrapper}>
                      <DotIcon />
                    </span>
                    {v}
                  </li>
                );
              })}
            </ul>

            <div
              className={styles.btnALike}
              style={{ marginTop: 16 }}
              onClick={(e) => {
                const target = e.nativeEvent.target as HTMLSpanElement;
                if (target.className === "aLikeText") {
                  onOpenClick();
                  return;
                }
              }}
              dangerouslySetInnerHTML={{
                __html: `<span class="aLikeText">${about.please_report_it_btn}</span>`
              }}
            />
            {/* <div>{about.safety_end_text}</div> */}
          </div>
        </div>
      </div>
      <FB
        key={openForm.key}
        open={Boolean(openForm.open)}
        onCancel={() => {
          setOpenForm((pre) => {
            return { ...pre, open: false };
          });
        }}
        lang={langDic}
        langKey={langKey}
      />
    </>
  );
};
