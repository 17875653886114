'use client'
import React from 'react'
import { usePathname } from 'next/navigation'

import styles from '../Footer.module.scss'
import Copyright from './Mobile/Copyright'
import About from './Mobile/About'
import SocialLink from './Mobile/SocialLink'

const Mobile = (props: any) => {
  const { data, countryKey } = props
  const pathName = usePathname()

  return (
    <div className={styles.footerMobileContainer}>
      {/* <TalentsLink data={data} {...restProps} /> */}
      {/* <RecruiterLink data={data} {...restProps} /> */}
      {/* <PopularJobsLink data={data} {...restProps} /> */}
      {/* <Company data={data} /> */}
      {/* <DownloadApp data={data} /> */}
      {!pathName.includes('/job') ? (
        <>
          <About data={data} countryKey={countryKey} />
          <SocialLink data={data} isMobile={true} />
          <Copyright data={data} countryKey={countryKey} />
        </>
      ) : null}
    </div>
  )
}

export default Mobile
